:root {
 --bg-avatar-yellow: #d9d9d9;
}

.wrp-avatar-profil-exh-dash {
 display: flex;
 margin: 1ch 0;
}
.avatar-circle-profil-exh-dash {
 display: flex;
 position: relative;
 color: #fff;

 background: var(--color-base);
}
.avatar-circle-profil-exh-dash img {
 display: flex;
 height: 6em;
 width: 6em;
 border-radius: 100%;
 border: none;
 background: var(--bg-avatar-yellow);
 object-fit: cover;
}
