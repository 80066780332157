.wrp-date-fcwell {
 display: flex;
}
.wrp-date-fcwell p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.5px;
}

/* Small */
.wrp-date-fcwell-sm {
 display: flex;
}
.wrp-date-fcwell-sm p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0.5px;
}
