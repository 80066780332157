.flex-swipeable-service {
 display: grid;
 place-items: center;
 padding: 1em;
 gap: 5vh;
 margin-top: 20vh;

 width: 100vw;
}
.flex-swipeable-service img {
 width: 100%;
 height: 55vh;
 object-fit: cover;
}
.flex-swipeable-service h4 {
 display: flex;
 font-size: 1.2em;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 400;
 font-style: normal;
}
.flex-swipeable-service p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin-top: 1vh;
 letter-spacing: 0.6px;
 line-height: 1.3;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
