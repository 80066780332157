:root {
 --bg-green-btn-tontine: #09ad01;
}

.tontine-btn-next {
 display: flex;
 margin: 20vh 0;
}
.tontine-btn-next button {
 display: flex;
 justify-content: center;
 align-items: center;

 border-radius: 0.9em;
 font-size: 1em;
 letter-spacing: 1px;
 cursor: pointer;
 white-space: nowrap;

 outline-style: none;
 font-family: var(--text-family);

 padding: 1ch 1.3em;
 width: 100%;

 color: var(--color-btn);
 border: 0.1px solid var(--bg-green-btn-tontine);
 background: var(--bg-green-btn-tontine);
}

/* Small */
.tontine-btn-next-sm {
 display: flex;
 margin: 15vh 0;
}
.tontine-btn-next-sm button {
 display: flex;
 justify-content: center;
 align-items: center;

 border-radius: 0.9em;
 font-size: 1em;
 letter-spacing: 1px;
 cursor: pointer;
 white-space: nowrap;

 outline-style: none;
 font-family: var(--text-family);

 padding: 1ch 1.3em;
 width: 100%;

 color: var(--color-btn);
 border: 0.1px solid var(--bg-green-btn-tontine);
 background: var(--bg-green-btn-tontine);
}
