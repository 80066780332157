.wrp-logo-sign {
 display: flex;
}
.wrp-logo-sign img {
 display: flex;
 width: 4em;
 height: 4em;
 cursor: pointer;
 object-fit: cover;
}
.wrp-logo-sign-sm {
 display: flex;
}
.wrp-logo-sign-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}
