.wrp-tontine-ima-method {
 display: grid;
 place-items: center;
 color: black;
}

.wrp-tontine-ima-method img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}

.wrp-tontine-ima-method p {
 margin-top: 3vh;
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 margin-bottom: 5vh;
 color: gray;
}
