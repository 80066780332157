.wrp-send-pret {
 display: flex;
 flex-direction: column;
 position: relative;
 justify-content: center;
 align-items: center;
 overflow: hidden;
 width: 100%;
 height: 100vh;
}
