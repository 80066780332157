.wrp-quote-tiket-msg {
 display: flex;
 margin-top: 1.5vh;
}
.wrp-quote-tiket-msg p {
 display: flex;
 font-size: 1.1em;
 font-weight: 400;
 line-height: 1.1;
 font-family: var(--font-condensed);
 letter-spacing: -1px;
}
