.devise-pret-money {
 display: flex;
 cursor: pointer;

 justify-content: space-between;
 align-items: start;
 border-radius: 2em;
 width: 100%;

 margin: 1ch 0;
 border: 1px solid grey;
}
.devise-pret-money img {
 height: 2.6em;
 width: 2.6em;
 cursor: pointer;

 object-fit: cover;
 border-radius: 100%;
}
.devise-pret-money p {
 display: flex;
 font-size: 1.103em;
 margin-left: 1em;

 margin-top: 0.8ch;
 margin-left: 5em;
 margin-right: 1em;

 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}
