.profil-tontine-navs {
 display: flex;
 justify-content: start;
 align-items: stretch;
 width: 100%;
}
.profil-tontine-navs img {
 height: 2.8em;
 width: 2.8em;
 border-radius: 100%;
 object-fit: cover;
}
.profil-tontine-navs h4 {
 display: flex;
 margin-left: 0.7em;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
