@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik+Vinyl&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;500&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;



:root {
 --para-small: 1em;
 --para-medium: 1.1em;
 --para-large: 1.08em;

 --para-width-small: 95vw;
 --para-width-medium: 20em;
 --para-width-large: 30em;

 --para-heigthline-small: 1;
 --para-heigthline-medium: 1;
 --para-heigthline-large: 1.2;

 --subject-small: 1em;
 --subject-medium: 1.178em;
 --subject-large: 1.3983em;
}

.App-loading {
 display: flex;
 position: absolute;

 left: 0;
 right: 0;
 top: 0;
 z-index: 99999999999;

 height: 205%;
 opacity: 0.5;
 background: #000;
}
.dash-loading-large {
 display: flex;
 position: absolute;

 left: 0;
 right: 0;
 top: 0;
 z-index: 99999999999;

 height: 187%;
 opacity: 0.5;
 background: #000;
}
.dash-loading-small {
 display: flex;
 position: absolute;

 left: 0;
 right: 0;
 top: 0;
 z-index: 99999999999;

 height: 223%;
 opacity: 0.5;
 background: #000;
}

.App-loading-Current {
 display: flex;
 position: absolute;

 left: 0;
 right: 0;
 top: 0;
 z-index: 99999999999;

 height: 100%;
 opacity: 0.5;
 background: #000;
}
.App-loading-profil {
 display: flex;
 position: absolute;

 left: 0;
 right: 0;
 top: 0;
 z-index: 99999999999;

 height: 100%;
 opacity: 0.5;
 background: #000;
}

.App-loading-blank {
 display: flex;
 position: absolute;

 left: 0;
 right: 0;
 top: 0;
 z-index: 99999999999;

 height: 100%;
 min-height: 120%;
 background: white;
}
.App-Icon {
 display: flex;
 position: absolute;

 justify-content: center;
 align-items: center;

 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);

 z-index: 999999999999999;
}

.Loading-Hm {
 display: flex;
 position: absolute;

 justify-content: center;
 align-items: center;

 height: 100%;
 width: 100%;

 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 background-color: #ffffff;

 z-index: 999999999999999;
}

.logo-nav-top-hm {
 display: flex;
}

.logo-nav-top-hm img {
 width: 4em;
 object-fit: contain;
}

.hm-btn-sm {
 display: grid;
 place-items: center;
 color: #fff;
}
.hm-btn-sm p {
 display: flex;
 font-size: 0.7em;
 letter-spacing: 1px;
 line-height: 1;
 text-align: center;
 text-transform: uppercase;
 border-radius: 2em;
 padding: 1.5em 2em;
 background-color: #001f54;

 /* font-family: "Lexend Deca", sans-serif; */

 font-family: var(--text-family);

 font-optical-sizing: auto;
 font-weight: 400;
 font-style: normal;
 cursor: pointer;
}

.flx-pad-2 {
 padding: 1vh 1em;
}

.head-title-middle-home {
 background-size: cover;
 width: 100%;
 height: 300px;

 display: flex;
 justify-content: center;
 align-items: center;
}
.head-title-middle-home h3 {
 font-size: 10vw;

 font-family: "Righteous", sans-serif;
 font-weight: 400;
 font-style: normal;

 -webkit-background-clip: text;

         background-clip: text;
 color: transparent;

 background-image: linear-gradient(to right, #001f54, #feba7b, #febaaa, #001f54);
}
.head-title-middle-home-sm {
 background-size: cover;
 width: 100%;
 height: 300px;

 display: flex;
 justify-content: center;
 align-items: center;
}
.head-title-middle-home-sm h3 {
 font-size: 17vw;

 font-family: "Righteous", sans-serif;
 font-weight: 400;
 font-style: normal;

 -webkit-background-clip: text;

         background-clip: text;
 color: transparent;

 background-image: linear-gradient(to right, #001f54, #feba7b, #febaaa, #001f54);
}

.grid-center {
 display: grid;
 place-items: center;
}

.hm-title-footer-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 40em;

 justify-content: center;
 align-items: center;
}
.hm-title-footer-home h3 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.hm-title-footer-home p {
 display: flex;
 font-size: 1em;
 font-weight: 200;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
 color: #dee2e6;
}

.hm-title-footer-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 40em;

 justify-content: center;
 align-items: center;
}
.hm-title-footer-home-sm h3 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.hm-title-footer-home-sm p {
 display: flex;
 padding: 1em;
 font-size: 4.5vw;
 font-weight: 100;

 letter-spacing: px;
 line-height: 1.7;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;

 color: #fff;
}


.grid-templates-rows {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100vh;
 width: 100%;
}
.grid-templates-rows-sm {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100vh;
}

.grid-template-repeat {
 display: grid;
 grid-gap: 1em;
 place-items: center;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
 /* background-color: #ccc5b9; */
 /* background-color: #ccc5b9; */
}

.grid-template-repeat-sm {
 display: flex;
 flex-direction: column;
 /* background-color: #ccc5b9; */
 /* background-color: #ccc5b9; */
}

.deposit-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 80vw;
 padding-left: 5em;
 margin: 40vh 0;
 justify-content: center;
 place-items: center;
}
.deposit-title-quote-home h4 {
 display: flex;
 font-size: 2.5em;
 letter-spacing: -0.5px;
 line-height: 1.1;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;

 color: #343a40;
}
.deposit-title-quote-home p {
 display: flex;
 font-size: 1em;

 font-weight: 200;
 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
 color: #6c757d;
}

/* Small */
.deposit-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;
 margin: 10vh 0;
 justify-content: center;
 place-items: center;
}
.deposit-title-quote-home-sm h4 {
 display: flex;
 font-size: 2.5em;
 letter-spacing: -0.5px;
 line-height: 1.1;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
 color: #343a40;
}
.deposit-title-quote-home-sm p {
 display: flex;
 font-size: 1em;

 font-weight: 200;
 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
 color: #6c757d;
}

.flex-deposite-image {
 display: grid;
}
.flex-deposite-image img {
 height: 40vw;
 width: 40vw;
 object-fit: cover;
}
/* 
.flex-deposite-image-sm {
 display: grid;
}
.flex-deposit-image-sm img {
 width: 30vw;
 height: 30vw;
 object-fit: cover;
} */

.keep-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin: 40vh 0;

 justify-content: center;
 place-items: center;
}
.keep-title-quote-home h4 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;

 -webkit-background-clip: text;

         background-clip: text;
 color: transparent;
 background-image: linear-gradient(to right, #3a0ca3, #febaaa, #feba7b, #001f54, #3a0ca3);
 /* background-image: linear-gradient(to right, #feba7b, #febaaa, #feba7b, #001f54); */
}
.keep-title-quote-home p {
 display: flex;
 font-size: 1em;

 font-weight: 200;
 margin: 7vh 0;
 max-width: 60vw;
 text-align: center;

 letter-spacing: 0.6px;
 line-height: 1.8;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
 color: #6c757d;
}

.keep-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;
 margin-top: 15vh;
}
.keep-title-quote-home-sm h4 {
 display: flex;
 font-size: 2.1em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: start;
 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;

 -webkit-background-clip: text;

         background-clip: text;
 color: transparent;
 background-image: linear-gradient(to right, #3a0ca3, #febaaa, #feba7b, #001f54, #3a0ca3);
}
.keep-title-quote-home-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin-top: 5vh;
 letter-spacing: 0px;
 line-height: 1.5;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.line {
 width: 100%;
 height: 0.1px;
 background-color: #c0c0c0;
}

.grid-template-repeat-all {
 display: grid;
 grid-gap: 1em;
 place-items: center;
 margin-top: 20vh;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.grid-template-repeat-all-sm {
 display: flex;
 flex-direction: column;
}

.service-quote-all {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 22.4em;

 justify-content: center;
 align-items: center;
}
.service-quote-all h3 {
 display: flex;
 font-size: 1.3em;
 letter-spacing: -0.5px;
 line-height: 1.4;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 400;
 font-style: normal;
}
.service-quote-all p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin-top: 1vh;
 letter-spacing: 0.6px;
 line-height: 1.8;

 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;

 color: #adb5bd;
}

.service-quote-all-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;

 margin-top: 10vh;
 justify-content: center;
 align-items: center;
}
.service-quote-all-sm h3 {
 display: flex;
 font-size: 2.3em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.service-quote-all-sm p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 margin: 5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.margin-top-5vh {
 margin: 5vh 0;
}

.flex-service-all-image {
 display: grid;
 place-items: center;

 width: 75%;
 height: 95vh;
}
.flex-service-all-image img {
 height: 100%;
 width: 100%;
 border-radius: 2%;
 object-fit: cover;
}

.flex-service-all-image-sm {
 width: 100vw;
 height: auto;
 border-radius: 5%;
 margin-top: 5vh;
 background-color: #ccc9dc;
}
.flex-service-all-image-sm img {
 width: 100%;
 height: auto;
 min-height: 48vh;
 object-fit: cover;
}

.flex-swipeable-service {
 display: grid;
 place-items: center;
 padding: 1em;
 grid-gap: 5vh;
 gap: 5vh;
 margin-top: 20vh;

 width: 100vw;
}
.flex-swipeable-service img {
 width: 100%;
 height: 55vh;
 object-fit: cover;
}
.flex-swipeable-service h4 {
 display: flex;
 font-size: 1.2em;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 400;
 font-style: normal;
}
.flex-swipeable-service p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin-top: 1vh;
 letter-spacing: 0.6px;
 line-height: 1.3;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.wrp-more-body-bg-black {
 display: flex;
 padding: 40vh 0;
 background-color: #000;
}

.more-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin: 35vh 0;
 justify-content: center;
 place-items: center;
}
.more-title-quote-home h4 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;
 max-width: 17em;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.more-title-quote-home p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 max-width: 45em;
 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.more-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;

 margin-top: 18vh;
 justify-content: center;
 align-items: center;
}
.more-title-quote-home-sm h4 {
 display: flex;
 font-size: 2.1em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.more-title-quote-home-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin: 2.5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.accepted-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin: 25vh 0;

 justify-content: center;
 place-items: center;
}
.accepted-title-quote-home h4 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;
 max-width: 17em;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.accepted-title-quote-home p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 max-width: 45em;
 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
.accepted-title-quote-home img {
 width: 90%;
 height: 75vh;
 border-radius: 3em;
 object-fit: cover;
}

.accepted-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;

 margin-top: 18vh;
 justify-content: center;
 align-items: center;
}
.accepted-title-quote-home-sm h4 {
 display: flex;
 font-size: 2.1em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.accepted-title-quote-home-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin: 2.5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
.accepted-title-quote-home-sm img {
 width: 90%;
 height: 18vh;
 border-radius: 1em;

 object-fit: cover;
}

.grid-template-repeat-want {
 display: grid;
 grid-gap: 1em;
 place-items: center;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.grid-template-repeat-want-sm {
 display: flex;
 flex-direction: column;
}

/* .deposit-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 25em;

 justify-content: center;
 align-items: center;
}
.deposit-title-quote-home h3 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}

.deposit-title-quote-home p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.deposit-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;

 margin-top: 10vh;
 justify-content: center;
 align-items: center;
}
.deposit-title-quote-home-sm h3 {
 display: flex;
 font-size: 2.3em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}

.deposit-title-quote-home-sm p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 margin: 5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
} */

.flex-want-image {
 display: grid;
 place-items: center;

 padding: 2em;

 width: 45vw;
 height: 95vh;
 border-radius: 7%;
}
.flex-want-image img {
 height: 100%;
 width: 100%;
 border-radius: 3%;
 object-fit: cover;
}

.flex-deposit-image-sm {
 width: 100vw;
 height: auto;
 border-radius: 5%;
 margin-top: 5vh;
 background-color: #ccc9dc;
}
.flex-deposit-image-sm img {
 width: 100%;
 height: auto;
 min-height: 48vh;
 object-fit: cover;
}

.grid-template-repeat-check {
 display: grid;
 grid-gap: 1em;
 margin-top: 15vh;
 place-items: center;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.grid-template-repeat-check-sm {
 display: flex;
 flex-direction: column;
}

/* .deposit-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 25em;

 justify-content: center;
 align-items: center;
}
.deposit-title-quote-home h3 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}

.deposit-title-quote-home p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.deposit-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;

 margin-top: 10vh;
 justify-content: center;
 align-items: center;
}
.deposit-title-quote-home-sm h3 {
 display: flex;
 font-size: 2.3em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}

.deposit-title-quote-home-sm p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 margin: 5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
} */

.flex-check-image {
 display: grid;
 place-items: center;

 padding: 2em;

 width: 44vw;
 height: 95vh;
 border-radius: 7%;
}
.flex-check-image img {
 height: 70%;
 width: 70%;
 object-fit: cover;
}

.flex-check-image-sm {
 width: 100vw;
 height: auto;
 border-radius: 5%;
 margin-top: 5vh;
 background-color: #ccc9dc;
}
.flex-check-image-sm img {
 width: 100%;
 height: auto;
 min-height: 48vh;
 object-fit: cover;
}

.grid-template-repeat-check {
 display: grid;
 grid-gap: 1em;
 margin-top: 15vh;
 place-items: center;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.grid-template-repeat-check-sm {
 display: flex;
 flex-direction: column;
}

/* .deposit-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 25em;

 justify-content: center;
 align-items: center;
}
.deposit-title-quote-home h3 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}

.deposit-title-quote-home p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.deposit-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;

 margin-top: 10vh;
 justify-content: center;
 align-items: center;
}
.deposit-title-quote-home-sm h3 {
 display: flex;
 font-size: 2.3em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}

.deposit-title-quote-home-sm p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 margin: 5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
} */

.flex-deposit-image {
 display: grid;
 place-items: center;
 background-color: #ccc9dc;

 width: 95%;
 height: 95vh;
 border-radius: 5%;
}
.flex-deposit-image img {
 height: 70%;
 width: 70%;
 object-fit: cover;
}

.flex-deposit-image-sm {
 width: 100vw;
 height: auto;
 border-radius: 5%;
 margin-top: 5vh;
 background-color: #ccc9dc;
}
.flex-deposit-image-sm img {
 width: 100%;
 height: auto;
 min-height: 48vh;
 object-fit: cover;
}

.grid-template-repeat-check {
 margin-top: 15vh;
 grid-gap: 8rem;
 gap: 8rem;
}
.grid-template-repeat-check-sm {
 display: flex;
 flex-direction: column;
}

.flex-deposit-image-earn {
 display: flex;
 flex-direction: column;
 padding: 1em;

 height: auto;
 grid-gap: 1vh;
 grid-template-columns: repeat(3, 1fr);
}
.flex-deposit-image-earn img {
 height: 70vh;
 width: 100%;
 border-radius: 2em;
 object-fit: cover;
}
.flex-deposit-image-earn h5 {
 padding: 1rem;
 display: flex;
 font-size: 1.5em;
 letter-spacing: 0px;
 line-height: 1.1;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
.flex-deposit-image-earn p {
 display: flex;
 font-size: 0.9em;
 letter-spacing: 0px;
 padding: 1rem;
 line-height: 2;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.flex-deposit-image-earn-sm {
 display: flex;
 flex-direction: column;

 margin-top: 5vh;

 height: auto;
 grid-gap: 1vh;
 grid-template-columns: repeat(3, 1fr);
}
.flex-deposit-image-earn-sm img {
 height: 55vh;
 width: 100%;
 object-fit: cover;
}
.flex-deposit-image-earn-sm h5 {
 padding: 1rem;
 display: flex;
 font-size: 1.5em;
 letter-spacing: 0px;
 line-height: 1.1;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
.flex-deposit-image-earn-sm p {
 display: flex;
 font-size: 0.9em;
 letter-spacing: 0px;
 padding: 1rem;
 line-height: 2;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.earn-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 margin-top: 14vh;
 place-items: center;
}
.earn-title-quote-home h4 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;
 max-width: 17em;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.earn-title-quote-home p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 max-width: 45em;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.earn-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;
 justify-content: center;
 align-items: center;
}
.earn-title-quote-home-sm h4 {
 display: flex;
 font-size: 2.1em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.earn-title-quote-home-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin: 2.5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.flex-block {
 display: flex;
 flex-direction: column;
}

.wrp-form-input-sign {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 padding: 0.8em;

 width: 100%;
 max-width: 25em;
}
.wrp-form-input-sign form {
 display: flex;
 flex-direction: column;
 margin-bottom: 2ch;
 width: 100%;
}
.wrp-form-input-sign form h1 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-form-input-sign form p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 2vh;
}
.color-red-msg {
 display: flex;
 color: red;
}

.wrp-form-input-sign input {
 display: flex;
 font-size: 1.5em;
 font-family: var(--text-family);
 font-weight: 400;
 line-height: 1;
 padding-top: 1ch;
 padding-bottom: 0.5ch;
 border: none;
 color: #343a40;
}
.wrp-form-input-sign form button {
 margin-top: 2vh;
}

.wrp-form-input-sign .border-bottom-sgin {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 padding-bottom: 0.3em;
 border-bottom: 0.5px solid #202020;
}
.wrp-form-input-sign .text-input-size {
 font-size: 1.5em;
 letter-spacing: -0.5px;
 text-align: center;
}
.class-input-label {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.pop-title {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-quote-sign {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 place-items: start;
 width: 100%;
}
.wrp-quote-sign p {
 display: flex;
 font-size: 1em;
 letter-spacing: 0px;
 font-family: var(--text-family);
 font-weight: 400;
 line-height: 1;
}

/* small view available */
.wrp-quote-sign-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0 2%;
}
.wrp-quote-sign-sm p {
 display: flex;
 font-size: 1em;
 letter-spacing: 0px;
 font-family: var(--text-family);
 max-width: 90vw;
 font-weight: 400;
 line-height: 1.1;
}

.wrp-logo-sign {
 display: flex;
}
.wrp-logo-sign img {
 display: flex;
 width: 4em;
 height: 4em;
 cursor: pointer;
 object-fit: cover;
}
.wrp-logo-sign-sm {
 display: flex;
}
.wrp-logo-sign-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

.wrp-sign-up {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 place-items: center;
 width: 100%;
 position: relative;

 height: 100vh;
}
.wrp-sign-up-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 place-items: center;
 width: 100%;
 position: relative;

 height: 100vh;
}

.wrp-region {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 90vh;
}

.wrp-logo-regio {
 display: flex;
}
.wrp-logo-regio img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

/* small regio */
.wrp-logo-regio-sm {
 display: flex;
 margin-top: 20vh;
}
.wrp-logo-regio-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

.wrp-contry-region {
 display: flex;
 flex-direction: column;
 margin: 2vh 0;
}
.wrp-contry-region h4 {
 display: flex;
 font-size: 0.8em;
 font-family: var(--text-family);
 font-weight: 500;
 white-space: nowrap;
 color: var(--bgcolor-btn);
}

.wrp-quote-region {
 display: flex;
 flex-direction: row;

 width: 100%;
 max-width: 25em;
}
.wrp-quote-region p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 text-align: center;
}

/* small */
.wrp-quote-region-sm {
 display: flex;
 flex-direction: row;
 width: 100%;
 max-width: 75vw;
}
.wrp-quote-region-sm p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 font-weight: 300;
 text-align: center;
}

.wrp-regions-btn {
 display: flex;
 width: 100%;
 max-width: 17em;
 margin-top: 8vh;
}
.wrp-regions-btn-sm {
 display: flex;
 flex-direction: column;

 margin-top: 30vh;
 padding: 0.3em;
 width: 100%;
}

.wrp-phone {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100vh;
 position: relative;
}

.wrp-input-phone-register {
 display: flex;
 flex-direction: column;

 width: 100%;
 max-width: 20em;

 position: absolute;
 top: 60%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-phone-register form {
 display: flex;
 flex-direction: column;
 grid-gap: 1em;
 gap: 1em;
 justify-content: center;
 align-items: center;
}
.wrp-input-phone-register form input {
 display: flex;
 flex-direction: column;
 font-size: 1.7em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 width: 100%;
 padding: 0.2em;
 font-family: var(--text-family);
 border: none;
 border-radius: 0.2em;
 color: black;
 background: rgba(201, 201, 201, 0.413);
}

/* small view input */
.wrp-input-phone-register-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;

 position: absolute;
 top: 91%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-phone-register-sm form {
 display: flex;
 flex-direction: column;
 grid-gap: 0.5em;
 gap: 0.5em;
}
.wrp-input-phone-register-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.7em;
 font-weight: 400;
 padding: 0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 width: 97vw;
 border: none;
 border-radius: 0.2em;
 color: black;
 background: rgba(201, 201, 201, 0.413);
}

.wrp-signin-register {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 position: relative;
}

.wrp-quote-signin {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;

 position: absolute;
 top: 20%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-quote-signin p {
 display: flex;
 font-weight: 300;
 text-align: start;
 font-family: var(--text-family);
 width: 100%;
 max-width: 25vw;
 color: rgb(172, 172, 172);
}

/* small */
.wrp-quote-signin-sm {
 display: flex;
 flex-direction: row;
 width: 100%;
 max-width: 95vw;

 position: absolute;
 top: 15%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-quote-signin-sm p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 text-align: start;
 color: rgb(172, 172, 172);
}

.wrp-input-signin-user {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-input-signin-user form {
 display: flex;
 width: 100%;
 max-width: 25em;
 flex-direction: column;
}
.wrp-input-signin-user form input {
 display: flex;
 flex-direction: column;
 font-size: 1.4em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 padding: 0.5em 0;
 letter-spacing: 0px;
 border: none;
 border-bottom: 1px solid #62656c;

 border-radius: 0.1em;
 color: #000;

 background: transparent;
}
.wrp-input-signin-user form button {
 margin-top: 3vh;
}

/* Small sign user */
.wrp-input-signin-user-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.wrp-input-signin-user-sm form {
 display: flex;
 flex-direction: column;
 padding: 0.5em;
}
.wrp-input-signin-user-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.4em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 padding: 0.5em 0;
 letter-spacing: 0px;
 border: none;
 border-bottom: 1px solid #62656c;

 border-radius: 0.1em;
 color: #000;

 background: transparent;
}
.wrp-input-signin-user-sm form button {
 margin-top: 3vh;
}

.wrp-icon-success {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 /* color: var(--color-base); */
 position: absolute;
 top: 75%;
 left: 50%;

 transform: translate(-50%, -50%);
 color: #38b000;
}
.wrp-icon-success p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-succes-register {
 display: flex;
 flex-direction: column;
 position: relative;
 width: 100%;
 height: 100vh;
}

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-title-form-success {
 display: flex;
 flex-direction: column;

 position: absolute;
 top: 5%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-title-form-success h1 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 text-align: center;
 line-height: 1;
 letter-spacing: 0.1px;
}

.wrp-save-register {
 display: flex;
 flex-direction: column;
 width: 100%;
 position: relative;
}

.wrp-regiter-home {
 display: flex;
 flex-direction: column;
 width: 100%;
}

.wrp-box-register {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 90vh;
}

.wrp-logo-register {
 display: flex;
 justify-content: center;
 align-items: center;
}
.wrp-logo-register img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

/* small register */
.wrp-logo-register-sm {
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 30vh 0;
}
.wrp-logo-register-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

.wrp-regisbtn {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}
.wrp-regisbtn-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}

.wrp-Btn-Register {
 display: flex;
 width: 100%;
 max-width: 20em;
 margin: 20vh 0;
}
.wrp-Btn-Register-sm {
 display: flex;
 margin: 20vh 0;
 width: 100%;
 max-width: 95vw;
}

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

/* .tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
} */

.wrp-dashed {
 display: flex;
 width: 100%;
 height: 100%;
 max-height: 100vh;
 background-color: var(--nav-cmd-bg);

 /* background: linear-gradient(25deg, #a2d2ff, #003e77); */
 color: #0a090c;
}

.head-dashed {
 display: flex;
 flex-direction: column;
 position: relative;
 width: 100%;
 min-height: 70vh;
}

.box-dashed {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100%;
}

.profil-dashed {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.profil-dashed img {
 height: 3em;
 width: 3em;
 object-fit: cover;
 border: 0.2em solid #fff;

 padding: 0.2em;

 border-radius: 100%;
}

.name-head-dashed {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 margin: 2vh 0;
 width: 100%;
}
.name-head-dashed h2 {
 display: flex;
 font-size: 1.5em;
 font-family: var(--text-family);
 font-weight: 900;
 line-height: 1;
 letter-spacing: 0.1px;
 text-align: center;
 color: #f6fff8;

 white-space: nowrap;
}
.name-head-dashed h3 {
 display: flex;
 font-size: 0.7em;
 font-family: var(--text-family);
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0.5px;
 text-align: center;
 margin-top: 8vh;
 text-transform: uppercase;

 white-space: nowrap;
}

.nav-box-dashed {
 display: flex;
 width: 100%;
 padding: 0 5%;
 margin-top: 2vh;
 justify-content: space-between;
 align-items: center;
}

.head-img-dashed {
 display: flex;
 justify-content: center;
 align-items: center;

 cursor: pointer;
}
.head-img-dashed img {
 display: flex;
 width: 3.5em;
 height: 3.5em;
 object-fit: cover;
}

.body-dashed {
 display: flex;
 flex-direction: column;
 width: 100%;
 grid-gap: 5vh;
 gap: 5vh;
}

.prix-body-dashed {
 display: flex;
 flex-direction: column;
 width: 100%;
 grid-gap: 3vh;
 gap: 3vh;
 padding: 2em;
}
.prix-body-dashed-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0.5em;
}

.prix-nav-dashed {
 display: flex;
 justify-content: start;
 align-items: center;
 width: 100%;
}

.title-body-dashed {
 display: flex;
 color: var(--color-yellow);
}
.title-body-dashed h2 {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-main-prix-dashed {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 color: #c44900;
}
.wrp-main-prix-dashed span {
 display: flex;
 font-size: 2em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
}

/* Small */
.wrp-main-prix-dashed-sm {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: flex-start;
 width: 100%;
 color: #c44900;
}
.wrp-main-prix-dashed-sm span {
 display: flex;
 font-size: 1.455em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 300;
 margin: 0.5ch 0;
 line-height: 1;
 white-space: nowrap;
}

.box-purchase-dashed {
 display: flex;
 flex-direction: column;
 width: 100%;
}

:root {
 --margin-service: 0.6vw;
 --margin-service-md: 0.4vw;
 --color-menu: #eff1f9;
 /* --color-menu: #f5f5f5; */

 --text-color-menu: #000;
}

.wrp-list-purchase-dashed {
 display: flex;
}
.wrp-list-purchase-dashed nav ul {
 display: flex;
 margin: 0;
 padding: 0;
 flex-wrap: wrap;
 list-style: none;
 width: 100%;
}
.wrp-list-purchase-dashed nav ul li {
 display: flex;
 flex-direction: column;
 position: relative;

 padding: 8vw 11vw;
 border-radius: 0.3em;
 margin: 0.4vw;
 margin: var(--margin-service-md);

 cursor: pointer;
 background: #eff1f9;
 background: var(--color-menu);
 transition: 0.8s;

 transition: 3500ms linear cubic-bezier(0.83, 0.2, 0.35, 0.55);
}
.wrp-list-purchase-dashed nav ul li .wrp-list-abs {
 display: flex;
 flex-direction: column;
 position: absolute;

 width: 100%;
 justify-content: center;
 align-items: center;

 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
}
.wrp-list-purchase-dashed nav .wrp-list-abs span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 text-align: center;
 max-width: 10em;
 letter-spacing: 0.5px;
}

/* Meduim */
.wrp-list-purchase-dashed-md {
 display: flex;
}
.wrp-list-purchase-dashed-md nav ul {
 display: flex;
 margin: 0;
 padding: 0;
 flex-wrap: wrap;
 list-style: none;
 width: 100%;
 /* gap: 1.7vw; */
}
.wrp-list-purchase-dashed-md nav ul li {
 display: flex;
 flex-direction: column;
 position: relative;

 padding: 10vw 14vw;
 margin: 0.4vw;
 margin: var(--margin-service-md);
 border-radius: 0.2em;
 cursor: pointer;
 background: #eff1f9;
 background: var(--color-menu);

 transition: 3500ms linear cubic-bezier(0.83, 0.2, 0.35, 0.55);
}
.wrp-list-purchase-dashed-md nav ul li .wrp-list-abs {
 display: flex;
 flex-direction: column;
 position: absolute;

 width: 100%;
 justify-content: center;
 align-items: center;

 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
}
.wrp-list-purchase-dashed-md nav .wrp-list-abs span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 text-align: center;
 max-width: 10em;
 letter-spacing: 0.5px;
}

/* Small */
.wrp-list-purchase-dashed-sm {
 display: flex;
 width: 100%;
 justify-content: center;
}
.wrp-list-purchase-dashed-sm nav ul {
 display: flex;
 margin: 0;
 padding: 0.1em;

 flex-wrap: wrap;
 list-style: none;

 width: 100%;
 /* gap: 2vw; */
}
.wrp-list-purchase-dashed-sm nav ul li {
 display: flex;
 flex-direction: column;
 position: relative;

 padding: 12vw 15vw;
 border-radius: 0.2em;
 cursor: pointer;
 background: #eff1f9;
 background: var(--color-menu);
 margin: 0.6vw;
 margin: var(--margin-service);

 transition: 3500ms linear cubic-bezier(0.83, 0.2, 0.35, 0.55);
}
.wrp-list-purchase-dashed-sm nav ul li .wrp-list-abs {
 display: flex;
 flex-direction: column;
 position: absolute;

 width: 100%;
 justify-content: center;
 align-items: center;

 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
}
.wrp-list-purchase-dashed-sm nav .wrp-list-abs span {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 text-align: center;
 max-width: 10em;
 color: #000;
 color: var(--text-color-menu);
 letter-spacing: 0.5px;
}

.icon-purchase-dashed {
 display: flex;
}
.icon-purchase-dashed img {
 height: 3.2em;
 width: 3.2em;
 object-fit: cover;
}
.icon-purchase-dashed-md {
 display: flex;
}
.icon-purchase-dashed-md img {
 height: 3.2em;
 width: 3.2em;
 object-fit: cover;
}
.icon-purchase-dashed-sm {
 display: flex;
}
.icon-purchase-dashed-sm img {
 height: 2.2em;
 width: 2.2em;
 object-fit: cover;
}

.prix-body-dashed {
 display: flex;
 flex-direction: column;
 width: 100%;
 grid-gap: 3vh;
 gap: 3vh;
 padding: 2em;
}
.prix-body-dashed-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0.5em;
 margin-bottom: 5vh;
}

.wrp-main-swap-dashed {
 display: flex;
 margin: 0;
 padding: 0;
 flex-wrap: wrap;
 list-style: none;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 grid-gap: 2vw;
 gap: 2vw;
}
.wrp-main-swap-dashed span {
 display: flex;
 font-size: 1.02em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
 color: #6c757d;
}
.wrp-main-swap-dashed .box-swap-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.wrp-main-swap-dashed .box-swap-title h2 {
 display: flex;
 font-size: 1.189em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
}

/* Medium  */
.wrp-main-swap-dashed-md {
 display: flex;
 margin: 0;
 padding: 0;
 flex-wrap: wrap;
 list-style: none;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 grid-gap: 2vw;
 gap: 2vw;
}
.wrp-main-swap-dashed-md span {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
 color: #6c757d;
}
.wrp-main-swap-dashed-md .box-swap-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.wrp-main-swap-dashed-md .box-swap-title h2 {
 display: flex;
 font-size: 1.1em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
}

/* Small */
.wrp-main-swap-dashed-sm {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 width: 100%;
}
.wrp-main-swap-dashed-sm span {
 display: flex;
 font-size: 1.1em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
 color: #6c757d;
}

.wrp-main-swap-dashed-sm .box-swap-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 margin-top: 4vh;
}
.wrp-main-swap-dashed-sm .box-swap-title h2 {
 display: flex;
 font-size: 1.1em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
}

.wrp-last-swap-ten {
 display: flex;
 flex-direction: column;
 margin-top: 2vh;
}
.wrp-last-swap-ten h3 {
 display: flex;
 font-size: 1.132em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
.wrp-last-swap-ten-row {
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
}
.wrp-last-swap-ten-row img {
 height: 1.5em;
 width: 1.5em;
 margin: 0.3em;
 object-fit: cover;
}
.img-printed {
 height: 2em;
 width: 2em;
 object-fit: cover;
}

.tontine-nav-invited-icon-dash {
 display: flex;
}
.tontine-nav-invited-icon-dash img {
 height: 1.8em;
 width: 1.8em;
 object-fit: cover;
}

.wrp-body-exchange-buy-detail {
 display: flex;
 padding: 3%;
}

.list-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-buy-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail p {
 display: flex;
 font-size: 0.5em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.list-top-exchange-info-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-info-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-info-detail p {
 display: flex;
 font-size: 0.5em;
 font-weight: 400;

 /* padding-top: 2vh; */

 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.list-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 border-radius: 1em;
 padding: 0.7em;
 margin: 1vh 0;
}
.list-sub-exchange-buy-detail-view {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail-view p {
 display: flex;
 font-size: 0.655em;
 font-weight: 400;
 line-height: 0.4;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.flex-grid-center-creditaire {
 display: flex;
 justify-content: space-between;
 place-items: center;
}


.flex-transfert-head-swap {
 display: flex;
 padding: 1em;
}
.flex-transfert-head-swap h1 {
 display: flex;
 font-size: 0.9em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: #0077b6;
}

.wrp-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 height: 100%;
 min-height: 100vh;
}

.wrp-faq {
 display: flex;
 justify-content: start;
 align-items: flex-start;

 width: 100%;
 padding: 0 2em;
}
.wrp-faq-sm {
 display: flex;
 justify-content: start;
 align-items: flex-start;

 width: 100%;
 padding: 0 0.5em;
}

.wrp-faq-quote {
 display: flex;
 color: #d62839;
}
.wrp-faq-quote p {
 display: flex;
 font-size: 1.1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

.wrp-logout {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: flex-start;

 width: 100%;
 padding: 5vh 2em;
}
.wrp-logout-sm {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: flex-start;

 width: 100%;
 padding: 5vh .5em;
}

.wrp-logout-ima {
 display: flex;
 justify-content: center;
 align-items: center;
 cursor: pointer;
 grid-gap: 0.5em;
 gap: 0.5em;
}
.wrp-logout-ima img {
 height: 1.8m;
 width: 1.8m;
 object-fit: cover;
}

.child-custom {
 display: flex;
 font-size: 1.144em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 padding: 1em;
 letter-spacing: 0.5px;
}

.wrp-profil-money {
 display: flex;
 flex-direction: column;
}

.wrp-box-profil {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 padding: 1em;

 width: 100%;
 height: 100%;
 min-height: 80vh;
}

:root {
 --color-yellow: #9d4edd;
}

.wrp-boxname-profil {
 display: flex;
 width: 100%;
 justify-content: center;
 place-items: center;
 margin: 2vh 0;
}
.profil-name-identity {
 display: grid;
 place-items: start;
 padding-left: 2em;
 width: 15em;
}

.profil-name-identity h1 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
.profil-name-identity h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: #6c757d;
}


:root {
 --color-avatar: #000;
}

.wrp-avatar-profil {
 display: flex;
 flex-direction: column;

 width: 100%;
 margin-bottom: 5vh;

 justify-content: center;
 align-items: center;
}
.avatar-circle-profil {
 display: flex;
 position: relative;
 color: #000;
 color: var(--color-avatar);
}

.wrp-profil-avatar-user {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;
}
.wrp-profil-avatar-user .profile-user {
 display: flex;
 position: relative;

 justify-content: center;
 align-items: center;
 width: 100%;
 background-color: transparent;
}
.wrp-profil-avatar-user .profile-user img {
 display: flex;
 height: 4em;
 width: 4em;
 border-radius: 100%;
 border: none;
 background: var(--bg-avatar-yellow);
 object-fit: cover;
}

.wrp-profil-avatar-user button {
 display: flex;

 justify-content: center;
 align-items: center;

 grid-gap: 0.5em;

 gap: 0.5em;
 border: none;
 outline: none;
 border-radius: 1em;
 padding: 0.4em 1.3em;
 color: #cca43b;
 background-color: #fff8e1;
}

.wrp-profil-avatar-user button span {
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-profil-avatar-user .profile-user .icon-camera-profil {
 position: absolute;
 z-index: 1;
}

/* small view */
.wrp-avatar-profil-sm {
 display: flex;
 margin-bottom: 5vh;
}
.avatar-circle-profil-sm {
 display: flex;
 position: relative;
 color: #000;
 color: var(--color-avatar);
}

.wrp-nav-profil {
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 height: 10vh;
 padding: 0 5%;
}

.wrp-arrow {
 display: flex;
 flex-direction: column;
 cursor: pointer;
 color: rgba(116, 116, 116, 0.995);
}
.wrp-arrow-sm {
 display: flex;
 cursor: pointer;
 color: rgba(116, 116, 116, 0.995);
}

.wrp-withdraw {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100vh;
 position: relative;
}

.box-withdraw-money {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.box-withdraw-money-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.logo-withdraw {
 display: flex;

 position: absolute;
 top: 20%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.logo-withdraw img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

/* Small screen */
.logo-withdraw-sm {
 display: flex;

 position: absolute;
 top: 20%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.logo-withdraw-sm img {
 display: flex;

 width: 4em;
 height: 4em;
 object-fit: cover;
}

.wrp-subject-draw {
 display: flex;

 position: absolute;
 top: 35%;
 left: 50%;

 width: 100%;
 max-width: 20em;
 transform: translate(-50%, -50%);
}
.wrp-subject-draw p {
 display: flex;
 font-weight: 400;
 font-size: 1.1889em;
 font-family: var(--text-family);
 text-align: center;
 margin-top: 5vh;
 line-height: 1.1;
 letter-spacing: 0px;
}

/* small view */
.wrp-subject-draw-sm {
 display: flex;

 position: absolute;
 top: 40%;
 left: 50%;

 width: 100%;
 max-width: 80vw;
 transform: translate(-50%, -50%);
}
.wrp-subject-draw-sm p {
 display: flex;
 font-size: 2em;
 font-weight: 400;
 text-align: center;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.wrp-link-withdraw {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 20em;

 position: absolute;
 top: 80%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-link-withdraw .margin-link-button {
 margin-top: 1.6vh;
}

.wrp-link-withdraw-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 96vw;

 position: absolute;
 top: 80%;
 left: 50%;

 transform: translate(-50%, -50%);
}

.wrp-link-withdraw-sm .margin-link-button {
 margin-top: 1.3vh;
}

.wrp-ima-franc {
 display: flex;
 margin: 0.3em;
}
.wrp-ima-franc img {
 display: flex;
 height: 1em;
 width: 1em;
 border-radius: 100%;
 object-fit: cover;
}

.wrp-line-widthdraw {
 display: flex;
 width: 100%;
 height: 1px;
 margin-top: 7vh;

 max-width: 20em;
 background: #e0e0e0;
}
.wrp-line-widthdraw-sm {
 display: flex;
 width: 100%;
 height: 1px;
 margin-top: 1vh;

 max-width: 5em;
 background: #e0e0e0;
}

.wrp-arrow-profil {
 display: flex;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;

 transform: translate(-50%, -50%);
}
.wrp-arrow-profil-sm {
 display: flex;

 display: flex;
 position: absolute;
 top: 5%;
 left: 6%;
 transform: translate(-50%, -50%);
}

.wrp-money-send {
 display: flex;
 flex-direction: column;
 position: relative;
 width: 100%;
 margin-top: 15vh;
 height: 100vh;
}

.wrp-box-send {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}

.input-withdraw {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 height: 100%;
 width: 100%;
 max-width: 21em;
}
.input-withdraw form {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin-top: 4ch;
}
.input-withdraw input {
 display: flex;
 outline: none;
 border: none;
 font-family: var(--text-family);
 padding: 0.2vh 0;
 font-size: 3em;
 justify-content: center;
 align-items: center;
 width: 100%;

 font-weight: 100;
 text-align: center;
 letter-spacing: 1px;
 border-radius: 0.3em;
}
.input-withdraw h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.input-withdraw button {
 margin-top: 2vh;
}

.wrp-prix-withdraw {
 display: flex;
 flex-direction: column;
 margin-top: 4vh;
}
.wrp-prix-withdraw span {
 display: flex;
 font-size: 4em;
 font-weight: 500;
 white-space: nowrap;
 font-family: var(--text-family);
 letter-spacing: -5.5px;
}

/* Small  */
.wrp-prix-withdraw-sm {
 display: flex;
 flex-direction: column;
 margin-top: 4vh;
}
.wrp-prix-withdraw-sm span {
 display: flex;
 font-size: 2.8em;
 font-weight: 500;
 white-space: nowrap;
 font-family: var(--text-family);
 letter-spacing: -4.5px;
}

.wrp-logo-solde {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-logo-solde img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}
.wrp-logo-solde p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 2vh;
}

.wrp-ima-send {
 display: flex;
}
.wrp-ima-send img {
 width: 5em;
 height: 5em;
 object-fit: cover;
 border-radius: 50%;
}

.wrp-exchange-money {
 display: flex;
 flex-direction: column;
 height: 100vh;
 min-height: 100%;
 width: 100%;
 justify-content: center;
 align-items: center;

 position: relative;
}

.wrp-quote-validexchange {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;
 max-width: 20em;
}
.wrp-quote-validexchange p {
 display: flex;
 font-size: 1.187em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 text-align: center;
}

/* small view available */
.wrp-quote-validexchange-sm {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 position: absolute;
 top: 35%;
 left: 50%;
 transform: translate(-50%, -50%);

 width: 100%;
}
.wrp-quote-validexchange-sm p {
 display: flex;
 font-size: 1.188em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 max-width: 100vw;
 font-weight: 400;
 text-align: center;
}

.wrp-values-validExh {
 display: flex;
 flex-direction: column;
}
.wrp-values-validExh h2 span {
 display: flex;
 font-size: 2.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: -4px;
 color: var(--color-base);
}

.wrp-values-validExh-sm {
 display: flex;
 flex-direction: column;

 position: absolute;

 justify-content: center;
 align-items: center;

 top: 52%;
 left: 50%;

 transform: translate(-50%, -50%);
}

.wrp-values-validExh-sm h2 {
 display: flex;
 flex-direction: column;

 grid-gap: 0.5em;

 gap: 0.5em;

 justify-content: center;
 align-items: center;

 width: 100%;
}
.wrp-values-validExh-sm h2 span {
 display: flex;
 font-size: 1.8em;
 font-weight: 300;
 white-space: nowrap;
 font-family: var(--text-family);
 line-height: 0;
 letter-spacing: -3px;
 color: var(--color-base);
}
.unite-prix-currency {
 display: flex;
 font-size: 0.8em;
 font-family: var(--text-family);
 white-space: nowrap;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-ima-send-valide {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.wrp-ima-send-valide p {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-exchange-box {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100vh;

 grid-gap: 0.5em;

 gap: 0.5em;

 justify-content: center;
 align-items: center;

 position: relative;
}

.wrp-prix-exchange {
 display: flex;
 flex-direction: column;
 color: var(--color-base);
 margin-bottom: 7vh;
}
.wrp-prix-exchange p {
 display: flex;
 font-size: 3em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: -3px;
 color: var(--color-base);
}
.wrp-prix-exchange span {
 display: flex;
 font-size: 1.6em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: -1px;
}

/* Small screen */
.wrp-prix-exchange-sm {
 display: flex;
 flex-direction: column;
 position: absolute;

 justify-content: center;
 align-items: center;

 top: 50%;
 left: 50%;
 color: var(--color-base);

 transform: translate(-50%, -50%);
}
.wrp-prix-exchange-sm p {
 display: flex;
 font-size: 3em;
 font-weight: 300;
 font-family: var(--text-family);

 line-height: 1;
 letter-spacing: -3px;
 white-space: nowrap;
 color: var(--color-base);
}
.wrp-prix-exchange-sm span {
 display: flex;
 font-size: 2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-img-valide {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 margin-bottom: 1ch;
}
.wrp-img-valide img {
 height: 3em;
 width: 3em;
 border-radius: 50%;
 object-fit: cover;
}
.wrp-img-valide p {
 display: flex;
 font-size: 1.3em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-name-exchange {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 padding: 1.5ch 0;
 margin-bottom: 3vh;

 width: 100%;
}
.wrp-name-exchange h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 900;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.1px;
 white-space: nowrap;
}

/* small */
.wrp-name-exchange-sm {
 display: flex;
 flex-direction: column;

 position: absolute;
 top: 30%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-name-exchange-sm h2 {
 display: flex;
 font-size: 1.1em;
 font-weight: 900;
 white-space: nowrap;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.1px;
}

:root {
 --color-time: #01579b;
}

.wrp-time-exchange {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;
}
.wrp-time-exchange h2 {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 text-align: center;
 letter-spacing: 1px;
}
.wrp-time-exchange p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1.4;
 text-align: center;
 letter-spacing: 1px;
 color: #01579b;
 color: var(--color-time);
}

.wrp-valide-exh {
 display: flex;
 width: 100%;
 margin: 3ch 0;
 justify-content: space-between;
 align-items: center;
}
.wrp-valide-exh button {
 display: flex;
 width: 100%;
 margin: 1em;
 justify-content: center;
 align-items: center;
}

.wrp-valide-exh-sm {
 display: flex;
 flex-direction: column;

 width: 100%;
 max-width: 96vw;
 margin-top: 12vh;
}
.wrp-valide-exh-sm button {
 display: flex;
 margin: 0.7vh 0;
}
.wrp-valide-exh-sm button span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 1px;
 margin: 0.3em;
}

:root {
 --bg-avatar-yellow: #d9d9d9;
}

.wrp-avatar-profil-exh-dash {
 display: flex;
 margin: 1ch 0;
}
.avatar-circle-profil-exh-dash {
 display: flex;
 position: relative;
 color: #fff;

 background: var(--color-base);
}
.avatar-circle-profil-exh-dash img {
 display: flex;
 height: 6em;
 width: 6em;
 border-radius: 100%;
 border: none;
 background: #d9d9d9;
 background: var(--bg-avatar-yellow);
 object-fit: cover;
}

.wrp-exchange {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 min-height: 90vh;

 justify-content: center;
 align-items: center;

 position: relative;
}

.wrp-tontine-navbar-exfran {
 display: flex;
 width: 100%;
 height: 5ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back-exfran {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back-exfran span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 0;
 letter-spacing: 0px;
}

.wrp-tontine-navbar-exfran {
 display: flex;
 width: 100%;
 height: 5ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back-exfran {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back-exfran span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 0;
 letter-spacing: 0px;
}

.wrp-withdraw-succes {
 display: flex;
}

.wrp-box-well {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 /* min-height: 100vh; */
 justify-content: center;
 align-items: center;
}

.wrp-success-withdraw-name {
 display: flex;
 margin-top: 1ch;
}
.wrp-success-withdraw-name h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 1px;
}

/* Small Screen */
.wrp-success-withdraw-name-sm {
 display: flex;
 margin-top: 1ch;
}
.wrp-success-withdraw-name-sm h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 white-space: nowrap;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0.1px;
}

.wrp-success-withd-prix {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 margin: 5vh 0;
}
.wrp-success-withd-prix h2 {
 display: flex;
 flex-direction: column;
 font-size: 4em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: -3px;
}
.wrp-success-withd-prix span {
 display: flex;
 flex-direction: column;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 1px;
}

/* Small Screen */
.wrp-success-withd-prix-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 margin: 7vh 0;
}
.wrp-success-withd-prix-sm h2 {
 display: flex;
 font-size: 2.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: -3px;
}
.wrp-success-withd-prix-sm span {
 display: flex;
 flex-direction: column;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 1px;
}

.wrp-date-fcwell {
 display: flex;
}
.wrp-date-fcwell p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.5px;
}

/* Small */
.wrp-date-fcwell-sm {
 display: flex;
}
.wrp-date-fcwell-sm p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0.5px;
}

.wrp-success-btn {
 display: flex;
 flex-direction: column;
 color: #55a630;
}
.wrp-success-btn span {
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 100%;
}
.wrp-success-btn p {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0.1px;
}

/* Small */
.wrp-success-btn-sm {
 display: flex;
 flex-direction: column;
 color: #55a630;
}
.wrp-success-btn-sm span {
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 100%;
}
.wrp-success-btn-sm p {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.1px;
}

:root {
 --bg-avatar-yellow: #d9d9d9;
}

.avatar-circle-flags {
 display: flex;
 margin-top: 10vh;
}
.avatar-circle-flags img {
 display: flex;
 height: 6em;
 width: 6em;
 border-radius: 100%;
 border: none;
 background: #d9d9d9;
 background: var(--bg-avatar-yellow);
 object-fit: cover;
}

/* .wrp-contry-print-overview {
 display: flex;
 flex-direction: column;
 width: 100%;

 margin: 2vh 0;

 justify-content: center;
 align-items: center;
}
.wrp-contry-print-overview h1 {
 display: flex;
 font-size: 2em;
 font-weight: 500;
 font-family: var(--font-quote-name);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview p {
 display: flex;
 flex-direction: column;
 font-size: 1em;
 font-weight: 300;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: justify;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
} */

/* .wrp-contry-print-overview {
 display: flex;
 flex-direction: column;
 width: 100%;

 margin: 2vh 0;

 justify-content: center;
 align-items: center;
}
.wrp-contry-print-overview h1 {
 display: flex;
 font-size: 2em;
 font-weight: 500;
 font-family: var(--font-quote-name);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
} */

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 4ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-nav-invited {
 display: flex;
}
.tontine-nav-invited img {
 height: 1em;
 width: 1em;
 object-fit: cover;
}
.tontine-send-invited img {
 height: 0.3em;
 width: 0.3em;
}

.wrp-msg-output-ticked {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 width: 100%;
 max-width: 95vw;
 margin-top: 6vh;
 background-color: #fff;
}
@media screen {
 .wrp-msg-output-ticked {
  visibility: hidden;
 }
}

/* logo available */

:root {
 --font-condensed: "Overpass", sans-serif;
 --font-title-name: "Rubik Vinyl", cursive;
 --font-quote-name: "Nunito Sans", sans-serif;
}

.wrp-logo-print-tickets {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-top: 2vh;
 width: 100%;
}

.wrp-logo-print-tickets img {
 height: 3em;
 width: 15em;
 object-fit: cover;
}
.wrp-logo-print-tickets h2 {
 display: flex;
 font-size: 1.4em;
 font-weight: 500;
 font-family: "Rubik Vinyl", cursive;
 font-family: var(--font-title-name);
 line-height: 1;
 letter-spacing: 1px;
 border-bottom: 2px solde black;
}

.wrp-title-print-tickets-client {
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
}
.wrp-title-print-tickets-client h2 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 font-family: var(--font-condensed);
 line-height: 1;
 white-space: nowrap;
 letter-spacing: -1px;
}

/*  */

.wrp-quote-tiket-msg {
 display: flex;
 margin-top: 1.5vh;
}
.wrp-quote-tiket-msg p {
 display: flex;
 font-size: 1.1em;
 font-weight: 400;
 line-height: 1.1;
 font-family: var(--font-condensed);
 letter-spacing: -1px;
}

.wrp-line-ticket-client-dash {
 display: flex;
 width: 100%;
 height: 0.2em;
 border-bottom: 0.162em dashed black;
}

.wrp-contry-print-overview {
 display: flex;
 flex-direction: column;
 width: 100%;

 margin: 2vh 0;

 justify-content: center;
 align-items: center;
}
.wrp-contry-print-overview h1 {
 display: flex;
 font-size: 2em;
 font-weight: 500;
 font-family: var(--font-quote-name);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: -1px;
}
.wrp-contry-print-overview span {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: -1px;
}

/* .wrp-input-send-money {
 display: flex;
 flex-direction: column;

 width: 100%;
 max-width: 20em;

 position: absolute;
 top: 60%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-send-money form {
 display: flex;
 flex-direction: column;
 gap: 1em;
 justify-content: center;
 align-items: center;
}
.wrp-input-send-money form input {
 display: flex;
 flex-direction: column;
 font-size: 0.92em;
 font-weight: 400;
 text-align: center;
 line-height: 1;
 letter-spacing: 1px;
 width: 100%;
 padding: 0.7em;
 font-family: "AntipastoPro";
 border: none;
 border-radius: 0.2em;
 color: black;
 background: rgba(201, 201, 201, 0.413);
}

small view input
.wrp-input-send-money-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;

 position: absolute;
 top: 90%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-send-money-sm form {
 display: flex;
 flex-direction: column;
 gap: 1em;
}
.wrp-input-send-money-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 0.8em;
 font-weight: 400;
 padding: 0.8em;
 font-family: "AntipastoPro";
 text-align: center;
 line-height: 1;
 letter-spacing: 1px;
 width: 95vw;
 border: none;

 color: black;
 background: rgba(201, 201, 201, 0.413);
} */

/* .wrp-quote-send-money {
 display: flex;
 flex-direction: row;
 justify-content: start;
 align-items: start;

 position: absolute;
 top: 50%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-quote-send-money p {
 display: flex;
 font-weight: 300;
 text-align: center;
 width: 100%;
 max-width: 340px;
}

small
.wrp-quote-send-money-sm {
 display: flex;
 flex-direction: row;
 width: 100%;
 max-width: 95vw;

 justify-content: center;
 align-items: center;

 position: absolute;
 top: 45%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-quote-send-money-sm p {
 display: flex;
 font-weight: 300;
 text-align: start;
} */

.wrp-simple-valid {
 display: flex;
 flex-direction: column;

 width: 100%;
 height: 100vh;
 position: relative;
}

.wrp-arrow-profil-money {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.wrp-arrow-profil-money-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-title-exchange-fc {
 display: flex;

 position: absolute;
 top: 2%;
 left: 50%;

 transform: translate(-50%, 50%);
}
.wrp-title-exchange-fc h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0.1px;
}

.wrp-title-exchange-fc-sm {
 display: flex;

 position: absolute;
 top: 1.5%;
 left: 50%;

 transform: translate(-50%, 50%);
}
.wrp-title-exchange-fc-sm h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0.1px;
}

.wrp-logo-send {
 display: flex;

 position: absolute;
 top: 25%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-logo-send img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

.wrp-logo-send-sm {
 display: flex;

 position: absolute;
 top: 20%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-logo-send-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

.flex-id-pin-send {
 display: grid;
 place-items: center;
}
.flex-id-pin-send p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 white-space: nowrap;
 letter-spacing: 0px;
 margin-left: 1em;
 font-family: var(--text-family);
 color: #7f7f7f;
}

.wrp-form-input-pin {
 display: flex;
 flex-direction: column;

 height: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-form-input-pin form {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 25em;
 padding: 1em;
}
.wrp-form-input-pin input {
 display: flex;
 font-size: 1.5em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 2px;
 text-align: center;
 padding-left: 1.5em;
 width: 100%;
 border: none;
 color: black;
}
.wrp-form-input-pin form button {
 display: flex;
 margin-top: 2vh;
}

.info-detect {
 display: flex;
 font-size: 0.9em;
 font-weight: 100;
 line-height: 1.2;
 letter-spacing: 0px;
 font-family: var(--text-family);

 color: #7f7f7f;
}

.wrp-form-input-pin form .wrp-errors-code {
 display: flex;
 font-size: 0.3em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 margin-top: 2em;
 width: 100%;
 color: crimson;
}
.wrp-form-input-pin form .wrp-errors-code-transparent {
 display: flex;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 width: 100%;
 color: transparent;
}

/* .wrp-quote-send-money {
 display: flex;
 flex-direction: row;
 justify-content: start;
 align-items: start;

 position: absolute;
 top: 50%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-quote-send-money p {
 display: flex;
 font-weight: 300;
 text-align: center;
 width: 100%;
 max-width: 340px;
}

small
.wrp-quote-send-money-sm {
 display: flex;
 flex-direction: row;
 width: 100%;
 max-width: 95vw;

 justify-content: center;
 align-items: center;

 position: absolute;
 top: 45%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-quote-send-money-sm p {
 display: flex;
 font-weight: 300;
 text-align: start;
 color: rgb(172, 172, 172);
} */

.wrp-simple-valid {
 display: flex;
 flex-direction: column;

 width: 100%;
 height: 100vh;
 position: relative;
}

.wrp-arrow-profil-money {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.wrp-arrow-profil-money-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-title-exchange-fc {
 display: flex;

 position: absolute;
 top: 2%;
 left: 50%;

 transform: translate(-50%, 50%);
}
.wrp-title-exchange-fc h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.1px;
}

.wrp-title-exchange-fc-sm {
 display: flex;

 position: absolute;
 top: 1.5%;
 left: 50%;

 transform: translate(-50%, 50%);
}
.wrp-title-exchange-fc-sm h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0.1px;
}

/* .wrp-logo-send-usd {
 display: flex;
 position: absolute;

 top: 45%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-logo-send-usd img {
 display: flex;
 width: 5em;
 height: 5em;
 object-fit: cover;
}

.wrp-logo-send-usd-sm {
 display: flex;
 position: absolute;
 top: 30%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-logo-send-usd-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
} */

/* .wrp-input-send-money {
 display: flex;
 flex-direction: column;

 width: 100%;
 max-width: 20em;

 position: absolute;
 top: 60%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-send-money form {
 display: flex;
 flex-direction: column;
 gap: 1em;
 justify-content: center;
 align-items: center;
}
.wrp-input-send-money form input {
 display: flex;
 flex-direction: column;
 font-size: 0.92em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 1px;
 width: 100%;
 padding: 0.7em;
 font-family: "AntipastoPro";
 border: none;
 border-radius: 0.2em;
 color: black;
 background: rgba(201, 201, 201, 0.413);
}

small view input
.wrp-input-send-money-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;

 position: absolute;
 top: 90%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-send-money-sm form {
 display: flex;
 flex-direction: column;
 gap: 1em;
}
.wrp-input-send-money-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 0.8em;
 font-weight: 400;
 padding: 0.8em;
 font-family: "AntipastoPro";
 line-height: 1;
 letter-spacing: 1px;
 width: 95vw;
 border: none;

 color: black;
 background: rgba(201, 201, 201, 0.413);
} */

/* .wrp-qtform-send-money {
 display: flex;
}
.wrp-qtform-send-money p {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 text-align: start;
 width: 100%;
}

small
.wrp-qtform-send-money-sm {
 display: flex;
}
.wrp-qtform-send-money-sm p {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 text-align: center;
 width: 100%;
} */

.wrp-simple-valid {
 display: flex;
 flex-direction: column;

 width: 100%;
 height: 100vh;
 position: relative;
}

.wrp-arrow-profil-money {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.wrp-arrow-profil-money-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-title-exchange-fc {
 display: flex;

 position: absolute;
 top: 2%;
 left: 50%;

 transform: translate(-50%, 50%);
}
.wrp-title-exchange-fc h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.1px;
}

.wrp-title-exchange-fc-sm {
 display: flex;

 position: absolute;
 top: 1.5%;
 left: 50%;

 transform: translate(-50%, 50%);
}
.wrp-title-exchange-fc-sm h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.1px;
}

/* .wrp-logo-send {
 display: flex;

 position: absolute;
 top: 45%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-logo-send img {
 display: flex;
 width: 5em;
 height: 5em;
 object-fit: cover;
}

.wrp-logo-send-sm {
 display: flex;

 position: absolute;
 top: 30%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-logo-send-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
} */

/* .wrp-input-send-money {
 display: flex;
 flex-direction: column;

 width: 100%;
 max-width: 20em;

 position: absolute;
 top: 60%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-send-money form {
 display: flex;
 flex-direction: column;
 gap: 1em;
 justify-content: center;
 align-items: center;
}
.wrp-input-send-money form input {
 display: flex;
 flex-direction: column;
 font-size: 0.92em;
 font-weight: 400;
 text-align: center;
 line-height: 1;
 letter-spacing: 1px;
 width: 100%;
 padding: 0.7em;
 font-family: "AntipastoPro";
 border: none;
 border-radius: 0.2em;
 color: black;
 background: rgba(201, 201, 201, 0.413);
}

small view input
.wrp-input-send-money-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;

 position: absolute;
 top: 90%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-send-money-sm form {
 display: flex;
 flex-direction: column;
 gap: 1em;
}
.wrp-input-send-money-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 0.8em;
 font-weight: 400;
 padding: 0.8em;
 font-family: "AntipastoPro";
 text-align: center;
 line-height: 1;
 letter-spacing: 1px;
 width: 95vw;
 border: none;

 color: black;
 background: rgba(201, 201, 201, 0.413);
} */

/* .wrp-quote-send-money {
 display: flex;
 flex-direction: row;
 justify-content: start;
 align-items: start;

 position: absolute;
 top: 50%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-quote-send-money p {
 display: flex;
 font-weight: 300;
 text-align: center;
 width: 100%;
 max-width: 340px;
}

small
.wrp-quote-send-money-sm {
 display: flex;
 flex-direction: row;
 width: 100%;
 max-width: 95vw;

 justify-content: center;
 align-items: center;

 position: absolute;
 top: 45%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-quote-send-money-sm p {
 display: flex;
 font-weight: 300;
 text-align: start;
} */

.wrp-simple-valid {
 display: flex;
 flex-direction: column;

 width: 100%;
 height: 100vh;
 position: relative;
}

.wrp-arrow-profil-money {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.wrp-arrow-profil-money-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-title-exchange-fc {
 display: flex;

 position: absolute;
 top: 2%;
 left: 50%;

 transform: translate(-50%, 50%);
}
.wrp-title-exchange-fc h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.1px;
}

.wrp-title-exchange-fc-sm {
 display: flex;

 position: absolute;
 top: 1.5%;
 left: 50%;

 transform: translate(-50%, 50%);
}
.wrp-title-exchange-fc-sm h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.1px;
}

/* .wrp-logo-send {
 display: flex;

 position: absolute;
 top: 45%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-logo-send img {
 display: flex;
 width: 5em;
 height: 5em;
 object-fit: cover;
}

.wrp-logo-send-sm {
 display: flex;

 position: absolute;
 top: 30%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-logo-send-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
} */

.wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;
 width: 100%;
 height: 100vh;
}

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 2.5em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: -1px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.3em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1.3;
 letter-spacing: 0px;
} */

.wrp-form-input-nows {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.wrp-form-input-nows form {
 display: flex;
 flex-direction: column;
 padding: 1em;

 width: 100%;
 max-width: 100vw;
}
.wrp-form-input-nows input {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;

 letter-spacing: 1px;
 text-align: center;
 padding-left: 1em;
 border: none;
}
.wrp-form-input-nows form button {
 display: flex;
 margin-top: 2vh;
 font-family: var(--text-family);
}

.wrp-form-input-nows form .wrp-errors-code {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
 margin-top: 2em;
 width: 100%;
 color: crimson;
}
.wrp-form-input-nows form .wrp-errors-code-transparent {
 display: flex;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 width: 100%;
 color: transparent;
}

.wrp-arrow-profil-money {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.wrp-arrow-profil-money-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-ima-key-now {
 display: flex;
 position: absolute;

 top: 10%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.wrp-upgrade {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100vh;
}

:root {
 --purple: #48cae4;
}
.icon-pin {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 color: #48cae4;
 color: var(--purple);
}

.wrp-tontine-navbar-change-pin {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

/* .wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100vh;
} */

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
} */

.wrp-arrow-profil-money {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.wrp-arrow-profil-money-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-upgrade {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100vh;
}

/* wrp-success-pin */
.wrp-success-pin {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 90vh;
}

:root {
 --purple: #022b3a;
}
.icon-pin {
 display: flex;
 flex-direction: column;
 justify-content: center;
 font-family: var(--text-family);
 align-items: center;
 color: #022b3a;
 color: var(--purple);
}

/* .wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
} */

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

:root {
 --cl-color: #1c2321;
 --bg-color: #eef1ef;
}
.wrp-hm-brokers {
 display: flex;
 position: relative;

 width: 100%;
 height: 100vh;

 justify-content: center;
 align-items: center;

 color: #1c2321;

 color: var(--cl-color);
 background-color: #eef1ef;
 background-color: var(--bg-color);
}
/* Small */
.wrp-hm-brokers-sm {
 display: flex;
 position: relative;

 width: 100%;
 height: 100vh;

 justify-content: center;
 align-items: center;

 color: #1c2321;

 color: var(--cl-color);
 background-color: #eef1ef;
 background-color: var(--bg-color);
}
.wrp-box-hm-brokers {
 display: flex;
}

/* Title Brokers */
.wrp-title-hm-broker {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: start;

 position: absolute;

 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);

 width: 100%;
 max-width: 30em;
}
.wrp-title-hm-broker h1 {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
.wrp-title-hm-broker p {
 display: flex;
 font-size: var(--para-small);
 font-weight: 300;
 line-height: 1.1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
/* Title Brokers Small*/
.wrp-title-hm-broker-sm {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: start;

 position: absolute;

 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);

 width: 100%;
 max-width: 90vw;
}
.wrp-title-hm-broker-sm h1 {
 display: flex;
 font-size: 2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-title-hm-broker-sm p {
 display: flex;
 font-size: var(--para-small);
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
}

.wrp-btn-brokers {
 display: flex;
 width: 100%;
 height: 100%;
 max-width: 15em;
}
.wrp-btn-brokers button {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--text-family);

 outline: none;
 border: none;

 justify-content: center;
 align-items: center;

 outline-style: none;
 white-space: nowrap;

 padding: 2vh 0;

 border-radius: 1.1em;
 width: 100%;
 background-color: var(--bgcolor-btn);
 color: white;
}
.Btn-Broker {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--text-family);

 outline: none;
 border: none;

 justify-content: center;
 align-items: center;

 outline-style: none;
 white-space: nowrap;

 padding: 1.155ch 0;

 border-radius: 0.9em;
 width: 100%;
 background-color: var(--bgcolor-btn);
 color: white;
}

.wrp-iconshop-hm {
 display: flex;
 flex-direction: column;
 position: absolute;

 bottom: 5%;
 right: 5%;
 transform: translate(-50%, -50%);
}
.wrp-iconshop-hm p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-pd-shop {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 border-radius: 100%;

 padding: 0.5em;
 background-color: #bcc7c85b;
}

/* Small */
.wrp-iconshop-hm-sm {
 display: flex;
 flex-direction: column;
 position: absolute;

 bottom: 5%;
 right: 5%;
 transform: translate(-50%, -50%);
}
.wrp-iconshop-hm-sm p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-back-brokers {
 display: flex;
}
.wrp-back-box-brokers {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 cursor: pointer;
}

.wrp-back-box-brokers .color-box-icons-edit {
 color: crimson;
}

.wrp-back-brokers .wrp-back-box-brokers span {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 margin-left: -0.3em;
 white-space: nowrap;
}

.wrp-back-long-brokers {
 display: flex;
}
.wrp-back-long-box-brokers {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 cursor: pointer;
}
.wrp-back-long-brokers .wrp-back-box-brokers span {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 white-space: nowrap;
}

.qte-broker-unite {
 display: flex;
 margin: 8vh 0;
 width: 100%;
 max-width: 30em;
}
.qte-broker-unite p {
 display: flex;
 font-size: 1.26em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0.5px;
 text-align: center;
}

.wrp-ima-broker-network img {
 height: 2.9em;
 height: 2.9em;
 border-radius: 100%;
 object-fit: cover;
 object-position: 50%;
}

.list-unite nav ul {
 display: flex;
 flex-direction: row;
 list-style: none;
 width: 100%;
 justify-content: space-between;
 align-items: center;

 margin-bottom: 10vh;
}
.list-unite nav ul li {
 display: flex;
 margin: 0 3vw;
}

.wrp-box-broker-unite {
 display: flex;
 flex-direction: column;
 width: 100%;
}

.wrp-hm-brokers-unite {
 display: flex;
 flex-direction: column;
 position: relative;

 width: 100%;
 height: 100vh;

 color: var(--cl-color);
 background-color: var(--bg-color);
}
/* Small */
.wrp-hm-brokers-unite-sm {
 display: flex;
 position: relative;

 width: 100%;
 height: 100vh;

 color: var(--cl-color);
 background-color: var(--bg-color);
}

.navbar-top-broker {
 display: flex;
 justify-content: start;
 align-items: start;
 width: 100%;
}
.navbar-top-broker nav {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 1.8vh 3%;
}
.navbar-top-broker nav img {
 height: 3em;
 width: 3em;
 object-fit: cover;
 cursor: pointer;
}

.wrp-btn-dealer-unit {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 position: absolute;

 left: 85%;
 bottom: 5%;
}
.wrp-btn-dealer-unit img {
 height: 3em;
 width: 3em;
 border-radius: 100%;
 object-fit: cover;
}
.wrp-btn-dealer-unit h4 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 text-align: center;
 letter-spacing: 0px;
 margin-top: 1vh;
}

/* Small dealer  */
.wrp-btn-dealer-unit-sm {
 display: grid;
 place-items: center;
 position: absolute;

 left: 75%;
 bottom: 5%;
}
.wrp-btn-dealer-unit-sm img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}
.wrp-btn-dealer-unit-sm h4 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 2vh;
}
.wrp-dealer-unit {
 display: grid;
 place-items: center;
 cursor: pointer;
}
.wrp-dealer-unit p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-buy-brokers {
 display: flex;
 position: relative;
 flex-direction: column;

 width: 100%;
 height: 100%;

 color: var(--cl-color);
 background-color: var(--bg-color);
}
.wrp-buy-brokers .wrp-buy-brokers-sub {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 min-height: 98vh;
 width: 100%;
}

.wrp-input-broker {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;
}
.wrp-input-broker form {
 display: flex;
 flex-direction: column;
}
.wrp-input-broker form input {
 display: flex;
 flex-direction: column;
 font-size: 2em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 1px;
 width: 100%;
 border: none;

 margin-top: 1vh;

 border-bottom: 1px solid #30343f;
 color: #1c2321;
}

.wrp-input-broker form button {
 margin-top: 2vh;
}

.wrp-ima-brokers img {
 display: flex;
 height: 1.8em;
 width: 1.8em;
 border-radius: 50%;
 object-fit: cover;
}

.wrp-qt-buy-brokers {
 display: flex;
 width: 100%;
 max-width: 26em;
}
.wrp-qt-buy-brokers p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1.1;
 text-align: start;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

/* Small */
.wrp-qt-buy-brokers-sm {
 display: flex;
 width: 100%;
 max-width: 95vw;
}
.wrp-qt-buy-brokers-sm p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1.1;
 text-align: start;
 letter-spacing: 0.5px;
}

.wrp-qt-broker-unite {
 display: flex;
}
.wrp-qt-broker-unite p {
 display: flex;
 font-size: 1.187em;
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0.5px;
 font-family: var(--text-family);
 text-align: center;
 max-width: 20em;
}

.list-unite-select {
 display: flex;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.list-unite-select nav ul {
 display: flex;
 flex-direction: column;
 grid-gap: 0.5em;
 gap: 0.5em;
 list-style: none;
}

.wrp-box-broker-unite {
 display: flex;
 flex-direction: column;

 position: absolute;
 top: 50%;
 left: 50%;

 justify-content: center;
 align-items: center;

 transform: translate(-50%, -50%);
 width: 100%;
}

/* .wrp-hm-brokers-unite {
 display: flex;
 position: relative;

 width: 100%;
 height: 100vh;

 color: var(--cl-color);
 background-color: var(--bg-color);
}
Small
.wrp-hm-brokers-unite-sm {
 display: flex;
 position: relative;

 width: 100%;
 height: 100vh;

 color: var(--cl-color);
 background-color: var(--bg-color);
} */

.wrp-buy-brokers-prix {
 display: flex;
 flex-direction: column;
 position: relative;

 width: 100%;
 height: 100%;

 justify-content: center;
 align-items: center;

 padding: 0.5vh 0;
 color: var(--cl-color);
 background-color: var(--bg-color);
}

.wrp-input-broker-prixs {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 min-height: 90vh;

 justify-content: center;
 align-items: center;
}
.wrp-input-broker-prixs form {
 display: flex;
 flex-direction: column;
 padding: 1em;
}
.wrp-input-broker-prixs form h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
.wrp-input-broker-prixs form input {
 display: flex;
 flex-direction: column;
 font-size: 2.4em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 1px;
 border: transparent;
 text-align: center;
 border-radius: 0.1em;
 color: #1c2321;

 background: transparent;
}
.wrp-input-broker-prixs form button {
 font-family: var(--text-family);
 margin-top: 2vh;
}
.wrp-input-broker-prixs form h3 {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);

 color: red;
}

.wrp-prix-flex-broker {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 grid-gap: 0.5em;
 gap: 0.5em;
}
.wrp-prixs-flex-broker {
 display: flex;
 flex-direction: row;
 grid-gap: 0.5em;
 gap: 0.5em;
}

.wrp-prix-flex-box-broker {
 display: flex;
 flex-direction: column;
 margin-top: 2vh;
}
.box-flex-prix-brokers {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: start;
 align-items: start;
}
.wrp-flex-prix-counts {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 width: 100%;
}

.flex-count-dialog {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.flex-count-dialog-sub {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 width: 100%;
 align-items: center;
}
.view-dialog-billing {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.view-dialog-billing h2 {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.view-dialog-billing-quote {
 display: flex;
 width: 100%;
}
.view-dialog-billing .view-dialog-billing-quote h3 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 white-space: nowrap;

 line-height: 1;
 letter-spacing: 0px;
}
.view-dialog-billing .view-dialog-billing-quote p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 font-family: var(--text-family);
 white-space: nowrap;

 width: 5em;
 line-height: 1;
 letter-spacing: 0px;
}
.margin-dialog-count {
 display: flex;
 flex-direction: column;
 margin-right: 5em;
}

.divider-dialog {
 display: flex;

 justify-content: end;
 align-items: flex-end;
 height: 1px;
 margin-bottom: 1vh;
 background-color: #c8dee4;
}

.wrp-ima-brokers-prix img {
 display: flex;
 height: 1.5em;
 width: 1.5em;
 border-radius: 50%;
 object-fit: cover;
}

.wrp-nav-buy {
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 padding: 0.5em;
}

:root {
 --bg-avatar-yellow: transparent;
}

.profil-caise {
 display: flex;
}
.profil-caise img {
 display: flex;
 height: 3em;
 width: 3em;
 border-radius: 100%;
 border: none;
 background: transparent;
 background: var(--bg-avatar-yellow);
 object-fit: cover;
 border: 0.1em solid white;
}
.profil-caise-flex {
 display: flex;
 grid-gap: 0.5em;
 gap: 0.5em;
 justify-content: center;
 align-items: center;
}

.view-name-caise {
 display: flex;
 flex-direction: column;
}
.view-name-caise h2 {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 900;
 line-height: 1;
 letter-spacing: 0.1px;
 text-align: center;

 white-space: nowrap;
}
.view-name-caise span {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 900;
 line-height: 1;
 letter-spacing: 0.1px;
 white-space: nowrap;
}

/* small */
.view-name-caise-sm {
 display: flex;
 flex-direction: column;

 position: absolute;
 top: 30%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.view-name-caise-sm h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 900;
 white-space: nowrap;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.1px;
}

.wrp-network-stock {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.wrp-network-stock div {
 display: flex;
 width: 100%;
 cursor: pointer;
 border: 0.1em solid white;
 background: #b5c8d9;
 color: black;
}
.wrp-network-stock h2 {
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 width: 100%;
 text-align: center;

 padding: 1em;
 justify-content: end;
 align-items: end;
 white-space: nowrap;

 font-family: var(--text-family);
 letter-spacing: 0px;
}
.wrp-network-stock input {
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 width: 100%;
 text-align: center;

 border: none;
 cursor: pointer;
 background-color: #b5c8d9;
 border-left: 0.1em solid white;

 padding: 1em;
 justify-content: end;
 align-items: end;
 white-space: nowrap;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

/* .wrp-input-stock {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0.3em;
}
.wrp-input-stock-box-prix {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 padding: 0.5em;
}
.wrp-input-stock-subbox-prix {
 display: flex;
 flex-direction: row;
 width: 100%;
 justify-content: space-between;
 align-items: center;
}
.wrp-input-stock-subbox-prix h3 {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 list-style: none;
 color: #2ecc2e;
 letter-spacing: 0px;
}
.wrp-devise-switch {
 display: flex;
 justify-content: center;
 align-items: center;
}
.wrp-input-stock form button {
 margin-top: 5vh;
} */

.stock-wallet-prix {
 display: flex;
}
.stock-wallet-prix span {
 display: flex;
 font-size: 1.5em;
 font-family: var(--text-family);
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
}
.margin-input-stock {
 display: flex;
 margin-left: 0.3em;
}

.title-stock-input {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin-top: 5vh;
}

.title-stock-input h1 {
 /* display: flex; */
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 width: 100%;
 text-align: center;

 padding: 1em;
 justify-content: end;
 align-items: end;
 white-space: nowrap;

 font-family: var(--text-family);
 letter-spacing: 0px;
 border-left: 0.1em solid white;
 background: #59a5d8;
 color: white;
}

.devise-ima-switch {
 display: flex;
}
.devise-ima-switch img {
 display: flex;
 height: 2.7em;
 width: 2.7em;
 border-radius: 100%;
 object-fit: cover;
}

.wrp-rate-stock {
 display: flex;

 justify-content: center;
 align-items: center;

 padding: 0.8em;

 width: 100%;
 background: #59a5d8;
 color: #fff;
}

.wrp-rate-stock span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 white-space: nowrap;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

/* .progressbar-stock {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 margin-top: 3vh;
}
.progressbar {
 position: relative;
 overflow: hidden;
 width: 350px;
 height: 30px;
 border-radius: 20px;
 background-color: #abb;
}
.progressPercent {
 font-weight: 600;
 font-family: var(--text-family);
 position: absolute;
 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
 color: #fff;
 text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
} */

.nav-stock {
 display: flex;
}

.box-nav-stock {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0 3%;
}

.nav-stock {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 height: 100%;
}
.nav-stock img {
 height: 1.8em;
 width: 1.8em;
 object-fit: cover;
 object-position: 100%;
}
.nav-stock p {
 display: flex;
 font-size: 1.1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.title-nav-fiat {
 display: flex;
 margin: 2vh 0;
}
.title-nav-fiat h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-search-stock {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0 3%;
}
.wrp-search-stock input {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.user-box-stock {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;

 margin-top: 3vh;
}
.user-box-stock p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: -1;
 letter-spacing: 0px;
}
.flex-list-user-stock {
 display: flex;
 justify-content: center;
 align-items: center;
 grid-gap: 0.5em;
 gap: 0.5em;
}
.sub-list-user {
 display: flex;
 flex-direction: column;
 grid-gap: -2vh;
 gap: -2vh;
}

/* .flex-head-list-cmd {
 display: flex;
}
.flex-head-list-cmd header {
 position: fixed;
 top: 0;
 height: 8vh;
 width: 100%;
 background-color: #264653;
}
.flex-head-list-cmd header li {
 list-style: none;
}
.flex-head-list-cmd header .container {
 width: 100%;
 margin: auto;
}
.flex-head-list-cmd header .navbar {
 display: flex;
 width: 100%;
 margin-top: 2vh;

 justify-content: center;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmd header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-cmd section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-cmd section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-cmd section li {
 margin-top: 3vh;
 width: 100%;
 margin: 6vh 0;
 border-bottom: 0.01em solid #cfd2cd;
}

.flex-head-list-cmd .box-data-cmd-list {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmd .box-data-cmd-list h2 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 font-family: var(--text-family);
 color: #219ebc;
 line-height: 1;
 letter-spacing: 0px;
}

.flex-head-list-cmd .box-data-cmd-operator {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.flex-head-list-cmd .cmd-operator-title {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: flex-start;
 width: 100%;
}
.flex-head-list-cmd .cmd-operator-title h3 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 color: #264653;

 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.flex-head-list-cmd .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.flex-head-list-cmd .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #4361ee;
}

.flex-head-list-cmd .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 6em;
}

.flex-head-list-cmd .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
} */

.flex-head-list-cmds {
 display: grid;
 place-items: center;
}
.flex-head-list-cmds header {
 position: fixed;
 top: 0;
 z-index: 9999;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-cmds header li {
 list-style: none;
}
.flex-head-list-cmds header .container {
 width: 100%;
}
.flex-head-list-cmds header .navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmds header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-cmds section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 10em;
}
.flex-head-list-cmds section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-cmds section li {
 margin-top: 3vh;
 width: 100%;
 border-bottom: 0.01em solid #cfd2cd;
}

.flex-head-list-cmds section input {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.flex-head-list-cmds .box-data-cmd-list {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmds .box-data-cmd-list h2 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 font-family: var(--text-family);
 color: #219ebc;
 line-height: 1;
 letter-spacing: 0px;
}

.flex-head-list-cmds .box-data-cmd-operator {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.flex-head-list-cmds .cmd-operator-title {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: flex-start;
 width: 100%;
}
.flex-head-list-cmds .cmd-operator-title h3 {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 color: #264653;

 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
.flex-head-list-cmds .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.flex-head-list-cmds .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 0.5em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #4361ee;
}
.flex-head-list-cmds .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 6em;
}
.flex-head-list-cmds .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
}

.wrp-flex-row-commi {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 width: 100%;
}

.wrp-buy-brokers-thank {
 display: flex;
 position: relative;
 flex-direction: column;

 width: 100%;
 height: 100vh;

 color: var(--cl-color);
 background-color: var(--bg-color);
}
.wrp-buy-brokers-thank-sub {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100%;
}

.wrp-qt-buy-brokers {
 display: flex;
 width: 100%;
 max-width: 26em;
 margin-bottom: 2vh;
}
.wrp-qt-buy-brokers p {
 display: flex;
 font-size: 1.198em;
 font-weight: 300;
 line-height: 1.2;
 text-align: start;
 letter-spacing: 0px;
}

/* Small */
.wrp-qt-buy-brokers-sm {
 display: flex;
 width: 100%;
 padding: 1em;
}
.wrp-qt-buy-brokers-sm p {
 display: flex;
 font-size: 1.175em;
 font-weight: 300;
 line-height: 1.1;
 text-align: start;
 letter-spacing: 0.5px;
}

.wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100vh;
}

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
} */

/* Design */
.block-flex-center-wifi {
 display: grid;
 place-items: center;
}

.pin-arrow-broker {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.pin-arrow-broker-sm {
 display: flex;
 cursor: pointer;
 position: absolute;
 top: 5%;
 left: 1.5em;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-ima-key-now {
 display: flex;
 position: absolute;

 top: 10%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.wrp-box-hm-brokers-save {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100%;
 min-height: 90vh;
}
.wrp-hm-brokers {
 display: flex;
 flex-direction: column;
}

.quote-save {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
 width: 100%;
 text-align: center;
 max-width: 23em;
}

.wrp-box-fiat {
 display: flex;
 flex-direction: column;
 height: 100%;
 min-height: 100vh;
 width: 100%;
 background-color: var(--bg-color);
}

.navbar-top-broker-fiat nav {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0.5em;
 margin-bottom: 10vh;
}

/* Wallet css  */
.wrp-wallet {
 display: flex;
 flex-direction: column;
}

/* .bg-image-wallet {
 display: flex;
 min-height: 100vh;
 position: absolute;
}

.bg-image-wallet::before {
 content: "";
 display: flex;
 flex-direction: column;
 background-image: url(/src/images/abstract.jpg);
 background-size: cover;
 background-repeat: no-repeat;

 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;

 background-color: black;

 opacity: 0.5;
} */

/* View nav wallet */
.wrp-nav-wallet-slide {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0 3%;
 color: white;

 height: 6ch;
 z-index: 10;
}

/* @import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300&display=swap");

:root {
font-family: var(--text-family);
} */

.wrp-wallet-money div {
 display: flex;
 justify-content: center;
 align-items: center;

 width: 100%;
 padding: 8vh 0;
 z-index: 10;
}
.wrp-wallet-money h4 {
 display: flex;
 font-size: 4em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-wallet-money h5 {
 display: flex;
 font-size: 2.7em;
 margin-top: 2vh;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-wallet-money h3 {
 display: flex;
 font-size: 2em;
 padding-bottom: 4vh;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.ima-wallet-devise-slide {
 display: flex;
}
.ima-wallet-devise-slide img {
 height: 1.5em;
 width: 1.5em;
 object-fit: cover;
}

.slide-money-small {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0 3%;
}
.slide-money-small p {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: -1px;
}

.slide-money-small div {
 display: flex;
 justify-content: center;
 align-items: center;
}

.slide-money-small img {
 height: 1.1em;
 width: 1.1em;
 object-fit: cover;
 border-radius: 100%;
 margin-right: 0.3em;
}

.quote-wallet-money {
 display: flex;
 margin-top: 5vh;

 width: 100%;
 justify-content: center;
 align-items: center;
}
.quote-wallet-money p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-frais-current {
 display: flex;
 position: absolute;
 flex-direction: column;

 width: 100%;
 justify-content: center;
 align-items: center;
 max-width: 95vw;

 top: 90%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-frais-current span {
 display: flex;

 justify-content: center;
 align-items: center;

 font-size: 1em;
 font-weight: 300;
 text-align: center;
 line-height: 1.2;
 letter-spacing: 0px;
}
.wrp-frais-current-sm {
 display: flex;
 position: absolute;
 flex-direction: column;

 width: 100%;
 justify-content: center;
 align-items: center;
 max-width: 95vw;

 top: 90%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-frais-current-sm span {
 display: flex;
 flex-direction: column;
 font-size: var(--para-small);
 text-align: center;
 padding: 0.4em;
 line-height: 1;
 letter-spacing: 0px;
 max-width: 70vw;
}

.bg-image-wallet {
 display: flex;
 flex-direction: column;
 background-image: url(/static/media/abstract.1f8ef539.jpg);
 height: 100vh;
 background-size: cover;
 background-repeat: no-repeat;
}

/* @import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300&display=swap");

:root {
font-family: var(--text-family);
} */

.wrp-wallet-money div {
 display: flex;
 justify-content: center;
 align-items: center;

 width: 100%;
 padding: 8vh 0;
 z-index: 10;
}
.wrp-wallet-money h4 {
 display: flex;
 font-size: 4em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-wallet-money h5 {
 display: flex;
 font-size: 2.7em;
 margin-top: 2vh;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-wallet-money h3 {
 display: flex;
 font-size: 2em;
 padding-bottom: 4vh;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.ima-wallet-devise-slide {
 display: flex;
}
.ima-wallet-devise-slide img {
 height: 1.5em;
 width: 1.5em;
 object-fit: cover;
}

.slide-money-small {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0 3%;
}
.slide-money-small p {
 display: flex;
 font-size: 2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: -1px;
}

.slide-money-small div {
 display: flex;
 justify-content: center;
 align-items: center;
}

.slide-money-small img {
 height: 1.1em;
 width: 1.1em;
 object-fit: cover;
 border-radius: 100%;
 margin-right: 0.3em;
}

.quote-wallet-money {
 display: flex;
 margin-top: 5vh;

 width: 100%;
 justify-content: center;
 align-items: center;
}
.quote-wallet-money p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-frais-current {
 display: flex;
 position: absolute;
 flex-direction: column;

 width: 100%;
 justify-content: center;
 align-items: center;
 max-width: 95vw;

 top: 90%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-frais-current span {
 display: flex;

 justify-content: center;
 align-items: center;

 font-size: 1em;
 font-weight: 300;
 text-align: center;
 line-height: 1.2;
 letter-spacing: 0px;
}
.wrp-frais-current-sm {
 display: flex;
 position: absolute;
 flex-direction: column;

 width: 100%;
 justify-content: center;
 align-items: center;
 max-width: 95vw;

 top: 90%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-frais-current-sm span {
 display: flex;
 flex-direction: column;
 font-size: var(--para-small);
 text-align: center;
 padding: 0.4em;
 line-height: 1;
 letter-spacing: 0px;
 max-width: 70vw;
}

.quote-well-fiat {
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 1em;
 width: 100%;
}
.quote-well-fiat p {
 display: flex;
 font-size: 1.3em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-well-fiat {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 height: 80vh;
}

/* .wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
} */

.quote-well-fiat {
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 1em;
 width: 100%;
}
.quote-well-fiat p {
 display: flex;
 font-size: 1.3em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

/* .wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
} */

.wrp-well-fiat {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 height: 100vh;
}

/* Wallet css  */
.wrp-wallet {
 display: flex;
 flex-direction: column;
}

:root {
 --color-black-pret: #01161e;
}

.head-wallet {
 display: flex;
 flex-direction: column;
 min-height: 100%;
}

.quote-view-pret {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}
.quote-view-pret p {
 display: flex;
 font-size: 1.153em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0.5px;
 text-align: center;
 max-width: 30em;
 color: black;
}

/* Small */
.quote-view-pret-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding: 0.5em;
 width: 100%;
}
.quote-view-pret-sm p {
 display: flex;
 font-size: 1.198em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 text-align: center;
 max-width: 30em;
 color: black;
}

.wrp-pret-btn-next {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0 30%;
 margin-top: 30vh;
}
.wrp-pret-btn-next span {
 display: flex;
 font-size: 1em;
 padding: 0.2em;

 font-weight: 300;
 text-align: center;
 line-height: 1.2;
 letter-spacing: 0px;
}
.wrp-pret-btn-next-sm {
 display: flex;
 flex-direction: column;

 width: 100%;
 margin-top: 25vh;
 padding: 0.5em;
 justify-content: center;
 align-items: center;
}
.wrp-pret-btn-next-sm span {
 display: flex;
 flex-direction: column;
 font-size: var(--para-small);
 text-align: center;
 padding: 0.4em;
 cursor: pointer;
 line-height: 1;
 letter-spacing: 0px;
}

.view-name-pret {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 margin: 2vh 0;
 width: 100%;
 color: white;
}
.view-name-pret h2 {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 900;
 line-height: 1;
 letter-spacing: 0.1px;
 text-align: center;
 color: black;

 white-space: nowrap;
}

/* small */
.view-name-pret-sm {
 display: flex;
 flex-direction: column;

 position: absolute;
 top: 30%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.view-name-pret-sm h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 900;
 white-space: nowrap;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.1px;
 color: black;
}

:root {
 --bg-avatar-yellow: #d9d9d9;
}

.profil-pret-flex {
 display: flex;
 flex-direction: column;
 margin-top: 20vh;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.profil-pret {
 display: flex;
 flex-direction: column;
 width: 100%;
 grid-gap: 1em;
 gap: 1em;
 justify-content: center;
 align-items: center;
}
.profil-pret img {
 display: flex;
 height: 9em;
 width: 9em;
 border-radius: 100%;
 border: none;
 background: #d9d9d9;
 background: var(--bg-avatar-yellow);
 object-fit: cover;
 padding: 0.1em;
 border: 0.2em solid var(--bgcolor-btn);
}
.profil-pret-flex {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.flex-badge-ima img {
 height: 4em;
 width: 4em;
 object-fit: cover;
 border: none;
}

.wrp-back-pret-asked {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 75px;
 transform: translate(-50%, -50%);
}
.wrp-back-pret-asked-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 30px;
 transform: translate(-50%, -50%);
}

.flex-head-list-cmds {
 display: grid;
 place-items: center;
}
.flex-head-list-cmds header {
 position: fixed;
 top: 0;
 z-index: 9999;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-cmds header li {
 list-style: none;
}
.flex-head-list-cmds header .container {
 width: 100%;
}
.flex-head-list-cmds header .navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmds header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-cmds section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-cmds section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-cmds section li {
 margin-top: 3vh;
 width: 100%;
 border-bottom: 0.01em solid #cfd2cd;
}

.flex-head-list-cmds section input {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.flex-head-list-cmds .box-data-cmd-list {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmds .box-data-cmd-list h2 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 font-family: var(--text-family);
 color: #219ebc;
 line-height: 1;
 letter-spacing: 0px;
}

.flex-head-list-cmds .box-data-cmd-operator {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.flex-head-list-cmds .cmd-operator-title {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: flex-start;
 width: 100%;
}
.flex-head-list-cmds .cmd-operator-title h3 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 color: #264653;

 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.flex-head-list-cmds .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.flex-head-list-cmds .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #4361ee;
}

.flex-head-list-cmds .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 6em;
}

.flex-head-list-cmds .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
}

.wrp-withdraw {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100vh;
 position: relative;
}

.box-withdraw-money {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.box-withdraw-money-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.wrp-subject-withdraw {
 display: flex;

 position: absolute;
 top: 35%;
 left: 50%;

 width: 100%;
 max-width: 20em;
 transform: translate(-50%, -50%);
}
.wrp-subject-withdraw p {
 display: flex;
 font-weight: 400;
 font-size: 1.1em;
 font-family: var(--text-family);
 text-align: center;
 margin-top: 5vh;
 line-height: 1.1;
 letter-spacing: 0px;
}

/* small view */
.wrp-subject-withdraw-sm {
 display: flex;

 position: absolute;
 top: 40%;
 left: 50%;

 width: 100%;
 max-width: 80vw;
 transform: translate(-50%, -50%);
}
.wrp-subject-withdraw-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: center;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.wrp-link-withdraw {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 20em;

 position: absolute;
 top: 80%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-link-withdraw .margin-link-button {
 margin-top: 1.6vh;
}

.wrp-link-withdraw-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 96vw;

 position: absolute;
 top: 80%;
 left: 50%;

 transform: translate(-50%, -50%);
}

.wrp-link-withdraw-sm .margin-link-button {
 margin-top: 1.3vh;
}

.wrp-ima-franc {
 display: flex;
 margin: 0.3em;
}
.wrp-ima-franc img {
 display: flex;
 height: 1em;
 width: 1em;
 border-radius: 100%;
 object-fit: cover;
}

.wrp-line-widthdraw {
 display: flex;
 width: 100%;
 height: 1px;
 margin-top: 7vh;

 max-width: 20em;
 background: #e0e0e0;
}
.wrp-line-widthdraw-sm {
 display: flex;
 width: 100%;
 height: 1px;
 margin-top: 1vh;

 max-width: 5em;
 background: #e0e0e0;
}

.wrp-arrow-pret-profil {
 display: flex;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 8%;

 transform: translate(-50%, -50%);
 /* color: rgba(116, 116, 116, 0.995); */
}
.wrp-arrow-pret-profil-sm {
 display: flex;

 display: flex;
 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);

 /* color: rgba(116, 116, 116, 0.995); */
}

.wrp-signin-register {
 display: flex;
 flex-direction: column;
 padding: 1.5em;
}

.form-signin-pret-title {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: flex-start;
}
.form-signin-pret-title h1 {
 display: flex;
 font-size: 1.6em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

:root {
 --color-avatar: #000;
}

.wrp-avatar-pieces {
 display: flex;
}
.avatar-circle-pieces {
 display: flex;
 position: relative;
 color: #000;
 color: var(--color-avatar);
}

.wrp-pieces-avatar-user {
 display: flex;
}
.wrp-pieces-avatar-user .profile-user {
 display: flex;
 position: relative;
 background-color: transparent;
}
.wrp-pieces-avatar-user .profile-user img {
 display: flex;
 height: 2em;
 width: 2em;
 border-radius: 100%;
 border: none;
 background: var(--bg-avatar-yellow);
 object-fit: cover;
}
.wrp-pieces-avatar-user button {
 display: flex;

 justify-content: center;
 align-items: center;

 grid-gap: 0.5em;

 gap: 0.5em;
 border: none;
 outline: none;
 border-radius: 1em;
 padding: 0.4em 1.3em;
 color: #cca43b;
 background-color: #fff8e1;
}
.wrp-pieces-avatar-user button span {
 font-size: 0.9em;
 font-weight: 300;
 letter-spacing: 0px;
 font-family: "AntipastoPro";
}
.wrp-pieces-avatar-user .profile-user .icon-camera-pieces {
 position: absolute;
 z-index: 1;
}

/* small view */
.wrp-avatar-pieces-sm {
 display: flex;
 margin-bottom: 5vh;
}
.avatar-circle-pieces-sm {
 display: flex;
 position: relative;
 color: #000;
 color: var(--color-avatar);
}

/* View nav wallet */
.form-sign-nav-pret-method {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 2vh 3%;
 color: white;
 z-index: 10;
}

.view-nav-pret {
 display: flex;
}
.view-nav-pret img {
 height: 3em;
 width: 3em;
 cursor: pointer;
 object-fit: cover;
}

/* .wrp-signin-register {
 display: flex;
 flex-direction: column;
 padding: 1.5em;
} */

.wrp-input-pieces {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.wrp-input-pieces form {
 display: flex;
 flex-direction: column;
}
.wrp-input-pieces form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 1px;
 width: 100%;
 border: none;
 padding: 2vh 0;
 border-radius: 0.1em;

 background: transparent;
}
.wrp-input-pieces p {
 display: flex;
 flex-direction: column;
 font-size: 1em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 1px;
 width: 100%;
 border: none;
 color: #62656c;
}
.wrp-input-pieces .import-pieces {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-input-pieces .import-pieces .wrp-pieces-avatar-user {
 display: flex;
 width: 100%;
 justify-content: center;
 align-items: center;
}

/* View nav wallet */
.form-sign-nav-pret-method {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 2vh 3%;
 color: white;
 z-index: 10;
}

.view-nav-pret {
 display: flex;
}
.view-nav-pret img {
 height: 3em;
 width: 3em;
 cursor: pointer;
 object-fit: cover;
}

.wrp-send-pret {
 display: flex;
 flex-direction: column;
 position: relative;
 justify-content: center;
 align-items: center;
 overflow: hidden;
 width: 100%;
 height: 100vh;
}

.quote-send-pret {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.quote-send-pret p {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 text-align: center;
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
}

.ima-send-pret {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
}
.ima-send-pret img {
 height: 8em;
 width: 8em;
 object-fit: cover;
 animation-name: drive;
 animation-direction: 4s;
}

@keyframes drive {
 to {
  transform: translate(0px);
 }
 from {
  transform: translate(700px);
 }
}

.asked-send-costs-view {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: center;
 width: 100%;
 height: 100vh;
 min-height: 100%;
}

.wrp-ima-send {
 display: flex;
 margin: 3vh 0;
}
.wrp-ima-send img {
 width: 4em;
 height: 4em;
 object-fit: cover;
 border-radius: 50%;
}

.asked-quote {
 display: flex;
 padding: 1em;
}
.asked-quote p {
 display: flex;
 font-size: 1.174em;
 font-family: var(--text-family);
 font-weight: 300;
 text-align: center;
 line-height: 1.1;
 letter-spacing: 0px;
}

.asked-quote-sm {
 display: flex;
 width: 50%;
}
.asked-quote-sm p {
 display: flex;
 font-size: 1.174em;
 font-family: var(--text-family);
 font-weight: 300;
 text-align: center;
 line-height: 1.1;
 letter-spacing: 0px;
}

.solde-input-asked {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 20em;
 justify-content: center;
 align-items: center;
}
.solde-input-asked input {
 display: flex;
 outline: none;
 border: none;
 font-family: var(--text-family);
 padding: 0.2vh 0;
 justify-content: center;
 align-items: center;
 width: 100%;

 font-size: 3.5em;
 font-weight: 100;
 text-align: center;
 letter-spacing: 0px;
 border-radius: 0.3em;
}
.solde-input-asked h1 {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.solde-input-asked h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.solde-input-asked button {
 display: flex;
 margin-top: 7vh;
 margin-bottom: 5vh;
}

.prix-withdraw-pret-asked {
 display: flex;
 flex-direction: column;
 margin-top: 4vh;
 margin-bottom: 3vh;
}
.prix-withdraw-pret-asked p {
 display: flex;
 font-size: 4em;
 font-family: var(--text-family);
 font-weight: 500;
 white-space: nowrap;
 letter-spacing: -4px;
}

.prix-withdraw-pret-asked-sm {
 display: flex;
 flex-direction: column;
 margin-top: 4vh;
 margin-bottom: 3vh;
}
.prix-withdraw-pret-asked-sm p {
 display: flex;
 flex-direction: row;
 font-size: 2.6em;

 font-family: var(--text-family);
 font-weight: 100;
 letter-spacing: -2px;
 grid-gap: 0.0928em;
 gap: 0.0928em;
}

.dash-pret {
 display: flex;
 flex-direction: column;
}

.head-dash-pret {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100vh;
}

/* View nav wallet */
.wrp-nav-pret-slide {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0 3%;
 color: white;
 z-index: 10;
}

.view-nav-pret {
 display: flex;
}
.view-nav-pret img {
 height: 3em;
 width: 3em;
 cursor: pointer;
 object-fit: cover;
}

/* View Component  */
.view-pret-icon-cmd {
 display: flex;
 cursor: pointer;
}

.view-pret-icon-cmd img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}

.prix-body-pret-balance {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: center;

 width: 100%;
 height: 100vh;
}
.prix-body-pret-balance-sm {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: center;

 width: 100%;
 height: 100vh;
}

:root {
 --color-text-pret: black;
}

.wrp-main-prix-pret {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 color: black;
 color: var(--color-text-pret);
}
.wrp-main-prix-pret span {
 display: flex;
 font-size: 3.5em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;
}
.wrp-main-prix-pret .wrp-pret-row-percentage {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 10em;
}
.wrp-main-prix-pret p {
 display: flex;
 margin-top: 3vh;
 flex-direction: row;
 width: 100%;

 justify-content: start;
 align-items: center;
}
.wrp-main-prix-pret p i {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
 margin-right: 0.3em;
}
.margin-pret-right {
 margin-right: 2em;
}

.wrp-main-prix-pret .wrp-graph-head-pret {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-main-prix-pret-sm .wrp-graph-head-pret {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}

/* Small */
.wrp-main-prix-pret-sm {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;

 color: black;

 color: var(--color-text-pret);
}
.wrp-main-prix-pret-sm span {
 display: flex;
 font-size: 2em;
 font-family: var(--text-family);
 letter-spacing: var(-text-family-spacing);
 font-weight: 400;
 line-height: 1;
 white-space: nowrap;
 margin-top: 2vh;
}
.wrp-main-prix-pret-sm .wrp-pret-row-percentage {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 10em;
}
.wrp-main-prix-pret-sm p {
 display: flex;
 margin-top: 3vh;
 flex-direction: row;
 width: 100%;

 justify-content: start;
 align-items: center;
}
.wrp-main-prix-pret-sm p i {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
 margin-right: 0.3em;
}

.devise-pret-prix-dash {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 1px;
}

.btn-pret-asked {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
 padding: 0 3%;
 margin-top: 10vh;
 padding-bottom: 5vh;
}
.btn-pret-asked button {
 display: flex;
 justify-content: center;
 align-items: center;

 border-radius: 0.7em;
 font-size: 1em;
 letter-spacing: 1px;
 white-space: nowrap;
 width: 100%;

 grid-gap: 0.1em;

 gap: 0.1em;
 outline-style: none;
 font-family: var(--text-family);

 padding: 0.5em;
 border: 0.1px solid var(--bgcolor-btn);
 color: white;
 background: var(--bgcolor-btn);
}

.profil-prix-pret-dash div {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.profil-prix-pret-dash img {
 height: 30ch;
 width: 80%;
 object-fit: cover;
 padding: 0.2em;
}

.quote-pret-duration {
 display: flex;
}

.quote-pret-duration p {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;

 margin: 5vh 0;

 line-height: 1;
 letter-spacing: 0px;

 color: #db3a34;
}

.flex-date-prix-ask-dash-pret {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 padding: 6vh 5%;
 color: #ffc300;
}
.flex-date-prix-ask-dash-pret h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.flex-date-prix-ask-dash-pret p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-devise-pret {
 display: flex;
 flex-direction: column;
 height: 90vh;

 justify-content: center;
 align-items: center;
}

.devise-pret {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
}
.devise-pret img {
 height: 5em;
 width: 5em;
 object-fit: cover;
}

.title-devise-pret {
 display: flex;
 margin-top: 2vh;
}

.title-devise-pret h1 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.box-devise-pret-currency {
 display: flex;
 flex-direction: column;
 margin-top: 10ch;
}

.devise-pret-money {
 display: flex;
 cursor: pointer;

 justify-content: space-between;
 align-items: start;
 border-radius: 2em;
 width: 100%;

 margin: 1ch 0;
 border: 1px solid grey;
}
.devise-pret-money img {
 height: 2.6em;
 width: 2.6em;
 cursor: pointer;

 object-fit: cover;
 border-radius: 100%;
}
.devise-pret-money p {
 display: flex;
 font-size: 1.103em;
 margin-left: 1em;

 margin-top: 0.8ch;
 margin-left: 5em;
 margin-right: 1em;

 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

/* View nav wallet */
.wrp-nav-pret-method {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0 3%;
 /* background-color: var(--bgcolor-green); */
 color: white;
 z-index: 10;
}

.view-nav-pret {
 display: flex;
}
.view-nav-pret img {
 height: 3em;
 width: 3em;
 cursor: pointer;
 object-fit: cover;
}

.wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100vh;
}

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
} */

/* .wrp-form-input-nows {
 display: flex;
 justify-content: center;
 align-items: center;
}
.wrp-form-input-nows form {
 display: flex;
 flex-direction: column;
 max-width: 25em;
 padding: 1em;
 gap: 1em;
}
.wrp-form-input-nows input {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 font-family: "AntipastoPro";
 line-height: 1;
 letter-spacing: 1px;
 text-align: center;
 padding-left: 2em;
 width: 100%;
 border: none;
 color: black;
}
.wrp-form-input-nows form button {
 display: flex;
 padding: 0.3em;
}

.wrp-form-input-nows form .wrp-errors-code {
 display: flex;
 font-size: 0.3em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 margin-top: 2em;
 width: 100%;
 color: crimson;
}
.wrp-form-input-nows form .wrp-errors-code-transparent {
 display: flex;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 width: 100%;
 color: transparent;
} */

.pin-arrow-broker {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.pin-arrow-broker-sm {
 display: flex;
 cursor: pointer;
 position: absolute;
 top: 5%;
 left: 1.5em;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-ima-key-now {
 display: flex;
 position: absolute;

 top: 10%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100vh;
}

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
} */

.pin-arrow-broker {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.pin-arrow-broker-sm {
 display: flex;
 cursor: pointer;
 position: absolute;
 top: 5%;
 left: 1.5em;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-ima-key-now {
 display: flex;
 position: absolute;

 top: 10%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100vh;
}

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
} */

.pin-arrow-broker {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.pin-arrow-broker-sm {
 display: flex;
 cursor: pointer;
 position: absolute;
 top: 5%;
 left: 1.5em;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-ima-key-now {
 display: flex;
 position: absolute;

 top: 10%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100vh;
}

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
} */

/* .wrp-form-input-nows {
 display: flex;
 justify-content: center;
 align-items: center;
}
.wrp-form-input-nows form {
 display: flex;
 flex-direction: column;
 max-width: 25em;
 padding: 1em;
 gap: 1em;
}
.wrp-form-input-nows input {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 font-family: "AntipastoPro";
 line-height: 1;
 letter-spacing: 1px;
 text-align: center;
 padding-left: 2em;
 width: 100%;
 border: none;
 color: black;
}
.wrp-form-input-nows form button {
 display: flex;
 padding: 0.3em;
}

.wrp-form-input-nows form .wrp-errors-code {
 display: flex;
 font-size: 0.3em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 margin-top: 2em;
 width: 100%;
 color: crimson;
}
.wrp-form-input-nows form .wrp-errors-code-transparent {
 display: flex;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 width: 100%;
 color: transparent;
} */

.pin-arrow-broker {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.pin-arrow-broker-sm {
 display: flex;
 cursor: pointer;
 position: absolute;
 top: 5%;
 left: 1.5em;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-ima-key-now {
 display: flex;
 position: absolute;

 top: 10%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.wrp-pret-method {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 87vh;
}

.qt-pret-method {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-bottom: 20vh;
}
.qt-pret-method p {
 display: flex;
 font-size: 1.289em;
 font-weight: 300;
 line-height: 1;
 text-align: center;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.wrp-pret-ima-method {
 display: flex;
 margin-bottom: 4vh;
}

.wrp-pret-ima-method img {
 height: 5em;
 width: 5em;
 object-fit: cover;
}

.wrp-box-pret-method {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 max-width: 15em;
}

.all-pret-method {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.all-pret-method p {
 display: flex;
 font-size: 1.1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 1vh;
}
.all-pret-method img {
 height: 5em;
 width: 5em;
 object-fit: cover;
 padding: 0.1em;
 border-radius: 50%;
 background: #ced4da;
}

/* View nav wallet */
.wrp-nav-pret-method {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0 3%;
 height: 5ch;
 color: white;
 z-index: 10;
}

.view-nav-pret {
 display: flex;
}
.view-nav-pret img {
 height: 3em;
 width: 3em;
 cursor: pointer;
 object-fit: cover;
}

.flex-head-list-reimburse {
 display: flex;
}
.flex-head-list-reimburse header {
 position: fixed;
 top: 0;
 z-index: 9999;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-reimburse header li {
 list-style: none;
}
.flex-head-list-reimburse header .container {
 width: 100%;
}
.flex-head-list-reimburse header .navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-reimburse header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-reimburse section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-reimburse section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-reimburse section li {
 margin-top: 3vh;
 width: 100%;
 border-bottom: 0.01em solid #cfd2cd;
}

.flex-head-list-reimburse section .title-pret-reimburse {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 3ch;
}

.flex-head-list-reimburse section .title-pret-reimburse h2 {
 display: flex;
 font-size: 1.134em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 margin: 0 0.3em;
 letter-spacing: 0px;
}

.flex-head-list-reimburse section .title-pret-reimburse .color {
 padding: 0.1em 0.3em;
 border-radius: 1em;
 background: #a2d2ff;
}

.flex-head-list-reimburse section input {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.flex-head-list-reimburse .box-data-cmd-list-time {
 display: flex;
 justify-content: start;
 align-items: center;
 width: 100%;
 margin: 2vh 0;
}
.flex-head-list-reimburse .box-data-cmd-list-time h2 {
 display: flex;
 font-size: 0.855em;
 text-align: justify;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: #219ebc;
}

.flex-head-list-reimburse .box-data-cmd-list-time img {
 height: 1.8em;
 width: 1.8em;
 object-fit: cover;
 border-radius: 50%;
}

.flex-head-list-reimburse .box-data-cmd-operator {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.flex-head-list-reimburse .cmd-operator-title {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: flex-start;
 width: 100%;
}
.flex-head-list-reimburse .cmd-operator-title h3 {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 color: #264653;

 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.flex-head-list-reimburse .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.flex-head-list-reimburse .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 0.688em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #4361ee;
}

.flex-head-list-reimburse .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 7.3em;
}

.flex-head-list-reimburse .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
}

.nav-stock {
 display: flex;
}

.box-nav-stock {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}

.nav-stock {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 height: 100%;
}
.nav-stock img {
 height: 1.8em;
 width: 1.8em;
 object-fit: cover;
 object-position: 100%;
}
.nav-stock p {
 display: flex;
 font-size: 1.1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.title-nav-fiat {
 display: flex;
 margin: 2vh 0;
}
.title-nav-fiat h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-search-stock {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0 3%;
}
.wrp-search-stock input {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.user-box-stock {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;

 margin-top: 3vh;
}
.user-box-stock p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: -1;
 letter-spacing: 0px;
}
.flex-list-user-stock {
 display: flex;
 justify-content: center;
 align-items: center;
 grid-gap: 0.5em;
 gap: 0.5em;
}
.sub-list-user {
 display: flex;
 flex-direction: column;
 grid-gap: -2vh;
 gap: -2vh;
}

.wrp-nav-buy {
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
}

:root {
 --bg-avatar-yellow: transparent;
}

.profil-caise {
 display: flex;
}
.profil-caise img {
 display: flex;
 height: 3em;
 width: 3em;
 border-radius: 100%;
 border: none;
 background: transparent;
 background: var(--bg-avatar-yellow);
 object-fit: cover;
 border: 0.1em solid white;
}
.profil-caise-flex {
 display: flex;
 grid-gap: 0.5em;
 gap: 0.5em;
 justify-content: center;
 align-items: center;
}

.view-name-caise {
 display: flex;
 flex-direction: column;
}
.view-name-caise h2 {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 900;
 line-height: 1;
 letter-spacing: 0.1px;
 text-align: center;

 white-space: nowrap;
}
.view-name-caise span {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 900;
 line-height: 1;
 letter-spacing: 0.1px;
 white-space: nowrap;
}

/* small */
.view-name-caise-sm {
 display: flex;
 flex-direction: column;

 position: absolute;
 top: 30%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.view-name-caise-sm h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 900;
 white-space: nowrap;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.1px;
}

.wrp-network-stock {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.wrp-network-stock div {
 display: flex;
 width: 100%;
 cursor: pointer;
 border: 0.1em solid white;
 background: #b5c8d9;
 color: black;
}
.wrp-network-stock h2 {
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 width: 100%;
 text-align: center;

 padding: 1em;
 justify-content: end;
 align-items: end;
 white-space: nowrap;

 font-family: var(--text-family);
 letter-spacing: 0px;
}
.wrp-network-stock input {
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 width: 100%;
 text-align: center;

 border: none;
 cursor: pointer;
 background-color: #b5c8d9;
 border-left: 0.1em solid white;

 padding: 1em;
 justify-content: end;
 align-items: end;
 white-space: nowrap;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.wrp-input-stock {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0.3em;
}
.wrp-input-stock-box-prix {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 padding: 0.5em;
}
.wrp-input-stock-subbox-prix {
 display: flex;
 flex-direction: row;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 margin-top: 3vh;
}
.wrp-input-stock-subbox-prix h3 {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 list-style: none;
 color: #2ecc2e;
 letter-spacing: 0px;
}
.wrp-devise-switch {
 display: flex;
 justify-content: center;
 align-items: center;
}
.wrp-input-stock form button {
 margin-top: 3vh;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
 -webkit-appearance: none;
 margin: 0;
}

input[type="number"] {
 -moz-appearance: textfield;
}

.stock-wallet-prix {
 display: flex;
 margin-top: 2vh;
}
.stock-wallet-prix span {
 display: flex;
 font-size: 1.5em;
 font-family: var(--text-family);
 font-weight: 300;
 white-space: nowrap;
 line-height: 1;
 letter-spacing: 0px;
}
.margin-input-stock {
 display: flex;
 margin-left: 0.3em;
}

.title-stock-input {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 margin-top: -1vh;
}

.title-stock-input h1 {
 /* display: flex; */
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 width: 100%;
 text-align: center;

 padding: 1em;
 justify-content: end;
 align-items: end;
 white-space: nowrap;

 font-family: var(--text-family);
 letter-spacing: 0px;
 border-left: 0.1em solid white;
 background: #59a5d8;
 color: white;
}

.devise-ima-switch {
 display: flex;
}
.devise-ima-switch img {
 display: flex;
 height: 2.7em;
 width: 2.7em;
 border-radius: 100%;
 object-fit: cover;
}

.wrp-rate-stock {
 display: flex;

 justify-content: center;
 align-items: center;

 padding: 0.8em;

 width: 100%;
 background: #59a5d8;
 color: #fff;
}

.wrp-rate-stock span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 white-space: nowrap;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.progressbar-stock {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
 margin-top: 3vh;
}
.progressbar {
 position: relative;
 overflow: hidden;
 width: 18em;
 height: 20px;
 border-radius: 20px;
 background-color: #abb;
}
.progressPercent {
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 position: absolute;
 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
 color: #fff;
}

.wrp-devise-stock {
 display: flex;
 flex-direction: column;
 height: 100vh;
 width: 100%;
 align-items: center;
 justify-content: center;
}

.stock-select {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}

.stock-select form {
 margin-top: 13vh;
}

.stock-select button {
 margin-top: 2vh;
}

.wrp-now-profil-stock {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 80vh;
}

.wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

/* Small Screen */
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.12em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1.17;
 letter-spacing: 0.5px;
}

.wrp-ima-key-now {
 display: flex;
 position: absolute;

 top: 10%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.wrp-buy-stock-thank {
 display: flex;
 position: relative;

 width: 100%;
 height: 100vh;

 justify-content: center;
 align-items: center;

 color: var(--cl-color);
 background-color: var(--bg-color);
}

.wrp-qt-buy-stock {
 display: flex;
 width: 100%;
 max-width: 26em;
 margin-bottom: 2vh;
}
.wrp-qt-buy-stock p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1.2;
 text-align: start;
 letter-spacing: 0px;
}

/* Small */
.wrp-qt-buy-stock-sm {
 display: flex;
 width: 100%;
 margin-bottom: 2vh;
 max-width: 95vw;
}
.wrp-qt-buy-stock-sm p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1.2;
 text-align: start;
 letter-spacing: 0.5px;
}

.wrp-tontine {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 height: 100%;
}

.wrp-tontine-images {
 display: flex;
}
.wrp-tontine-images img {
 width: 30vw;
 height: 30vw;
 object-fit: cover;
}
.wrp-tontine-images-md {
 display: flex;
}
.wrp-tontine-images-md img {
 height: 45vw;
 width: 45vw;
 object-fit: cover;
}
.wrp-tontine-images-sm {
 display: flex;
}
.wrp-tontine-images-sm img {
 height: 70vw;
 width: 70vw;
 object-fit: cover;
 border-radius: 100%;
}

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

/* .tontine-nav-invited {
 display: flex;
}

.tontine-nav-invited img {
 height: 2.7em;
 width: 2.7em;
 object-fit: cover;
} */

.welcome-tontine-quote {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding: 1em;
 width: 100%;
}
.welcome-tontine-quote p {
 display: flex;
 font-size: 1.282em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 text-align: center;
 letter-spacing: 0px;
}

:root {
 --bg-green-btn-tontine: #09ad01;
}

.tontine-btn-next {
 display: flex;
 margin: 20vh 0;
}
.tontine-btn-next button {
 display: flex;
 justify-content: center;
 align-items: center;

 border-radius: 0.9em;
 font-size: 1em;
 letter-spacing: 1px;
 cursor: pointer;
 white-space: nowrap;

 outline-style: none;
 font-family: var(--text-family);

 padding: 1ch 1.3em;
 width: 100%;

 color: var(--color-btn);
 border: 0.1px solid #09ad01;
 border: 0.1px solid var(--bg-green-btn-tontine);
 background: #09ad01;
 background: var(--bg-green-btn-tontine);
}

/* Small */
.tontine-btn-next-sm {
 display: flex;
 margin: 15vh 0;
}
.tontine-btn-next-sm button {
 display: flex;
 justify-content: center;
 align-items: center;

 border-radius: 0.9em;
 font-size: 1em;
 letter-spacing: 1px;
 cursor: pointer;
 white-space: nowrap;

 outline-style: none;
 font-family: var(--text-family);

 padding: 1ch 1.3em;
 width: 100%;

 color: var(--color-btn);
 border: 0.1px solid #09ad01;
 border: 0.1px solid var(--bg-green-btn-tontine);
 background: #09ad01;
 background: var(--bg-green-btn-tontine);
}

.create-link-tontine {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.create-link-tontine p {
 display: flex;
 font-size: 1.145em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 cursor: pointer;
 letter-spacing: 0px;
 color: #00a8e8;
}

.tontine-footer-qt {
 display: flex;
 padding: 1em;
}
.tontine-footer-qt span {
 display: flex;
 font-size: 1em;
 text-align: center;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.flex-head-list-tontine {
 display: flex;
}
.flex-head-list-tontine header {
 position: fixed;
 top: 0;
 z-index: 9999;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-tontine header li {
 list-style: none;
}
.flex-head-list-tontine header .container {
 width: 100%;
}
.flex-head-list-tontine header .navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-tontine header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-tontine section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-tontine section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-tontine section li {
 display: flex;
 flex-direction: row;

 margin-top: 3vh;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;
}

.flex-head-list-tontine p {
 display: flex;
 font-size: 1.333em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #6c757d;
}

.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 1.333em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #6c757d;
}
.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd-group {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 7em;
}
.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
}

.wrp-withdraw-tontine {
 display: grid;
 place-items: center;
 height: 90vh;
 grid-template-rows: auto 1fr auto;
}

:root {
 --blue-asked-tontine: #0077b6;
 --grey-asked-tontine: #343a40;
}

.wrp-prix-quote-withdraw-get-tontine {
 display: grid;
 place-items: center;
 color: #0077b6;
 color: var(--blue-asked-tontine);
}
.wrp-prix-quote-withdraw-get-tontine p {
 display: flex;
 font-size: 3.133em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: -3px;
 font-family: var(--text-family);
}

/* Small prix widthdraw */
.wrp-prix-quote-withdraw-get-tontine-sm {
 display: grid;
 place-items: center;
 color: #0077b6;
 color: var(--blue-asked-tontine);
}
.wrp-prix-quote-withdraw-get-tontine-sm p {
 display: flex;
 font-size: 2.133em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: -2px;
 font-family: var(--text-family);
}

.wrp-tontine-ima-method {
 display: grid;
 place-items: center;
 color: black;
}

.wrp-tontine-ima-method img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}

.wrp-tontine-ima-method p {
 margin-top: 3vh;
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 margin-bottom: 5vh;
 color: gray;
}

.wrp-btn-asked-tontine-withdraw {
 display: flex;
}

/* .wrp-withdraw-tontine {
 display: grid;
 place-items: center;
 height: 90vh;
 grid-template-rows: auto 1fr auto;
} */

/* :root {
 --blue-asked-tontine: #0077b6;
 --grey-asked-tontine: #343a40;
}

.wrp-prix-quote-withdraw-get-tontine {
 display: flex;
 margin-top: 5vh;
 color: var(--blue-asked-tontine);
}
.wrp-prix-quote-withdraw-get-tontine p {
 display: flex;
 font-size: 3.133em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: -3px;
 font-family: var(--text-family);
}

Small prix widthdraw
.wrp-prix-quote-withdraw-get-tontine-sm {
 display: grid;
 place-items: center;
 color: var(--blue-asked-tontine);
}
.wrp-prix-quote-withdraw-get-tontine-sm p {
 display: flex;
 font-size: 2.133em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: -2px;
 font-family: var(--text-family);
} */

/* .wrp-tontine-ima-method {
 display: grid;
 place-items: center;
 color: black;
}

.wrp-tontine-ima-method img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}

.wrp-tontine-ima-method p {
 margin-top: 3vh;
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 margin-bottom: 5vh;
 color: gray;
} */

/* .wrp-btn-asked-tontine-withdraw {
 display: flex;
} */

.form-tontine {
 display: flex;
 flex-direction: column;
 width: 100%;
}

/* .wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
} */

.tontine-send-invited {
 display: flex;
 cursor: pointer;
}
.tontine-send-invited img {
 height: 2.1em;
 width: 2.1em;
 object-fit: cover;
}

.anima-txted {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 1ch;
 color: #ffb703;
}

.wrp-body-form-tontine {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 60vh;
}
.wrp-body-form-tontine-sm {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;

 height: 60vh;
}

.grid-template-row {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100%;
 min-height: 100vh;
}

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.display-grid {
 display: grid;
 place-items: center;
 height: 100%;
 width: 100%;
}

.totine-model-ima {
 display: flex;
}
.totine-model-ima img {
 height: 100%;
 width: 50vw;
 object-fit: cover;
}

.totine-model-ima-sm {
 display: flex;
}
.totine-model-ima-sm img {
 height: 20vh;
 width: 60vw;
 object-fit: cover;
}

.flex-tontine-model {
 display: grid;
 place-items: center;
}
.flex-tontine-model h1 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 margin-bottom: 4vh;
 font-family: var(--text-family);

 color: #7c7a7a;
}

.button-width-25 {
 display: flex;
 width: 100%;
 max-width: 25em;
}
.button-width-25-sm {
 display: flex;
 width: 100%;
 max-width: 90vw;
}

.form-tontine {
 display: flex;
 flex-direction: column;
 width: 100%;
}

/* .wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
} */

.title-bd-tontine {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 80%;
 margin-top: 5ch;
 margin-bottom: 20vh;
}
.title-bd-tontine h1 {
 display: flex;
 font-size: 1.2399em;
 text-align: center;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: #7f7f7f;
}
.title-bd-tontine img {
 height: 5em;
 width: 5em;
 object-fit: cover;
}

.wrp-body-form-tontine {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 60vh;
}

/* .prix-withdraw-pret-asked {
 display: flex;
 flex-direction: column;
 margin-top: 4vh;
 margin-bottom: 3vh;
}
.prix-withdraw-pret-asked p {
 display: flex;
 font-size: 4em;
 font-family: var(--text-family);
 font-weight: 500;
 white-space: nowrap;
 letter-spacing: 0px;
}

.prix-withdraw-pret-asked-sm {
 display: flex;
 flex-direction: column;
 margin-top: 4vh;
 margin-bottom: 3vh;
}
.prix-withdraw-pret-asked-sm p {
 display: flex;
 flex-direction: row;
 font-size: 2.6em;

 font-family: var(--text-family);
 font-weight: 100;
 letter-spacing: -2px;
 gap: 0.0928em;
} */

.qt-info-tontine {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;
}
.title-qt-info-tontine {
 display: flex;
 flex-direction: row;
 width: 100%;
 max-width: 20em;
 justify-content: space-between;
 align-items: center;
}
.title-qt-info-tontine p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 color: #495057;
}

.btn-info-tontine-go-pin {
 display: flex;
 width: 65%;
 padding: 1em;
 margin-top: 15vh;
}

.btn-info-tontine-go-pin-sm {
 display: flex;
 width: 93%;
 margin-top: 15vh;
}

.wrp-body-info-tontine {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 90vh;
}

.wrp-well-tontine {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 min-height: 100vh;
 background: #dadada;
}

.wrp-well-body-tontine {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;

 justify-content: center;
 align-items: center;

 min-height: 90vh;
}

.well-icon-tontine {
 display: flex;
}
.well-icon-tontine img {
 height: 5em;
 width: 5em;
 object-fit: cover;
}

.qt-well-body-tontine {
 display: flex;
 margin-top: 2ch;
}

.qt-well-body-tontine p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-tontine-group {
 display: flex;
 flex-direction: column;
}

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

/* .tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
} */

.wrp-bg-group-tontine {
 display: flex;
 flex-direction: column;
 padding: 0 3%;
 width: 100%;
}

.list-group-item-bd-tontine {
 display: flex;
 font-size: 1.199em;
 font-weight: 300;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: #8b939a;
}

.list-group-item-bd-title {
 display: flex;
 font-size: 1.299em;
 font-weight: 300;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: #00a6fb;

 padding-left: 3%;

 margin-top: 5vh;
 margin-bottom: 3vh;
}

.flex-head-list-tontine {
 display: flex;
}
.flex-head-list-tontine header {
 position: fixed;
 top: 0;
 z-index: 9999;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-tontine header li {
 list-style: none;
}
.flex-head-list-tontine header .container {
 width: 100%;
}
.flex-head-list-tontine header .navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-tontine header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-tontine section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-tontine section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-tontine section li {
 display: flex;
 flex-direction: row;

 margin-top: 3vh;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;
}

.flex-head-list-tontine p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #6c757d;
}

.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;

 color: #6c757d;
}

.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title span {
 margin-left: 0.4em;
}

.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd-group {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 /* width: 7em; */
}
.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
}

.flex-head-list-tontine-child-all {
 display: flex;
}
.flex-head-list-tontine-child-all header {
 position: fixed;
 top: 0;
 z-index: 9999;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-tontine-child-all header li {
 list-style: none;
}
.flex-head-list-tontine-child-all header .container {
 width: 100%;
}
.flex-head-list-tontine-child-all header .navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-tontine-child-all header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-tontine-child-all section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 0.3em;
}
.flex-head-list-tontine-child-all section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-tontine-child-all section li {
 display: flex;
 flex-direction: row;
 margin-top: 1vh;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;
}

.flex-head-list-tontine-child-all .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.flex-head-list-tontine-child-all .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 1.333em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #6c757d;
}
.flex-head-list-tontine-child-all .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 7em;
}
.flex-head-list-tontine-child-all .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
}

.profil-tontine-navs {
 display: flex;
 justify-content: start;
 align-items: stretch;
 width: 100%;
}
.profil-tontine-navs img {
 height: 2.8em;
 width: 2.8em;
 border-radius: 100%;
 object-fit: cover;
}
.profil-tontine-navs h4 {
 display: flex;
 margin-left: 0.7em;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-tontine-budget {
 display: flex;
 flex-direction: column;
 width: 100%;

 justify-content: center;
 align-items: center;
}
.wrp-tontine-budget img {
 height: 2.7em;
 width: 2.7em;
 object-fit: cover;
 margin-top: 2ch 0;
}
.wrp-tontine-budget p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 margin-top: 1ch;
 letter-spacing: 0px;
}
.wrp-tontine-budget h2 {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 margin-top: 2ch;
 letter-spacing: 0px;
 color: #6c757d;
}

.profil-tontine-navs-prix {
 display: flex;
}
.profil-tontine-navs-prix h4 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: grey;
}

.wrp-tontine-group {
 display: flex;
 flex-direction: column;
}

/* .wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
} */

/* .tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
} */

.tontine-invite-user {
 display: flex;
}
.tontine-invite-user img {
 height: 1.899em;
 width: 1.899em;
 object-fit: cover;
}

.child-title-user {
 display: flex;
 margin-top: 20ch;
 margin-bottom: 5ch;
}
.child-title-user h1 {
 display: flex;
 font-size: 1.1799em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;

 color: #0077b6;

 letter-spacing: 0px;
}

.wrp-push-invited-icon-tontine {
 display: flex;
 flex-direction: row;

 justify-content: center;
 align-items: center;

 cursor: pointer;
 border-radius: 10em;
 margin-top: 1ch;
 margin-bottom: 4ch;
 border: 1px solid grey;
}
.wrp-push-invited-icon-tontine p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);

 margin-right: 3em;
 margin-left: 0.6em;

 padding-top: 1.8vh;
 padding-bottom: -2vh;
 color: #343a40;

 line-height: 0;
 letter-spacing: 0px;
}
.list-box-flex-input-tontine {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.list-box-flex-input-tontine h1 {
 display: flex;
 font-size: 1.188em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.form-child-bottom-input form input {
 margin-bottom: 4vh;
}

.form-child-bottom-input form button {
 padding: 0;
 margin-top: 4vh;
 border: none;
}

.profile-user-child-tontine .wrp-box-flex-child-tontine {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.profile-user-child-tontine img {
 height: 6.5em;
 width: 6.5em;
 object-fit: cover;

 padding: 0.2em;

 border-radius: 100%;
}
.profile-user-child-tontine h3 {
 display: flex;
 font-size: 1.092em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-tontine-group {
 display: flex;
 flex-direction: column;
}

/* .wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
} */

/* .tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
} */

.bd-overview-list-child {
 display: flex;
 flex-direction: column;

 justify-content: space-between;
 align-items: baseline;

 height: 80vh;
 padding: 0 5%;

 /* min-height: 90vh; */
}

.title-bd-overview-tontine {
 display: flex;
 flex-direction: column;
 margin: 5ch 0;
}

.title-bd-overview-tontine h1 {
 display: flex;
 font-size: 1.299em;
 font-weight: 300;
 color: #1b98e0;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-box-list-overview-bd-tontine {
 display: flex;
 flex-direction: column;
 margin-top: 5ch;
}
.wrp-list-overview-bd-tontine {
 display: flex;
 flex-direction: row;

 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.wrp-list-overview-bd-tontine h4 {
 display: flex;
 font-size: 1.2405em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: #979dac;
}

.wrp-btn-over-list {
 display: flex;
 width: 100%;
}

.wrp-body-form-tontine {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;
}
.wrp-body-form-tontine-sm {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;
}

.icon-budget-tontine-bd {
 display: flex;
}

.icon-budget-tontine-bd img {
 height: 4em;
 width: 4em;
 object-fit: cover;
}

.wrp-quote-budget-tontine {
 display: flex;
 margin: 5ch 0;
}
.wrp-quote-budget-tontine p {
 display: flex;
 font-size: 1.113em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 color: #01baef;
}

:root {
 --bg-green-btn-tontine: #09ad01;
}

.tontine-btn-next-budget {
 display: flex;
}
.tontine-btn-next-budget button {
 display: flex;
 justify-content: center;
 align-items: center;

 border-radius: 0.9em;
 font-size: 1em;
 letter-spacing: 0.5px;
 cursor: pointer;
 white-space: nowrap;

 outline-style: none;
 font-family: var(--text-family);

 padding: 0.5ch 3em;
 width: 100%;

 color: var(--color-btn);
 border: 0.1px solid #09ad01;
 border: 0.1px solid var(--bg-green-btn-tontine);
 background: #09ad01;
 background: var(--bg-green-btn-tontine);
}
.tontine-btn-next-budget button img {
 height: 2em;
 width: 2em;
 object-fit: cover;
 margin-left: 1em;
}

/* Small */
.tontine-btn-next-budget-sm {
 display: flex;
}
.tontine-btn-next-budget-sm button {
 display: flex;

 justify-content: center;
 align-items: center;
 border-radius: 0.9em;
 font-size: 1em;
 letter-spacing: 1px;

 cursor: pointer;
 white-space: nowrap;

 outline-style: none;
 font-family: var(--text-family);

 padding: 0.5ch 3em;
 width: 100%;

 color: var(--color-btn);
 border: 0.1px solid #09ad01;
 border: 0.1px solid var(--bg-green-btn-tontine);
 background: #09ad01;
 background: var(--bg-green-btn-tontine);
}
.tontine-btn-next-budget-sm button img {
 height: 2em;
 width: 2em;
 object-fit: cover;
 margin-left: 1em;
}


.wrp-back-cmd-fiat {
 display: flex;
 flex-direction: column;
}
.wrp-back-cmd-fiat span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-commi-box-unite-stock {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 90vw;
}
.wrp-commi-box-unite-stock p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 text-align: start;
 white-space: nowrap;

 padding-top: 1vh;
 padding-bottom: 1vh;
 margin: 0.3vh 0;
}

.flex-commi-stock {
 display: flex;
 place-items: center;
 border-radius: 0.8em;
 width: 100%;
 max-width: 100vw;
 margin: 1vh 0;
 padding-right: 0.4em;
 background-color: #2993;
}

.flex-head-list-cmd {
 display: flex;
}
.flex-head-list-cmd header {
 position: fixed;
 z-index: 9999;
 top: 0;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-cmd header li {
 list-style: none;
}
.flex-head-list-cmd header .container {
 width: 100%;
}
.flex-head-list-cmd header .navbar {
 display: flex;

 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmd header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-cmd section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-cmd section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-cmd section li {
 margin-top: 3vh;
 width: 100%;
 border-bottom: 0.01em solid #cfd2cd;
}

.flex-head-list-cmd section form {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmd-button section form button {
 margin-top: 5vh;
 border: none;
 outline: none;
}
.flex-head-list-cmd section form input {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 margin: 1.5vh 0;
}

.mt-title-update-fiat {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 padding: 5vh 0;
}

.flex-head-list-cmd .box-data-cmd-list {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmd .box-data-cmd-list h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: var(--nav-cmd-bg);
}

.flex-head-list-cmd .box-data-cmd-operator {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 width: 100%;
}
.flex-head-list-cmd .cmd-operator-title {
 display: flex;
 flex-direction: row;

 justify-content: space-between;
 place-items: start;

 width: 100%;
}

.cmd-operator-title {
 display: flex;
 place-items: start;
}
.cmd-operator-title h3 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: start;
 color: #adb5bd;

 line-height: 2;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
.cmd-operator-title p {
 display: flex;
 font-size: .9em;
 
 font-weight: 300;
 text-align: start;

 justify-content: start;
 align-items: flex-start;
 color: #212529;

 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.flex-head-list-last {
 display: flex;
}
.flex-head-list-last header {
 position: fixed;
 top: 0;
 z-index: 9999;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-last header li {
 list-style: none;
}
.flex-head-list-last header .container {
 width: 100%;
 /* margin: 0; */
}
.flex-head-list-last header .navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-last header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-last section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-last section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-last section li {
 margin-top: 3vh;
 width: 100%;
 border-bottom: 0.01em solid #cfd2cd;
}

.flex-head-list-last section .title-pret-last {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 3ch;
}

.flex-head-list-last section .title-pret-last h2 {
 display: flex;
 font-size: 1.134em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 margin: 0 0.3em;
 letter-spacing: 0px;
}

.flex-head-list-last section .title-pret-last .color {
 padding: 0.1em 0.3em;
 border-radius: 1em;
 background: #a2d2ff;
}

.flex-head-list-last section input {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.flex-head-list-last .box-data-cmd-list-time {
 display: flex;
 justify-content: start;
 align-items: center;
 width: 100%;
 margin: 2vh 0;
}
.flex-head-list-last .box-data-cmd-list-time h2 {
 display: flex;
 font-size: 1em;
 text-align: justify;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 0;
 letter-spacing: 0px;
 color: #5465ff;
}

.flex-head-list-last .box-data-cmd-list-time img {
 height: 1.8em;
 width: 1.8em;
 object-fit: cover;
 border-radius: 50%;
}

.flex-head-list-last .box-data-cmd-operator {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.flex-head-list-last .cmd-operator-title {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: flex-start;
 width: 100%;
}
.flex-head-list-last .cmd-operator-title h3 {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin: 1.4vh 0;

 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

.flex-head-list-last .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: start;
 width: 100%;
}
.flex-head-list-last .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0.5;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #006ba6;
}

.flex-head-list-last .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 10em;
}

.flex-head-list-last .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
}

.array-static-navbar {
 margin-bottom: 2.5ch;
 cursor: pointer;
}

/* Ticket Component ticket  */
.wrp-ticket-print {
 display: flex;
 height: 90vh;
 width: 100%;

 justify-content: center;
 align-items: center;
}

.box-ticket-printer {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
}

.wrp-quote-printer-ticket {
 display: flex;
}

.wrp-quote-printer-ticket p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

/* .wrp-contry-print-overview {
 display: flex;
 flex-direction: column;
 width: 100%;

 margin: 2vh 0;

 justify-content: center;
 align-items: center;
}
.wrp-contry-print-overview h1 {
 display: flex;
 font-size: 2em;
 font-weight: 400;
 font-family: var(--font-quote-name);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview span {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
} */

.container-firebaseui {
 display: flex;
 flex-direction: column;
 height: 80vh;
 padding: 1em;

 width: 100%;

 justify-content: center;
 align-items: center;
}
.container-firebaseui h4 {
 display: flex;
 font-size: 1.23em;
 font-weight: 300;
 max-width: 17em;
 text-align: center;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;

 color: #274c77;
}
.container-firebaseui p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 max-width: 25em;
 text-align: center;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;

 margin-top: 2vh;

 color: #8b8c89;
}

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100vh;
}

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
} */

/* .wrp-form-input-nows {
 display: flex;
 justify-content: center;
 align-items: center;
}
.wrp-form-input-nows form {
 display: flex;
 flex-direction: column;
 max-width: 25em;
 padding: 1em;
 gap: 1em;
}
.wrp-form-input-nows input {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 font-family: "AntipastoPro";
 line-height: 1;
 letter-spacing: 1px;
 text-align: center;
 padding-left: 2em;
 width: 100%;
 border: none;
 color: black;
}
.wrp-form-input-nows form button {
 display: flex;
 padding: 0.3em;
}

.wrp-form-input-nows form .wrp-errors-code {
 display: flex;
 font-size: 0.3em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 margin-top: 2em;
 width: 100%;
 color: crimson;
}
.wrp-form-input-nows form .wrp-errors-code-transparent {
 display: flex;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 width: 100%;
 color: transparent;
} */

.pin-arrow-broker {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.pin-arrow-broker-sm {
 display: flex;
 cursor: pointer;
 position: absolute;
 top: 5%;
 left: 1.5em;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-ima-key-now {
 display: flex;
 position: absolute;

 top: 10%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.wrp-now-profil {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 100vh;
}

/* .wrp-quote-now {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 20em;
}
.wrp-quote-now p {
 display: flex;
 text-align: center;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
}

Small Screen
.wrp-quote-now-sm {
 display: flex;
 padding: 1em;
}
.wrp-quote-now-sm p {
 display: flex;
 font-size: 1.02em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
} */

/* .wrp-form-input-nows {
 display: flex;
 justify-content: center;
 align-items: center;
}
.wrp-form-input-nows form {
 display: flex;
 flex-direction: column;
 max-width: 25em;
 padding: 1em;
 gap: 1em;
}
.wrp-form-input-nows input {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 font-family: "AntipastoPro";
 line-height: 1;
 letter-spacing: 1px;
 text-align: center;
 padding-left: 2em;
 width: 100%;
 border: none;
 color: black;
}
.wrp-form-input-nows form button {
 display: flex;
 padding: 0.3em;
}

.wrp-form-input-nows form .wrp-errors-code {
 display: flex;
 font-size: 0.3em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 margin-top: 2em;
 width: 100%;
 color: crimson;
}
.wrp-form-input-nows form .wrp-errors-code-transparent {
 display: flex;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 width: 100%;
 color: transparent;
} */

.pin-arrow-broker {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.pin-arrow-broker-sm {
 display: flex;
 cursor: pointer;
 position: absolute;
 top: 5%;
 left: 1.5em;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}

.wrp-ima-key-now {
 display: grid;
 place-items: center;
 position: absolute;

 top: 20%;
 left: 50%;
 transform: translate(-50%, -50%);
}

/* .wrp-contry-print-overview {
 display: flex;
 flex-direction: column;
 width: 100%;

 margin: 2vh 0;

 justify-content: center;
 align-items: center;
}
.wrp-contry-print-overview h1 {
 display: flex;
 font-size: 2em;
 font-weight: 500;
 font-family: var(--font-quote-name);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
} */

/* .wrp-contry-print-overview {
 display: flex;
 flex-direction: column;
 width: 100%;

 margin: 2vh 0;

 justify-content: center;
 align-items: center;
}
.wrp-contry-print-overview h1 {
 display: flex;
 font-size: 2em;
 font-weight: 500;
 font-family: var(--font-quote-name);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: 0px;
} */

.flex-hm-creditaire {
 display: grid;
 min-height: 100%;
 height: 100vh;
}

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.red-creditaire {
 width: 100%;
 border-radius: 0.3em;
 padding: 0.4em;
 margin: 2vh 0;
 color: #fff;
 background-color: red;
}
.center-creditaire {
 display: flex;
 width: 100%;
 justify-content: center;
 place-items: center;
}

.wrp-operator-credit {
 display: grid;
 place-items: center;
}

.padding-2em {
 padding: 2em;
}
.wrp-operator-creditaire {
 display: flex;
 width: 100%;
}
.wrp-operator-creditaire p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--text-family);
 color: #7f7f7f;
}

.flex-grid {
 display: grid;
 place-items: center;
}

.wrp-home-credit {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100%;
 min-height: 100vh;
 padding: 2%;
}

.wrp-credit-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 justify-content: space-between;
 align-items: center;
}

.credit-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.credit-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.flex-body-credit-center {
 display: grid;
 grid-template-rows: auto 1fr auto;
}

.wrp-title-solde-credit {
 display: grid;
 place-content: start;
 width: 100%;
 padding: 0.2em;
 margin-top: 2ch;
 background-color: gray;
}

.wrp-title-solde-credit h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 margin-top: 0.5vh;
 letter-spacing: 0.5px;
 font-family: var(--text-family);
}

.flex-body-center-credit {
 display: grid;
 place-items: center;
}

.flex-bord-crediter {
 display: grid;
 place-items: center;
 width: 100%;
 max-width: 20em;
 border-radius: 0.3em;
 border: 1px solid #a5a5a5;
}

.flex-credit-name {
 display: grid;
 place-items: center;
 width: 100%;
 margin-top: 2ch;
 padding: 1ch 0;
 max-width: 15em;
 border-radius: 0.4em;
 background-color: green;
}
.flex-credit-name p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 margin-top: 1ch;
 color: #fff;
 font-family: var(--text-family);
}

.flex-balance-user-creditaire {
 display: flex;
 margin-top: 2vh;
}
.flex-balance-user-creditaire p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.flex-balance-must-creditaire {
 display: grid;
 place-items: center;
}
.flex-balance-must-creditaire div h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 margin-top: 1ch;
 padding: 1ch 0;
 color: grey;
}
.flex-balance-must-creditaire p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.flex-footer-credit {
 margin-top: 10ch;
}

.flex-footer-button-credit {
 display: grid;
 place-items: center;
 width: 100%;
 /* padding: 0 30em; */
}

.flex-footer-button-credit button {
 display: flex;
 justify-content: center;
 align-items: center;

 border-radius: 0.3em;
 font-size: 1em;
 letter-spacing: 1px;
 cursor: pointer;
 white-space: nowrap;

 outline-style: none;
 font-family: var(--text-family);

 padding: 0.355em;
 width: 100%;

 color: var(--color-btn);
 border: 0.1px solid #e36414;
 background: #e36414;
}

.wrp-operator-creditor {
 display: grid;
 height: 100%;
 min-height: 100vh;
 grid-template-rows: auto 1fr auto;
}

.wrp-tontine-navbar {
 display: flex;
 width: 100%;
 height: 6ch;
 padding: 0 3%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.flex-network-operator {
 display: flex;
 padding: 0 30%;
}
.flex-network-operator-sm {
 display: flex;
 padding: 0 5%;
}

.martp-1ch {
 margin-top: 2ch;
}

.pop-up-last {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: #a5a5a5;
}

.flex-center {
 display: grid;
 place-items: center;
 width: 100%;
}

.flex-box-network {
 display: grid;
 place-items: center;
 padding: 2vh 7%;
 width: 100%;
 border-radius: 0.4em;
 color: #fff;
 background-color: #a2d2ff;
}
.flex-box-network p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 border-radius: 0.3em;
}

.flex-title-network {
 display: grid;
 place-items: center;
 width: 100%;
 padding-top: 2ch;
}
.flex-title-network h3 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 border-radius: 0.3em;
}

.flex-martop-3vh {
 margin-top: 1vh;
}

.flex-footer-credit {
 margin-top: 10ch;
}

.wrp-quote-footer-operator {
 display: flex;
 width: 100%;

 justify-content: center;
 align-items: center;
}
.wrp-quote-footer-operator p {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 max-width: 30em;
 line-height: 1.2;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--text-family);
}

/* Small */
.wrp-quote-footer-operator-sm {
 display: flex;
 width: 100%;

 justify-content: center;
 align-items: center;
}
.wrp-quote-footer-operator-sm p {
 display: flex;
 font-size: 0.5em;
 font-weight: 100;
 max-width: 90vw;
 line-height: 1.2;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--text-family);
}

.wrp-sharing {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 110vh;
 padding: 0.5em;
}

.wrp-sharing-navbar {
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
}

.tontine-back {
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 cursor: pointer;
}
.tontine-back span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 margin-left: -0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}

.grid-template-rows-auto {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100vh;
}

.padding-1 {
 padding: 1em;
}

.margin-top-5 {
 margin-top: 5vh;
}

.flex-sharing {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.rounded-black {
 border: 1px solid #335c67;

 padding: 1px;
 border-radius: 100%;
}

.wrp-title-sharing {
 display: flex;
 color: #006d77;
}
.wrp-title-sharing h1 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.flex {
 display: flex;
 place-items: center;
 grid-gap: 1em;
 gap: 1em;
}

.flex-thrift {
 display: grid;
}

.flex-thrift p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-column-hm-dash-list-user {
 display: flex;
 flex-direction: column;
 justify-content: start;
 margin-top: 5vh;
}
.wrp-column-hm-dash-list-user form {
 display: flex;
 flex-direction: row;
 padding-left: center;
 justify-content: space-between;
 width: 100%;
 padding: 1.2vh 1em;
 border-radius: 0.5em;
 background-color: #e6e6ea;
}
.wrp-column-hm-dash-list-user form input {
 display: flex;
 border: none;
 background: transparent;

 width: 100%;

 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-hm-dash-list-user {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 cursor: pointer;
 margin-top: 5vh;
 max-width: 100vw;
 padding-bottom: 1vh;
 border-bottom: 1px solid #eeeeee;
}
.flex-column-list-user-all {
 display: flex;
 justify-content: start;
 place-items: center;
}

.wrp-hm-dash-list-user h3 {
 display: flex;
 font-size: 1em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
.wrp-hm-dash-list-user span {
 display: flex;
 place-items: center;
 grid-gap: 1em;
 gap: 1em;
 justify-content: start;
}
.wrp-hm-dash-list-user p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: start;
 white-space: nowrap;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--text-family);
 width: 7em;
 margin-top: 2vh;
 color: #5b5b5b;
}

.flex-money-user-list {
 display: flex;
 flex-direction: column;
}

.flex-money-user-list span {
 display: flex;
 font-size: 0.7em;
 width: 10em;

 font-weight: 400;
 text-align: center;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: #5b5b5b;
}

/*  */
.flex-center {
 display: flex;
 place-items: center;
}
.pad-top-2 {
 padding-top: 2vh;
}

.flex-usd-devise-sharing {
 display: flex;
}
.flex-usd-devise-sharing p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.scroll-user-list-total-dash {
 overflow: scroll;
 height: 80vh;
}

.wrp-body-sharing {
 display: grid;
 padding: 1em;
}

