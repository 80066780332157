.hm-title-footer-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 40em;

 justify-content: center;
 align-items: center;
}
.hm-title-footer-home h3 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.hm-title-footer-home p {
 display: flex;
 font-size: 1em;
 font-weight: 200;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
 color: #dee2e6;
}

.hm-title-footer-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 40em;

 justify-content: center;
 align-items: center;
}
.hm-title-footer-home-sm h3 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.hm-title-footer-home-sm p {
 display: flex;
 padding: 1em;
 font-size: 4.5vw;
 font-weight: 100;

 letter-spacing: px;
 line-height: 1.7;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;

 color: #fff;
}
