.flex-credit-name {
 display: grid;
 place-items: center;
 width: 100%;
 margin-top: 2ch;
 padding: 1ch 0;
 max-width: 15em;
 border-radius: 0.4em;
 background-color: green;
}
.flex-credit-name p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 margin-top: 1ch;
 color: #fff;
 font-family: var(--text-family);
}
