.wrp-arrow-profil-money {
 display: flex;
 flex-direction: column;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
.wrp-arrow-profil-money-sm {
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;
 transform: translate(-50%, -50%);
 color: rgba(116, 116, 116, 0.995);
}
