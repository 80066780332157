/* logo available */
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Vinyl&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;500&display=swap");

:root {
 --font-condensed: "Overpass", sans-serif;
 --font-title-name: "Rubik Vinyl", cursive;
 --font-quote-name: "Nunito Sans", sans-serif;
}

.wrp-logo-print-tickets {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-top: 2vh;
 width: 100%;
}

.wrp-logo-print-tickets img {
 height: 3em;
 width: 15em;
 object-fit: cover;
}
.wrp-logo-print-tickets h2 {
 display: flex;
 font-size: 1.4em;
 font-weight: 500;
 font-family: var(--font-title-name);
 line-height: 1;
 letter-spacing: 1px;
 border-bottom: 2px solde black;
}
