.service-quote-all {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 22.4em;

 justify-content: center;
 align-items: center;
}
.service-quote-all h3 {
 display: flex;
 font-size: 1.3em;
 letter-spacing: -0.5px;
 line-height: 1.4;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 400;
 font-style: normal;
}
.service-quote-all p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin-top: 1vh;
 letter-spacing: 0.6px;
 line-height: 1.8;

 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;

 color: #adb5bd;
}

.service-quote-all-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;

 margin-top: 10vh;
 justify-content: center;
 align-items: center;
}
.service-quote-all-sm h3 {
 display: flex;
 font-size: 2.3em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.service-quote-all-sm p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 margin: 5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.margin-top-5vh {
 margin: 5vh 0;
}
