.wrp-input-broker-prixs {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;
 min-height: 90vh;

 justify-content: center;
 align-items: center;
}
.wrp-input-broker-prixs form {
 display: flex;
 flex-direction: column;
 padding: 1em;
}
.wrp-input-broker-prixs form h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
.wrp-input-broker-prixs form input {
 display: flex;
 flex-direction: column;
 font-size: 2.4em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 1px;
 border: transparent;
 text-align: center;
 border-radius: 0.1em;
 color: #1c2321;

 background: transparent;
}
.wrp-input-broker-prixs form button {
 font-family: var(--text-family);
 margin-top: 2vh;
}
.wrp-input-broker-prixs form h3 {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);

 color: red;
}

.wrp-prix-flex-broker {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 gap: 0.5em;
}
.wrp-prixs-flex-broker {
 display: flex;
 flex-direction: row;
 gap: 0.5em;
}

.wrp-prix-flex-box-broker {
 display: flex;
 flex-direction: column;
 margin-top: 2vh;
}
.box-flex-prix-brokers {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: start;
 align-items: start;
}
.wrp-flex-prix-counts {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 width: 100%;
}

.flex-count-dialog {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.flex-count-dialog-sub {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 width: 100%;
 align-items: center;
}
.view-dialog-billing {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.view-dialog-billing h2 {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}

.view-dialog-billing-quote {
 display: flex;
 width: 100%;
}
.view-dialog-billing .view-dialog-billing-quote h3 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 white-space: nowrap;

 line-height: 1;
 letter-spacing: 0px;
}
.view-dialog-billing .view-dialog-billing-quote p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 font-family: var(--text-family);
 white-space: nowrap;

 width: 5em;
 line-height: 1;
 letter-spacing: 0px;
}
.margin-dialog-count {
 display: flex;
 flex-direction: column;
 margin-right: 5em;
}

.divider-dialog {
 display: flex;

 justify-content: end;
 align-items: flex-end;
 height: 1px;
 margin-bottom: 1vh;
 background-color: #c8dee4;
}
