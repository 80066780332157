.quote-pret-duration {
 display: flex;
}

.quote-pret-duration p {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;

 margin: 5vh 0;

 line-height: 1;
 letter-spacing: 0px;

 color: #db3a34;
}
