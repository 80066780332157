.flex-body-center-credit {
 display: grid;
 place-items: center;
}

.flex-bord-crediter {
 display: grid;
 place-items: center;
 width: 100%;
 max-width: 20em;
 border-radius: 0.3em;
 border: 1px solid #a5a5a5;
}
