.wrp-quote-sign {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 place-items: start;
 width: 100%;
}
.wrp-quote-sign p {
 display: flex;
 font-size: 1em;
 letter-spacing: 0px;
 font-family: var(--text-family);
 font-weight: 400;
 line-height: 1;
}

/* small view available */
.wrp-quote-sign-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0 2%;
}
.wrp-quote-sign-sm p {
 display: flex;
 font-size: 1em;
 letter-spacing: 0px;
 font-family: var(--text-family);
 max-width: 90vw;
 font-weight: 400;
 line-height: 1.1;
}
