/* View nav wallet */
.wrp-nav-pret-slide {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 0 3%;
 color: white;
 z-index: 10;
}
