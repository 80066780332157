.flex-balance-user-creditaire {
 display: flex;
 margin-top: 2vh;
}
.flex-balance-user-creditaire p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
