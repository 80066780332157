.wrp-prix-exchange {
 display: flex;
 flex-direction: column;
 color: var(--color-base);
 margin-bottom: 7vh;
}
.wrp-prix-exchange p {
 display: flex;
 font-size: 3em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: -3px;
 color: var(--color-base);
}
.wrp-prix-exchange span {
 display: flex;
 font-size: 1.6em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: -1px;
}

/* Small screen */
.wrp-prix-exchange-sm {
 display: flex;
 flex-direction: column;
 position: absolute;

 justify-content: center;
 align-items: center;

 top: 50%;
 left: 50%;
 color: var(--color-base);

 transform: translate(-50%, -50%);
}
.wrp-prix-exchange-sm p {
 display: flex;
 font-size: 3em;
 font-weight: 300;
 font-family: var(--text-family);

 line-height: 1;
 letter-spacing: -3px;
 white-space: nowrap;
 color: var(--color-base);
}
.wrp-prix-exchange-sm span {
 display: flex;
 font-size: 2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}
