.title-body-dashed {
 display: flex;
 color: var(--color-yellow);
}
.title-body-dashed h2 {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}
