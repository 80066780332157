.bd-overview-list-child {
 display: flex;
 flex-direction: column;

 justify-content: space-between;
 align-items: baseline;

 height: 80vh;
 padding: 0 5%;

 /* min-height: 90vh; */
}
