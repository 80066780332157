.flex-tontine-model {
 display: grid;
 place-items: center;
}
.flex-tontine-model h1 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 margin-bottom: 4vh;
 font-family: var(--text-family);

 color: #7c7a7a;
}
