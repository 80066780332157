.wrp-quote-footer-operator {
 display: flex;
 width: 100%;

 justify-content: center;
 align-items: center;
}
.wrp-quote-footer-operator p {
 display: flex;
 font-size: 1em;
 font-weight: 100;
 max-width: 30em;
 line-height: 1.2;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--text-family);
}

/* Small */
.wrp-quote-footer-operator-sm {
 display: flex;
 width: 100%;

 justify-content: center;
 align-items: center;
}
.wrp-quote-footer-operator-sm p {
 display: flex;
 font-size: 0.5em;
 font-weight: 100;
 max-width: 90vw;
 line-height: 1.2;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--text-family);
}
