.hm-btn-sm {
 display: grid;
 place-items: center;
 color: #fff;
}
.hm-btn-sm p {
 display: flex;
 font-size: 0.7em;
 letter-spacing: 1px;
 line-height: 1;
 text-align: center;
 text-transform: uppercase;
 border-radius: 2em;
 padding: 1.5em 2em;
 background-color: #001f54;

 /* font-family: "Lexend Deca", sans-serif; */

 font-family: var(--text-family);

 font-optical-sizing: auto;
 font-weight: 400;
 font-style: normal;
 cursor: pointer;
}
