.flex-footer-button-credit {
 display: grid;
 place-items: center;
 width: 100%;
 /* padding: 0 30em; */
}

.flex-footer-button-credit button {
 display: flex;
 justify-content: center;
 align-items: center;

 border-radius: 0.3em;
 font-size: 1em;
 letter-spacing: 1px;
 cursor: pointer;
 white-space: nowrap;

 outline-style: none;
 font-family: var(--text-family);

 padding: 0.355em;
 width: 100%;

 color: var(--color-btn);
 border: 0.1px solid #e36414;
 background: #e36414;
}
