.wrp-success-withd-prix {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 margin: 5vh 0;
}
.wrp-success-withd-prix h2 {
 display: flex;
 flex-direction: column;
 font-size: 4em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: -3px;
}
.wrp-success-withd-prix span {
 display: flex;
 flex-direction: column;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 1px;
}

/* Small Screen */
.wrp-success-withd-prix-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 margin: 7vh 0;
}
.wrp-success-withd-prix-sm h2 {
 display: flex;
 font-size: 2.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: -3px;
}
.wrp-success-withd-prix-sm span {
 display: flex;
 flex-direction: column;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 1px;
}
