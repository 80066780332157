.margin-top-5 {
 margin-top: 5vh;
}

.flex-sharing {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.rounded-black {
 border: 1px solid #335c67;

 padding: 1px;
 border-radius: 100%;
}
