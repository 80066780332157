.name-head-dashed {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 margin: 2vh 0;
 width: 100%;
}
.name-head-dashed h2 {
 display: flex;
 font-size: 1.5em;
 font-family: var(--text-family);
 font-weight: 900;
 line-height: 1;
 letter-spacing: 0.1px;
 text-align: center;
 color: #f6fff8;

 white-space: nowrap;
}
.name-head-dashed h3 {
 display: flex;
 font-size: 0.7em;
 font-family: var(--text-family);
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0.5px;
 text-align: center;
 margin-top: 8vh;
 text-transform: uppercase;

 white-space: nowrap;
}
