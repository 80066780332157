.wrp-line-widthdraw {
 display: flex;
 width: 100%;
 height: 1px;
 margin-top: 7vh;

 max-width: 20em;
 background: #e0e0e0;
}
.wrp-line-widthdraw-sm {
 display: flex;
 width: 100%;
 height: 1px;
 margin-top: 1vh;

 max-width: 5em;
 background: #e0e0e0;
}
