.wrp-body-info-tontine {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 90vh;
}
