.wrp-tontine-budget {
 display: flex;
 flex-direction: column;
 width: 100%;

 justify-content: center;
 align-items: center;
}
.wrp-tontine-budget img {
 height: 2.7em;
 width: 2.7em;
 object-fit: cover;
 margin-top: 2ch 0;
}
.wrp-tontine-budget p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 margin-top: 1ch;
 letter-spacing: 0px;
}
.wrp-tontine-budget h2 {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 margin-top: 2ch;
 letter-spacing: 0px;
 color: #6c757d;
}
