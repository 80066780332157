.grid-template-repeat-check {
 display: grid;
 grid-gap: 1em;
 margin-top: 15vh;
 place-items: center;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.grid-template-repeat-check-sm {
 display: flex;
 flex-direction: column;
}
