/* wrp-success-pin */
.wrp-success-pin {
 display: flex;
 flex-direction: column;
 position: relative;

 justify-content: center;
 align-items: center;

 width: 100%;
 height: 90vh;
}
