.flex-service-all-image {
 display: grid;
 place-items: center;

 width: 75%;
 height: 95vh;
}
.flex-service-all-image img {
 height: 100%;
 width: 100%;
 border-radius: 2%;
 object-fit: cover;
}

.flex-service-all-image-sm {
 width: 100vw;
 height: auto;
 border-radius: 5%;
 margin-top: 5vh;
 background-color: #ccc9dc;
}
.flex-service-all-image-sm img {
 width: 100%;
 height: auto;
 min-height: 48vh;
 object-fit: cover;
}
