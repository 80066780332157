.totine-model-ima {
 display: flex;
}
.totine-model-ima img {
 height: 100%;
 width: 50vw;
 object-fit: cover;
}

.totine-model-ima-sm {
 display: flex;
}
.totine-model-ima-sm img {
 height: 20vh;
 width: 60vw;
 object-fit: cover;
}
