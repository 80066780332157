.flex-head-list-cmd {
 display: flex;
}
.flex-head-list-cmd header {
 position: fixed;
 z-index: 9999;
 top: 0;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-cmd header li {
 list-style: none;
}
.flex-head-list-cmd header .container {
 width: 100%;
}
.flex-head-list-cmd header .navbar {
 display: flex;

 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmd header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-cmd section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-cmd section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-cmd section li {
 margin-top: 3vh;
 width: 100%;
 border-bottom: 0.01em solid #cfd2cd;
}

.flex-head-list-cmd section form {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmd-button section form button {
 margin-top: 5vh;
 border: none;
 outline: none;
}
.flex-head-list-cmd section form input {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 margin: 1.5vh 0;
}

.mt-title-update-fiat {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 padding: 5vh 0;
}

.flex-head-list-cmd .box-data-cmd-list {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-cmd .box-data-cmd-list h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: var(--nav-cmd-bg);
}

.flex-head-list-cmd .box-data-cmd-operator {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 width: 100%;
}
.flex-head-list-cmd .cmd-operator-title {
 display: flex;
 flex-direction: row;

 justify-content: space-between;
 place-items: start;

 width: 100%;
}

.cmd-operator-title {
 display: flex;
 place-items: start;
}
.cmd-operator-title h3 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 text-align: start;
 color: #adb5bd;

 line-height: 2;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
.cmd-operator-title p {
 display: flex;
 font-size: .9em;
 
 font-weight: 300;
 text-align: start;

 justify-content: start;
 align-items: flex-start;
 color: #212529;

 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
