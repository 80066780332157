.grid-templates-rows {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100vh;
 width: 100%;
}
.grid-templates-rows-sm {
 display: grid;
 grid-template-rows: auto 1fr auto;
 height: 100vh;
}
