.wrp-arrow-profil {
 display: flex;
 cursor: pointer;

 position: absolute;
 top: 5%;
 left: 5%;

 transform: translate(-50%, -50%);
}
.wrp-arrow-profil-sm {
 display: flex;

 display: flex;
 position: absolute;
 top: 5%;
 left: 6%;
 transform: translate(-50%, -50%);
}
