.quote-well-fiat {
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 1em;
 width: 100%;
}
.quote-well-fiat p {
 display: flex;
 font-size: 1.3em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}
