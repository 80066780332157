.wrp-network-stock {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.wrp-network-stock div {
 display: flex;
 width: 100%;
 cursor: pointer;
 border: 0.1em solid white;
 background: #b5c8d9;
 color: black;
}
.wrp-network-stock h2 {
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 width: 100%;
 text-align: center;

 padding: 1em;
 justify-content: end;
 align-items: end;
 white-space: nowrap;

 font-family: var(--text-family);
 letter-spacing: 0px;
}
.wrp-network-stock input {
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 width: 100%;
 text-align: center;

 border: none;
 cursor: pointer;
 background-color: #b5c8d9;
 border-left: 0.1em solid white;

 padding: 1em;
 justify-content: end;
 align-items: end;
 white-space: nowrap;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
