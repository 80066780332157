.title-bd-tontine {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 80%;
 margin-top: 5ch;
 margin-bottom: 20vh;
}
.title-bd-tontine h1 {
 display: flex;
 font-size: 1.2399em;
 text-align: center;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 color: #7f7f7f;
}
.title-bd-tontine img {
 height: 5em;
 width: 5em;
 object-fit: cover;
}
