.prix-body-dashed {
 display: flex;
 flex-direction: column;
 width: 100%;
 gap: 3vh;
 padding: 2em;
}
.prix-body-dashed-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 0.5em;
 margin-bottom: 5vh;
}
