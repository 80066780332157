.user-box-stock {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;

 margin-top: 3vh;
}
.user-box-stock p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: -1;
 letter-spacing: 0px;
}
.flex-list-user-stock {
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 0.5em;
}
.sub-list-user {
 display: flex;
 flex-direction: column;
 gap: -2vh;
}
