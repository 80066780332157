.prix-body-pret-balance {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: center;

 width: 100%;
 height: 100vh;
}
.prix-body-pret-balance-sm {
 display: flex;
 flex-direction: column;

 justify-content: start;
 align-items: center;

 width: 100%;
 height: 100vh;
}
