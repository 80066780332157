.wrp-box-profil {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 padding: 1em;

 width: 100%;
 height: 100%;
 min-height: 80vh;
}
