.quote-wallet-money {
 display: flex;
 margin-top: 5vh;

 width: 100%;
 justify-content: center;
 align-items: center;
}
.quote-wallet-money p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
