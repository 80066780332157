.wrp-frais-current {
 display: flex;
 position: absolute;
 flex-direction: column;

 width: 100%;
 justify-content: center;
 align-items: center;
 max-width: 95vw;

 top: 90%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-frais-current span {
 display: flex;

 justify-content: center;
 align-items: center;

 font-size: 1em;
 font-weight: 300;
 text-align: center;
 line-height: 1.2;
 letter-spacing: 0px;
}
.wrp-frais-current-sm {
 display: flex;
 position: absolute;
 flex-direction: column;

 width: 100%;
 justify-content: center;
 align-items: center;
 max-width: 95vw;

 top: 90%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-frais-current-sm span {
 display: flex;
 flex-direction: column;
 font-size: var(--para-small);
 text-align: center;
 padding: 0.4em;
 line-height: 1;
 letter-spacing: 0px;
 max-width: 70vw;
}
