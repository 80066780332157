.wrp-buy-brokers {
 display: flex;
 position: relative;
 flex-direction: column;

 width: 100%;
 height: 100%;

 color: var(--cl-color);
 background-color: var(--bg-color);
}
.wrp-buy-brokers .wrp-buy-brokers-sub {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 min-height: 98vh;
 width: 100%;
}
