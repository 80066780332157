.grid-template-repeat-want {
 display: grid;
 grid-gap: 1em;
 place-items: center;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.grid-template-repeat-want-sm {
 display: flex;
 flex-direction: column;
}
