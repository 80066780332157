.grid-template-repeat-all {
 display: grid;
 grid-gap: 1em;
 place-items: center;
 margin-top: 20vh;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.grid-template-repeat-all-sm {
 display: flex;
 flex-direction: column;
}
