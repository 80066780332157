.flex-deposit-image-earn {
 display: flex;
 flex-direction: column;
 padding: 1em;

 height: auto;
 grid-gap: 1vh;
 grid-template-columns: repeat(3, 1fr);
}
.flex-deposit-image-earn img {
 height: 70vh;
 width: 100%;
 border-radius: 2em;
 object-fit: cover;
}
.flex-deposit-image-earn h5 {
 padding: 1rem;
 display: flex;
 font-size: 1.5em;
 letter-spacing: 0px;
 line-height: 1.1;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
.flex-deposit-image-earn p {
 display: flex;
 font-size: 0.9em;
 letter-spacing: 0px;
 padding: 1rem;
 line-height: 2;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.flex-deposit-image-earn-sm {
 display: flex;
 flex-direction: column;

 margin-top: 5vh;

 height: auto;
 grid-gap: 1vh;
 grid-template-columns: repeat(3, 1fr);
}
.flex-deposit-image-earn-sm img {
 height: 55vh;
 width: 100%;
 object-fit: cover;
}
.flex-deposit-image-earn-sm h5 {
 padding: 1rem;
 display: flex;
 font-size: 1.5em;
 letter-spacing: 0px;
 line-height: 1.1;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
.flex-deposit-image-earn-sm p {
 display: flex;
 font-size: 0.9em;
 letter-spacing: 0px;
 padding: 1rem;
 line-height: 2;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
