.quote-save {
 display: flex;
 font-size: 1.2em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
 width: 100%;
 text-align: center;
 max-width: 23em;
}
