.wrp-values-validExh {
 display: flex;
 flex-direction: column;
}
.wrp-values-validExh h2 span {
 display: flex;
 font-size: 2.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: -4px;
 color: var(--color-base);
}

.wrp-values-validExh-sm {
 display: flex;
 flex-direction: column;

 position: absolute;

 justify-content: center;
 align-items: center;

 top: 52%;
 left: 50%;

 transform: translate(-50%, -50%);
}

.wrp-values-validExh-sm h2 {
 display: flex;
 flex-direction: column;

 gap: 0.5em;

 justify-content: center;
 align-items: center;

 width: 100%;
}
.wrp-values-validExh-sm h2 span {
 display: flex;
 font-size: 1.8em;
 font-weight: 300;
 white-space: nowrap;
 font-family: var(--text-family);
 line-height: 0;
 letter-spacing: -3px;
 color: var(--color-base);
}
.unite-prix-currency {
 display: flex;
 font-size: 0.8em;
 font-family: var(--text-family);
 white-space: nowrap;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}
