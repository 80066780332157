.wrp-contry-print-overview {
 display: flex;
 flex-direction: column;
 width: 100%;

 margin: 2vh 0;

 justify-content: center;
 align-items: center;
}
.wrp-contry-print-overview h1 {
 display: flex;
 font-size: 2em;
 font-weight: 500;
 font-family: var(--font-quote-name);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-contry-print-overview p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: -1px;
}
.wrp-contry-print-overview span {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: center;
 font-family: var(--font-condensed);
 line-height: 1;
 letter-spacing: -1px;
}
