.wrp-title-sharing {
 display: flex;
 color: #006d77;
}
.wrp-title-sharing h1 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
