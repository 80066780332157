.wrp-ima-franc {
 display: flex;
 margin: 0.3em;
}
.wrp-ima-franc img {
 display: flex;
 height: 1em;
 width: 1em;
 border-radius: 100%;
 object-fit: cover;
}
