.wrp-title-solde-credit {
 display: grid;
 place-content: start;
 width: 100%;
 padding: 0.2em;
 margin-top: 2ch;
 background-color: gray;
}

.wrp-title-solde-credit h1 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 line-height: 1;
 margin-top: 0.5vh;
 letter-spacing: 0.5px;
 font-family: var(--text-family);
}
