.flex {
 display: flex;
 place-items: center;
 gap: 1em;
}

.flex-thrift {
 display: grid;
}

.flex-thrift p {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-column-hm-dash-list-user {
 display: flex;
 flex-direction: column;
 justify-content: start;
 margin-top: 5vh;
}
.wrp-column-hm-dash-list-user form {
 display: flex;
 flex-direction: row;
 padding-left: center;
 justify-content: space-between;
 width: 100%;
 padding: 1.2vh 1em;
 border-radius: 0.5em;
 background-color: #e6e6ea;
}
.wrp-column-hm-dash-list-user form input {
 display: flex;
 border: none;
 background: transparent;

 width: 100%;

 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.wrp-hm-dash-list-user {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 cursor: pointer;
 margin-top: 5vh;
 max-width: 100vw;
 padding-bottom: 1vh;
 border-bottom: 1px solid #eeeeee;
}
.flex-column-list-user-all {
 display: flex;
 justify-content: start;
 place-items: center;
}

.wrp-hm-dash-list-user h3 {
 display: flex;
 font-size: 1em;
 font-weight: 500;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
.wrp-hm-dash-list-user span {
 display: flex;
 place-items: center;
 gap: 1em;
 justify-content: start;
}
.wrp-hm-dash-list-user p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: start;
 white-space: nowrap;
 line-height: 0;
 letter-spacing: 0px;
 font-family: var(--text-family);
 width: 7em;
 margin-top: 2vh;
 color: #5b5b5b;
}

.flex-money-user-list {
 display: flex;
 flex-direction: column;
}

.flex-money-user-list span {
 display: flex;
 font-size: 0.7em;
 width: 10em;

 font-weight: 400;
 text-align: center;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: #5b5b5b;
}
