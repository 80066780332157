.keep-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin: 40vh 0;

 justify-content: center;
 place-items: center;
}
.keep-title-quote-home h4 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;

 background-clip: text;
 color: transparent;
 background-image: linear-gradient(to right, #3a0ca3, #febaaa, #feba7b, #001f54, #3a0ca3);
 /* background-image: linear-gradient(to right, #feba7b, #febaaa, #feba7b, #001f54); */
}
.keep-title-quote-home p {
 display: flex;
 font-size: 1em;

 font-weight: 200;
 margin: 7vh 0;
 max-width: 60vw;
 text-align: center;

 letter-spacing: 0.6px;
 line-height: 1.8;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
 color: #6c757d;
}

.keep-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;
 margin-top: 15vh;
}
.keep-title-quote-home-sm h4 {
 display: flex;
 font-size: 2.1em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: start;
 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;

 background-clip: text;
 color: transparent;
 background-image: linear-gradient(to right, #3a0ca3, #febaaa, #feba7b, #001f54, #3a0ca3);
}
.keep-title-quote-home-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin-top: 5vh;
 letter-spacing: 0px;
 line-height: 1.5;
 text-align: start;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
