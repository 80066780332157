.wrp-buy-brokers-prix {
 display: flex;
 flex-direction: column;
 position: relative;

 width: 100%;
 height: 100%;

 justify-content: center;
 align-items: center;

 padding: 0.5vh 0;
 color: var(--cl-color);
 background-color: var(--bg-color);
}
