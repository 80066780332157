.tontine-nav-invited {
 display: flex;
}
.tontine-nav-invited img {
 height: 1em;
 width: 1em;
 object-fit: cover;
}
.tontine-send-invited img {
 height: 0.3em;
 width: 0.3em;
}
