.wrp-quote-validexchange {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;
 max-width: 20em;
}
.wrp-quote-validexchange p {
 display: flex;
 font-size: 1.187em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 text-align: center;
}

/* small view available */
.wrp-quote-validexchange-sm {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

 position: absolute;
 top: 35%;
 left: 50%;
 transform: translate(-50%, -50%);

 width: 100%;
}
.wrp-quote-validexchange-sm p {
 display: flex;
 font-size: 1.188em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 max-width: 100vw;
 font-weight: 400;
 text-align: center;
}
