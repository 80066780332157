.flex-network-operator {
 display: flex;
 padding: 0 30%;
}
.flex-network-operator-sm {
 display: flex;
 padding: 0 5%;
}

.martp-1ch {
 margin-top: 2ch;
}

.pop-up-last {
 display: flex;
 font-size: 0.7em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: #a5a5a5;
}
