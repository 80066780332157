.wrp-input-phone-register {
 display: flex;
 flex-direction: column;

 width: 100%;
 max-width: 20em;

 position: absolute;
 top: 60%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-phone-register form {
 display: flex;
 flex-direction: column;
 gap: 1em;
 justify-content: center;
 align-items: center;
}
.wrp-input-phone-register form input {
 display: flex;
 flex-direction: column;
 font-size: 1.7em;
 font-weight: 400;
 line-height: 1;
 letter-spacing: 0px;
 width: 100%;
 padding: 0.2em;
 font-family: var(--text-family);
 border: none;
 border-radius: 0.2em;
 color: black;
 background: rgba(201, 201, 201, 0.413);
}

/* small view input */
.wrp-input-phone-register-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 width: 100%;

 position: absolute;
 top: 91%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-input-phone-register-sm form {
 display: flex;
 flex-direction: column;
 gap: 0.5em;
}
.wrp-input-phone-register-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.7em;
 font-weight: 400;
 padding: 0.3em;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 width: 97vw;
 border: none;
 border-radius: 0.2em;
 color: black;
 background: rgba(201, 201, 201, 0.413);
}
