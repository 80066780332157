.wrp-regisbtn {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}
.wrp-regisbtn-sm {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
}
