.flex-want-image {
 display: grid;
 place-items: center;

 padding: 2em;

 width: 45vw;
 height: 95vh;
 border-radius: 7%;
}
.flex-want-image img {
 height: 100%;
 width: 100%;
 border-radius: 3%;
 object-fit: cover;
}

.flex-deposit-image-sm {
 width: 100vw;
 height: auto;
 border-radius: 5%;
 margin-top: 5vh;
 background-color: #ccc9dc;
}
.flex-deposit-image-sm img {
 width: 100%;
 height: auto;
 min-height: 48vh;
 object-fit: cover;
}
