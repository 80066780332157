.wrp-btn-brokers {
 display: flex;
 width: 100%;
 height: 100%;
 max-width: 15em;
}
.wrp-btn-brokers button {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--text-family);

 outline: none;
 border: none;

 justify-content: center;
 align-items: center;

 outline-style: none;
 white-space: nowrap;

 padding: 2vh 0;

 border-radius: 1.1em;
 width: 100%;
 background-color: var(--bgcolor-btn);
 color: white;
}
.Btn-Broker {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0.5px;
 font-family: var(--text-family);

 outline: none;
 border: none;

 justify-content: center;
 align-items: center;

 outline-style: none;
 white-space: nowrap;

 padding: 1.155ch 0;

 border-radius: 0.9em;
 width: 100%;
 background-color: var(--bgcolor-btn);
 color: white;
}
