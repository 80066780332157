.wrp-rate-stock {
 display: flex;

 justify-content: center;
 align-items: center;

 padding: 0.8em;

 width: 100%;
 background: #59a5d8;
 color: #fff;
}

.wrp-rate-stock span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 white-space: nowrap;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
