.logo-withdraw {
 display: flex;

 position: absolute;
 top: 20%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.logo-withdraw img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

/* Small screen */
.logo-withdraw-sm {
 display: flex;

 position: absolute;
 top: 20%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.logo-withdraw-sm img {
 display: flex;

 width: 4em;
 height: 4em;
 object-fit: cover;
}
