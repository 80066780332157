.wrp-btn-dealer-unit {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 position: absolute;

 left: 85%;
 bottom: 5%;
}
.wrp-btn-dealer-unit img {
 height: 3em;
 width: 3em;
 border-radius: 100%;
 object-fit: cover;
}
.wrp-btn-dealer-unit h4 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 text-align: center;
 letter-spacing: 0px;
 margin-top: 1vh;
}

/* Small dealer  */
.wrp-btn-dealer-unit-sm {
 display: grid;
 place-items: center;
 position: absolute;

 left: 75%;
 bottom: 5%;
}
.wrp-btn-dealer-unit-sm img {
 height: 3em;
 width: 3em;
 object-fit: cover;
}
.wrp-btn-dealer-unit-sm h4 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 2vh;
}
.wrp-dealer-unit {
 display: grid;
 place-items: center;
 cursor: pointer;
}
.wrp-dealer-unit p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
