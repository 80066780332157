.wrp-qt-buy-brokers {
 display: flex;
 width: 100%;
 max-width: 26em;
}
.wrp-qt-buy-brokers p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1.1;
 text-align: start;
 font-family: var(--text-family);
 letter-spacing: 0px;
}

/* Small */
.wrp-qt-buy-brokers-sm {
 display: flex;
 width: 100%;
 max-width: 95vw;
}
.wrp-qt-buy-brokers-sm p {
 display: flex;
 font-size: 1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1.1;
 text-align: start;
 letter-spacing: 0.5px;
}
