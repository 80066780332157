.wrp-exchange-money {
 display: flex;
 flex-direction: column;
 height: 100vh;
 min-height: 100%;
 width: 100%;
 justify-content: center;
 align-items: center;

 position: relative;
}
