.padding-2em {
 padding: 2em;
}
.wrp-operator-creditaire {
 display: flex;
 width: 100%;
}
.wrp-operator-creditaire p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 text-align: center;
 font-family: var(--text-family);
 color: #7f7f7f;
}
