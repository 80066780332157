.wrp-logo-solde {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-logo-solde img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}
.wrp-logo-solde p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 margin-top: 2vh;
}
