.deposit-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 80vw;
 padding-left: 5em;
 margin: 40vh 0;
 justify-content: center;
 place-items: center;
}
.deposit-title-quote-home h4 {
 display: flex;
 font-size: 2.5em;
 letter-spacing: -0.5px;
 line-height: 1.1;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;

 color: #343a40;
}
.deposit-title-quote-home p {
 display: flex;
 font-size: 1em;

 font-weight: 200;
 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
 color: #6c757d;
}

/* Small */
.deposit-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;
 margin: 10vh 0;
 justify-content: center;
 place-items: center;
}
.deposit-title-quote-home-sm h4 {
 display: flex;
 font-size: 2.5em;
 letter-spacing: -0.5px;
 line-height: 1.1;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
 color: #343a40;
}
.deposit-title-quote-home-sm p {
 display: flex;
 font-size: 1em;

 font-weight: 200;
 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-style: normal;
 color: #6c757d;
}
