.flex-center {
 display: grid;
 place-items: center;
 width: 100%;
}

.flex-box-network {
 display: grid;
 place-items: center;
 padding: 2vh 7%;
 width: 100%;
 border-radius: 0.4em;
 color: #fff;
 background-color: #a2d2ff;
}
.flex-box-network p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 border-radius: 0.3em;
}

.flex-title-network {
 display: grid;
 place-items: center;
 width: 100%;
 padding-top: 2ch;
}
.flex-title-network h3 {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 border-radius: 0.3em;
}

.flex-martop-3vh {
 margin-top: 1vh;
}
