.wrp-logo-send {
 display: flex;

 position: absolute;
 top: 25%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-logo-send img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

.wrp-logo-send-sm {
 display: flex;

 position: absolute;
 top: 20%;
 left: 50%;

 transform: translate(-50%, -50%);
}
.wrp-logo-send-sm img {
 display: flex;
 width: 4em;
 height: 4em;
 object-fit: cover;
}

.flex-id-pin-send {
 display: grid;
 place-items: center;
}
.flex-id-pin-send p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 0;
 white-space: nowrap;
 letter-spacing: 0px;
 margin-left: 1em;
 font-family: var(--text-family);
 color: #7f7f7f;
}
