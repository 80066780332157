:root {
 --blue-asked-tontine: #0077b6;
 --grey-asked-tontine: #343a40;
}

.wrp-prix-quote-withdraw-get-tontine {
 display: grid;
 place-items: center;
 color: var(--blue-asked-tontine);
}
.wrp-prix-quote-withdraw-get-tontine p {
 display: flex;
 font-size: 3.133em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: -3px;
 font-family: var(--text-family);
}

/* Small prix widthdraw */
.wrp-prix-quote-withdraw-get-tontine-sm {
 display: grid;
 place-items: center;
 color: var(--blue-asked-tontine);
}
.wrp-prix-quote-withdraw-get-tontine-sm p {
 display: flex;
 font-size: 2.133em;
 font-weight: 300;
 line-height: 1.2;
 text-align: center;
 letter-spacing: -2px;
 font-family: var(--text-family);
}
