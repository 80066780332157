.wrp-qt-broker-unite {
 display: flex;
}
.wrp-qt-broker-unite p {
 display: flex;
 font-size: 1.187em;
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0.5px;
 font-family: var(--text-family);
 text-align: center;
 max-width: 20em;
}
