.wrp-commi-box-unite-stock {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 90vw;
}
.wrp-commi-box-unite-stock p {
 display: flex;
 font-size: 0.8em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
 text-align: start;
 white-space: nowrap;

 padding-top: 1vh;
 padding-bottom: 1vh;
 margin: 0.3vh 0;
}

.flex-commi-stock {
 display: flex;
 place-items: center;
 border-radius: 0.8em;
 width: 100%;
 max-width: 100vw;
 margin: 1vh 0;
 padding-right: 0.4em;
 background-color: #2993;
}
