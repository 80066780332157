.nav-stock {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
 height: 100%;
}
.nav-stock img {
 height: 1.8em;
 width: 1.8em;
 object-fit: cover;
 object-position: 100%;
}
.nav-stock p {
 display: flex;
 font-size: 1.1em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
}
