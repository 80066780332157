.wrp-input-pieces {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.wrp-input-pieces form {
 display: flex;
 flex-direction: column;
}
.wrp-input-pieces form input {
 display: flex;
 flex-direction: column;
 font-size: 1.1em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 1px;
 width: 100%;
 border: none;
 padding: 2vh 0;
 border-radius: 0.1em;

 background: transparent;
}
.wrp-input-pieces p {
 display: flex;
 flex-direction: column;
 font-size: 1em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 white-space: nowrap;
 letter-spacing: 1px;
 width: 100%;
 border: none;
 color: #62656c;
}
.wrp-input-pieces .import-pieces {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-input-pieces .import-pieces .wrp-pieces-avatar-user {
 display: flex;
 width: 100%;
 justify-content: center;
 align-items: center;
}
