.flex-balance-must-creditaire {
 display: grid;
 place-items: center;
}
.flex-balance-must-creditaire div h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 margin-top: 1ch;
 padding: 1ch 0;
 color: grey;
}
.flex-balance-must-creditaire p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
