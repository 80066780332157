.flex-date-prix-ask-dash-pret {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
 padding: 6vh 5%;
 color: #ffc300;
}
.flex-date-prix-ask-dash-pret h2 {
 display: flex;
 font-size: 1.3em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.flex-date-prix-ask-dash-pret p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
