.more-title-quote-home {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin: 35vh 0;
 justify-content: center;
 place-items: center;
}
.more-title-quote-home h4 {
 display: flex;
 font-size: 2.7em;
 letter-spacing: -0.5px;
 line-height: 1.1;
 text-align: center;
 max-width: 17em;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.more-title-quote-home p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;

 max-width: 45em;
 margin: 7vh 0;

 letter-spacing: 0.6px;
 line-height: 1.4;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}

.more-title-quote-home-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
 padding: 1em;

 margin-top: 18vh;
 justify-content: center;
 align-items: center;
}
.more-title-quote-home-sm h4 {
 display: flex;
 font-size: 2.1em;
 letter-spacing: -0.5px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}
.more-title-quote-home-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 300;

 margin: 2.5vh 0;
 letter-spacing: 0px;
 line-height: 1.2;
 text-align: center;

 font-family: "Lexend Deca", sans-serif;
 font-optical-sizing: auto;
 font-weight: 200;
 font-style: normal;
}
