.qt-well-body-tontine {
 display: flex;
 margin-top: 2ch;
}

.qt-well-body-tontine p {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
