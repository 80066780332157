.solde-input-asked {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 20em;
 justify-content: center;
 align-items: center;
}
.solde-input-asked input {
 display: flex;
 outline: none;
 border: none;
 font-family: var(--text-family);
 padding: 0.2vh 0;
 justify-content: center;
 align-items: center;
 width: 100%;

 font-size: 3.5em;
 font-weight: 100;
 text-align: center;
 letter-spacing: 0px;
 border-radius: 0.3em;
}
.solde-input-asked h1 {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.solde-input-asked h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.solde-input-asked button {
 display: flex;
 margin-top: 7vh;
 margin-bottom: 5vh;
}
