.flex-transfert-head-swap {
 display: flex;
 padding: 1em;
}
.flex-transfert-head-swap h1 {
 display: flex;
 font-size: 0.9em;
 font-weight: 100;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: #0077b6;
}
