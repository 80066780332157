/*  */
.flex-center {
 display: flex;
 place-items: center;
}
.pad-top-2 {
 padding-top: 2vh;
}

.flex-usd-devise-sharing {
 display: flex;
}
.flex-usd-devise-sharing p {
 display: flex;
 font-size: 1.1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
