.grid-template-repeat {
 display: grid;
 grid-gap: 1em;
 place-items: center;
 grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
 /* background-color: #ccc5b9; */
 /* background-color: #ccc5b9; */
}

.grid-template-repeat-sm {
 display: flex;
 flex-direction: column;
 /* background-color: #ccc5b9; */
 /* background-color: #ccc5b9; */
}
