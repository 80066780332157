.flex-head-list-tontine {
 display: flex;
}
.flex-head-list-tontine header {
 position: fixed;
 top: 0;
 z-index: 9999;
 height: 6ch;
 width: 100%;
 background-color: var(--nav-cmd-bg);
}
.flex-head-list-tontine header li {
 list-style: none;
}
.flex-head-list-tontine header .container {
 width: 100%;
}
.flex-head-list-tontine header .navbar {
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
}
.flex-head-list-tontine header .navbar span {
 display: flex;
 font-size: 1.2em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: whitesmoke;
}
.flex-head-list-tontine section {
 display: flex;
 flex-direction: column;
 margin-top: 10vh;
 width: 100%;
 padding: 1em;
}
.flex-head-list-tontine section ul {
 list-style: none;
 margin: 0;
 padding: 0;
 width: 100%;
}
.flex-head-list-tontine section li {
 display: flex;
 flex-direction: row;

 margin-top: 3vh;
 width: 100%;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;
}

.flex-head-list-tontine p {
 display: flex;
 font-size: 1.333em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #6c757d;
}

.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title {
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: start;
}
.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title p {
 display: flex;
 font-size: 1.333em;
 font-weight: 300;
 line-height: 0;
 font-family: var(--text-family);
 letter-spacing: 0px;
 color: #6c757d;
}
.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title .flex-row-cmd-group {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 7em;
}
.flex-head-list-tontine .cmd-operator-title .cmd-operator-sub-title .flex-row-other-cmd {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 width: 1em;
}
