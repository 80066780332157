.wrp-subject-withdraw {
 display: flex;

 position: absolute;
 top: 35%;
 left: 50%;

 width: 100%;
 max-width: 20em;
 transform: translate(-50%, -50%);
}
.wrp-subject-withdraw p {
 display: flex;
 font-weight: 400;
 font-size: 1.1em;
 font-family: var(--text-family);
 text-align: center;
 margin-top: 5vh;
 line-height: 1.1;
 letter-spacing: 0px;
}

/* small view */
.wrp-subject-withdraw-sm {
 display: flex;

 position: absolute;
 top: 40%;
 left: 50%;

 width: 100%;
 max-width: 80vw;
 transform: translate(-50%, -50%);
}
.wrp-subject-withdraw-sm p {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 text-align: center;
 font-family: var(--text-family);
 letter-spacing: 0px;
}
