.wrp-form-input-pin {
 display: flex;
 flex-direction: column;

 height: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-form-input-pin form {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 25em;
 padding: 1em;
}
.wrp-form-input-pin input {
 display: flex;
 font-size: 1.5em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 2px;
 text-align: center;
 padding-left: 1.5em;
 width: 100%;
 border: none;
 color: black;
}
.wrp-form-input-pin form button {
 display: flex;
 margin-top: 2vh;
}

.info-detect {
 display: flex;
 font-size: 0.9em;
 font-weight: 100;
 line-height: 1.2;
 letter-spacing: 0px;
 font-family: var(--text-family);

 color: #7f7f7f;
}

.wrp-form-input-pin form .wrp-errors-code {
 display: flex;
 font-size: 0.3em;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 margin-top: 2em;
 width: 100%;
 color: crimson;
}
.wrp-form-input-pin form .wrp-errors-code-transparent {
 display: flex;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 width: 100%;
 color: transparent;
}
