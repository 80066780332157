.wrp-input-signin-user {
 display: flex;
 flex-direction: column;
 width: 100%;
 justify-content: center;
 align-items: center;
}
.wrp-input-signin-user form {
 display: flex;
 width: 100%;
 max-width: 25em;
 flex-direction: column;
}
.wrp-input-signin-user form input {
 display: flex;
 flex-direction: column;
 font-size: 1.4em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 padding: 0.5em 0;
 letter-spacing: 0px;
 border: none;
 border-bottom: 1px solid #62656c;

 border-radius: 0.1em;
 color: #000;

 background: transparent;
}
.wrp-input-signin-user form button {
 margin-top: 3vh;
}

/* Small sign user */
.wrp-input-signin-user-sm {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.wrp-input-signin-user-sm form {
 display: flex;
 flex-direction: column;
 padding: 0.5em;
}
.wrp-input-signin-user-sm form input {
 display: flex;
 flex-direction: column;
 font-size: 1.4em;
 font-weight: 400;
 font-family: var(--text-family);
 line-height: 1;
 padding: 0.5em 0;
 letter-spacing: 0px;
 border: none;
 border-bottom: 1px solid #62656c;

 border-radius: 0.1em;
 color: #000;

 background: transparent;
}
.wrp-input-signin-user-sm form button {
 margin-top: 3vh;
}
