.wrp-msg-output-ticked {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 width: 100%;
 max-width: 95vw;
 margin-top: 6vh;
 background-color: #fff;
}
@media screen {
 .wrp-msg-output-ticked {
  visibility: hidden;
 }
}
