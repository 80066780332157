.wrp-title-print-tickets-client {
 display: flex;
 width: 100%;
 justify-content: space-between;
 align-items: center;
}
.wrp-title-print-tickets-client h2 {
 display: flex;
 font-size: 1em;
 font-weight: 400;
 font-family: var(--font-condensed);
 line-height: 1;
 white-space: nowrap;
 letter-spacing: -1px;
}
