.red-creditaire {
 width: 100%;
 border-radius: 0.3em;
 padding: 0.4em;
 margin: 2vh 0;
 color: #fff;
 background-color: red;
}
.center-creditaire {
 display: flex;
 width: 100%;
 justify-content: center;
 place-items: center;
}
