.container-firebaseui {
 display: flex;
 flex-direction: column;
 height: 80vh;
 padding: 1em;

 width: 100%;

 justify-content: center;
 align-items: center;
}
.container-firebaseui h4 {
 display: flex;
 font-size: 1.23em;
 font-weight: 300;
 max-width: 17em;
 text-align: center;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;

 color: #274c77;
}
.container-firebaseui p {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 max-width: 25em;
 text-align: center;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;

 margin-top: 2vh;

 color: #8b8c89;
}
