.flex-deposite-image {
 display: grid;
}
.flex-deposite-image img {
 height: 40vw;
 width: 40vw;
 object-fit: cover;
}
/* 
.flex-deposite-image-sm {
 display: grid;
}
.flex-deposit-image-sm img {
 width: 30vw;
 height: 30vw;
 object-fit: cover;
} */
