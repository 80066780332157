.wrp-exchange-box {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100vh;

 gap: 0.5em;

 justify-content: center;
 align-items: center;

 position: relative;
}
