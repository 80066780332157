:root {
 --margin-service: 0.6vw;
 --margin-service-md: 0.4vw;
 --color-menu: #eff1f9;
 /* --color-menu: #f5f5f5; */

 --text-color-menu: #000;
}

.wrp-list-purchase-dashed {
 display: flex;
}
.wrp-list-purchase-dashed nav ul {
 display: flex;
 margin: 0;
 padding: 0;
 flex-wrap: wrap;
 list-style: none;
 width: 100%;
}
.wrp-list-purchase-dashed nav ul li {
 display: flex;
 flex-direction: column;
 position: relative;

 padding: 8vw 11vw;
 border-radius: 0.3em;
 margin: var(--margin-service-md);

 cursor: pointer;
 background: var(--color-menu);
 transition: 0.8s;

 transition: 3500ms linear cubic-bezier(0.83, 0.2, 0.35, 0.55);
}
.wrp-list-purchase-dashed nav ul li .wrp-list-abs {
 display: flex;
 flex-direction: column;
 position: absolute;

 width: 100%;
 justify-content: center;
 align-items: center;

 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
}
.wrp-list-purchase-dashed nav .wrp-list-abs span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 text-align: center;
 max-width: 10em;
 letter-spacing: 0.5px;
}

/* Meduim */
.wrp-list-purchase-dashed-md {
 display: flex;
}
.wrp-list-purchase-dashed-md nav ul {
 display: flex;
 margin: 0;
 padding: 0;
 flex-wrap: wrap;
 list-style: none;
 width: 100%;
 /* gap: 1.7vw; */
}
.wrp-list-purchase-dashed-md nav ul li {
 display: flex;
 flex-direction: column;
 position: relative;

 padding: 10vw 14vw;
 margin: var(--margin-service-md);
 border-radius: 0.2em;
 cursor: pointer;
 background: var(--color-menu);

 transition: 3500ms linear cubic-bezier(0.83, 0.2, 0.35, 0.55);
}
.wrp-list-purchase-dashed-md nav ul li .wrp-list-abs {
 display: flex;
 flex-direction: column;
 position: absolute;

 width: 100%;
 justify-content: center;
 align-items: center;

 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
}
.wrp-list-purchase-dashed-md nav .wrp-list-abs span {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 text-align: center;
 max-width: 10em;
 letter-spacing: 0.5px;
}

/* Small */
.wrp-list-purchase-dashed-sm {
 display: flex;
 width: 100%;
 justify-content: center;
}
.wrp-list-purchase-dashed-sm nav ul {
 display: flex;
 margin: 0;
 padding: 0.1em;

 flex-wrap: wrap;
 list-style: none;

 width: 100%;
 /* gap: 2vw; */
}
.wrp-list-purchase-dashed-sm nav ul li {
 display: flex;
 flex-direction: column;
 position: relative;

 padding: 12vw 15vw;
 border-radius: 0.2em;
 cursor: pointer;
 background: var(--color-menu);
 margin: var(--margin-service);

 transition: 3500ms linear cubic-bezier(0.83, 0.2, 0.35, 0.55);
}
.wrp-list-purchase-dashed-sm nav ul li .wrp-list-abs {
 display: flex;
 flex-direction: column;
 position: absolute;

 width: 100%;
 justify-content: center;
 align-items: center;

 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
}
.wrp-list-purchase-dashed-sm nav .wrp-list-abs span {
 display: flex;
 font-size: 0.9em;
 font-weight: 300;
 line-height: 1;
 font-family: var(--text-family);
 text-align: center;
 max-width: 10em;
 color: var(--text-color-menu);
 letter-spacing: 0.5px;
}
