.wrp-name-exchange {
 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;
 padding: 1.5ch 0;
 margin-bottom: 3vh;

 width: 100%;
}
.wrp-name-exchange h2 {
 display: flex;
 font-size: 1.2em;
 font-weight: 900;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.1px;
 white-space: nowrap;
}

/* small */
.wrp-name-exchange-sm {
 display: flex;
 flex-direction: column;

 position: absolute;
 top: 30%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.wrp-name-exchange-sm h2 {
 display: flex;
 font-size: 1.1em;
 font-weight: 900;
 white-space: nowrap;
 line-height: 1;
 font-family: var(--text-family);
 letter-spacing: 0.1px;
}
