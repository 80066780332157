.title-bd-overview-tontine {
 display: flex;
 flex-direction: column;
 margin: 5ch 0;
}

.title-bd-overview-tontine h1 {
 display: flex;
 font-size: 1.299em;
 font-weight: 300;
 color: #1b98e0;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
