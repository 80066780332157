.wrp-form-input-nows {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 max-width: 25em;
}
.wrp-form-input-nows form {
 display: flex;
 flex-direction: column;
 padding: 1em;

 width: 100%;
 max-width: 100vw;
}
.wrp-form-input-nows input {
 display: flex;
 font-size: 1.5em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;

 letter-spacing: 1px;
 text-align: center;
 padding-left: 1em;
 border: none;
}
.wrp-form-input-nows form button {
 display: flex;
 margin-top: 2vh;
 font-family: var(--text-family);
}

.wrp-form-input-nows form .wrp-errors-code {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1.2;
 letter-spacing: 0px;
 margin-top: 2em;
 width: 100%;
 color: crimson;
}
.wrp-form-input-nows form .wrp-errors-code-transparent {
 display: flex;
 font-weight: 300;
 line-height: 0;
 letter-spacing: 0px;
 width: 100%;
 color: transparent;
}
