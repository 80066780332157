.wrp-dashed {
 display: flex;
 width: 100%;
 height: 100%;
 max-height: 100vh;
 background-color: var(--nav-cmd-bg);

 /* background: linear-gradient(25deg, #a2d2ff, #003e77); */
 color: #0a090c;
}
