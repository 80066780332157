.wrp-logout-ima {
 display: flex;
 justify-content: center;
 align-items: center;
 cursor: pointer;
 gap: 0.5em;
}
.wrp-logout-ima img {
 height: 1.8m;
 width: 1.8m;
 object-fit: cover;
}

.child-custom {
 display: flex;
 font-size: 1.144em;
 font-family: var(--text-family);
 font-weight: 300;
 line-height: 1;
 padding: 1em;
 letter-spacing: 0.5px;
}
