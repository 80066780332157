.head-title-middle-home {
 background-size: cover;
 width: 100%;
 height: 300px;

 display: flex;
 justify-content: center;
 align-items: center;
}
.head-title-middle-home h3 {
 font-size: 10vw;

 font-family: "Righteous", sans-serif;
 font-weight: 400;
 font-style: normal;

 background-clip: text;
 color: transparent;

 background-image: linear-gradient(to right, #001f54, #feba7b, #febaaa, #001f54);
}
.head-title-middle-home-sm {
 background-size: cover;
 width: 100%;
 height: 300px;

 display: flex;
 justify-content: center;
 align-items: center;
}
.head-title-middle-home-sm h3 {
 font-size: 17vw;

 font-family: "Righteous", sans-serif;
 font-weight: 400;
 font-style: normal;

 background-clip: text;
 color: transparent;

 background-image: linear-gradient(to right, #001f54, #feba7b, #febaaa, #001f54);
}
