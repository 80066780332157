.list-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-buy-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail p {
 display: flex;
 font-size: 0.5em;
 font-weight: 400;
 line-height: 0.5;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.list-top-exchange-info-detail {
 display: flex;
 flex-direction: column;
 border-bottom: 1px solid #e7ecef;

 padding-top: 7vh;
}
.list-sub-exchange-info-detail {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-info-detail p {
 display: flex;
 font-size: 0.5em;
 font-weight: 400;

 /* padding-top: 2vh; */

 line-height: 1;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
}

.list-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 width: 100%;
}
.list-top-exchange-buy-detail-view {
 display: flex;
 flex-direction: column;
 border-radius: 1em;
 padding: 0.7em;
 margin: 1vh 0;
}
.list-sub-exchange-buy-detail-view {
 display: flex;
 justify-content: space-between;
 place-items: center;
 width: 100%;
}
.list-sub-exchange-buy-detail-view p {
 display: flex;
 font-size: 0.655em;
 font-weight: 400;
 line-height: 0.4;
 white-space: nowrap;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
