:root {
 --color-yellow: #9d4edd;
}

.wrp-boxname-profil {
 display: flex;
 width: 100%;
 justify-content: center;
 place-items: center;
 margin: 2vh 0;
}
.profil-name-identity {
 display: grid;
 place-items: start;
 padding-left: 2em;
 width: 15em;
}

.profil-name-identity h1 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
}
.profil-name-identity h2 {
 display: flex;
 font-size: 1em;
 font-weight: 300;
 line-height: 1;
 letter-spacing: 0px;
 font-family: var(--text-family);
 color: #6c757d;
}
