/* @import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300&display=swap");

:root {
font-family: var(--text-family);
} */

.wrp-wallet-money div {
 display: flex;
 justify-content: center;
 align-items: center;

 width: 100%;
 padding: 8vh 0;
 z-index: 10;
}
.wrp-wallet-money h4 {
 display: flex;
 font-size: 4em;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-wallet-money h5 {
 display: flex;
 font-size: 2.7em;
 margin-top: 2vh;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
.wrp-wallet-money h3 {
 display: flex;
 font-size: 2em;
 padding-bottom: 4vh;
 font-weight: 300;
 font-family: var(--text-family);
 line-height: 1;
 letter-spacing: 0px;
}
