.wrp-well-body-tontine {
 display: flex;
 flex-direction: column;
 width: 100%;
 height: 100%;

 justify-content: center;
 align-items: center;

 min-height: 90vh;
}
